.sub-header {
  background: url(../public/assets/home/contact-sub-header.webp) no-repeat;
  background-position: center;
  background-size: cover;
  padding: 80px 0
}

.sub-header.shuttle-subhead {
  background: url(../public/assets/home/shuttle-subhead.webp) no-repeat;
  background-position: center;
  background-size: cover
}

.sub-header-content p {
  margin: 10px 0 0;
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  background: linear-gradient(to right, transparent, rgb(0 0 0 / 90%) 50%, transparent);
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center
}

.sub-header-content p span {
  color: #298cff;
  font-family: Gilroy;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
  margin: 0 5px
}

.sub-header-content h1 {
  color: #fff;
  font-size: 50px;
  text-shadow: 1px 3px 3px rgb(0 0 0 / .4);
  text-align: center
}

.contact-info-area {
  margin-bottom: 80px
}

.contact-box-wrap {
  box-shadow: 0 2px 16px 4px rgb(40 44 63 / .05);
  padding: 30px 10px;
  margin-top: 30px;
  border-radius: 5px;
  border: 1px solid rgba(0 0 0 / 15%);
  height: 250px
}

.contact-box-content a {
  color: rgb(0 0 0 / 70%);
  transition: all 0.2s ease
}

.contact-box-content a:hover {
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text;
  color: #fff0
}

.contact-box-content {
  text-align: center
}

.contact-box-content img {
  width: 50px;
  margin: 0 auto
}

.contact-box-content h3 {
  font-size: 26px;
  margin: 12px 0
}

.contact-box-content p {
  margin: 0
}

.get-intouch-place {
  margin: 80px 0
}

.in-touch-wrap {
  box-shadow: 0 2px 16px 4px rgb(40 44 63 / .05);
  border-radius: 5px;
  margin: 0px !important
}

.in-touch-wrap .col-lg-5 {
  overflow: hidden;
  padding: 0
}

.in-touch-form {
  padding: 30px
}

.shuttle-need-form .text-danger,
.user-info-modalbox form .text-danger {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  display: block
}

.in-touch-form form .text-danger {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: -15px;
  display: block;
  margin-bottom: 20px
}

.in-touch-form input {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 13px;
  color: rgb(0 0 0 / 50%);
  height: 42px
}

.in-touch-form select {
  font-weight: 500;
  font-size: 13px;
  color: rgb(0 0 0 / 50%);
  height: 42px;
  box-shadow: none;
  margin-bottom: 20px
}

.in-touch-form input:focus,
.in-touch-form select:focus,
.in-touch-form textarea:focus {
  box-shadow: none;
  border-color: #bab6b6b8
}

.in-touch-form textarea {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 13px;
  color: rgb(0 0 0 / 50%);
  height: 100px
}

.in-touch-form .country-code {
  height: 42px !important;
  font-size: 13px !important;
  font-weight: 500;
  color: rgb(0 0 0 / 50%) !important;
  border-color: #dee2e6 !important
}

.in-touch-form .country-code:focus {
  box-shadow: none
}

.country-flag {
  display: flex;
  align-Items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-Radius: 4px;
  background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/%3E%3C/svg%3E') no-repeat right 10px center;
  cursor: pointer;
  height: 42px !important;
  font-size: 13px !important;
  font-weight: 500;
  color: rgb(0 0 0 / 50%) !important;
  border-color: #dee2e6 !important;
  width: '100%'
}

.recaptcha-container {
  margin-bottom: 18px;
  margin-top: 20px
}

.custom-widget-container {
  background-color: #f9f9f9;
  padding: 0 20px 20px;
  border-radius: 8px
}

.custom-widget-container .goodfirm-widget {
  max-width: 100%;
  height: auto;
}

.custom-widget-container .goodfirm-logo {
  width: 1oopx;
  height: auto;
  margin-bottom: 10px
}

.custom-widget-container .goodfirm-review-block {
  padding: 2px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 15px
}

.custom-widget-container iframe {
  width: 100%;
  height: 170px;
  border: none
}

.no-padd {
  padding: 0px !important
}

.contact-image {
  width: 100%;
  border-radius: 5px 0 0 5px
}

.cont-nunbox {
  position: relative;
  overflow: hidden
}

.cont-number {
  bottom: 0;
  background: rgb(9 9 9 / 70%);
  position: absolute;
  width: 95%;
  left: 0;
  right: 0;
  border: 1px solid rgb(204 204 204 / 80%);
  border-bottom: 0;
  margin: 0 auto;
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0
}

.cont-number div {
  width: 33%;
  border-right: 1px solid #fff;
  padding: 0 20px;
  text-align: center
}

.cont-number div:last-child {
  border-right: 0
}

.cont-number div h4,
.cont-number div h3 {
  color: #fff;
  font-size: 42px;
  margin-bottom: 6px;
  line-height: 1
}

.cont-number div p {
  color: #298cff;
  margin: 0;
  font-weight: 700
}

.conform-phone {
  display: flex;
  align-items: center
}

.conform-phone div {
  width: 150px;
  margin-right: 7px
}

.name-email-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.name-email-wrap input {
  width: 49%
}

.get-touch-img {
  width: 350px
}

.get-intouch-place .get-touch-box {
  margin-top: 30px
}

.blog-main-area {
  padding-top: 20px;
  margin-bottom: 80px;
  padding-right: 15px
}

.blog-main-wrapper {
  background: linear-gradient(#f0fcff, #fff);
  box-shadow: 0 2px 16px 4px rgb(40 44 63 / .05);
  border-radius: 15px
}

.blog-main-wrapper .col-lg-6.left-pad-fix {
  padding-left: 0;
  overflow: hidden
}

.blog-main-wrapper .col-lg-6.right-pad-fix {
  padding-right: 0
}

.blog-top-content {
  padding: 30px 30px 20px 30px
}

.blog-small-logo {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 8px
}

.blog-small-logo img {
  width: 35px;
  margin-right: 10px;
  box-shadow: 0 3px 11px 0 rgba(0 0 0 / 28%);
  border-radius: 50%
}

.blog-small-logo span {
  color: rgb(0 0 0 / .45);
  font-size: 12px;
  font-weight: 600
}

.blog-small-logo span strong {
  color: #000;
  font-size: 14px;
  font-weight: 600
}

.blog-main-content span {
  color: rgb(0 0 0 / .45);
  font-size: 15px
}

.blog-main-content h1 {
  font-size: 32px;
  margin-bottom: 20px;
  margin-top: 5px
}

.blog-main-content p {
  margin-bottom: 20px
}

.blog-mainsec-img img {
  width: 100%;
  height: 375px;
  object-fit: cover;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px
}

.our-blogs-area {
  margin-bottom: 80px
}

.blog-cards-head {
  margin-bottom: 30px
}

.blog-card {
  background: #f0fcff;
  border-radius: 8px;
  transition: all 0.3s ease-in;
  margin-bottom: 30px
}

.blog-card .card-img-main {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%
}

.blog-card:hover {
  box-shadow: 0 2px 16px 4px rgb(40 44 63 / .05);
  background: #fff
}

.blog-card:hover .blog-card-content .card-link-more img {
  margin-left: 8px
}

.blog-date-wrap {
  border-bottom: 1px solid rgba(77 77 77 /30%);
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.by-togopool-date {
  display: flex;
  align-items: center
}

.by-togopool-date span {
  color: #4d4d4d;
  font-size: 14px
}

.by-togopool-date img {
  margin-right: 8px;
  width: 15px
}

.blog-card-content {
  padding: 20px;
  border: 1px solid rgb(0 0 0 / .3);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px
}

.blog-card-content h4 {
  font-size: 22px;
  margin: 15px 0;
  min-height: 105px
}

.blog-card-content .card-link-more {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 40%);
  background-clip: text;
  font-weight: 700;
  color: #fff0;
  font-size: 14px
}

.blog-card-content .card-link-more img {
  width: 11px;
  margin-left: 5px;
  transition: all 0.3s ease-in
}

.blog-detail-area {
  padding-top: 20px;
  margin-bottom: 80px
}

.header-detail-place h1 {
  font-size: 32px;
  font-family: "Gilroy";
}

.detail-date-owner {
  padding: 15px 0;
  border-bottom: 1px solid rgba(77 77 77 /30%);
  display: flex;
  align-items: center
}

.detail-owner-box {
  display: flex;
  align-items: center;
  margin-right: 50px
}

.detail-owner-box img {
  width: 18px;
  margin-right: 8px
}

.detail-owner-box:last-child {
  margin-right: 0
}

.detail-owner-box span {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500
}

.blog-list-img {
  margin-top: 30px;
  border-radius: 15px;
  margin-bottom: 40px
}

.blog-list-content h2,
.blog-list-content h3,
.blog-list-content h4 {
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 25px
}

.blog-list-content ul {
  padding-left: 15px
}

.blog-list-content ul li {
  font-size: 14px;
  color: rgb(0 0 0 / 70%);
  margin-bottom: 3px
}

.blog-list-content ul li:last-child {
  margin-bottom: 0
}

.blogdetail-cards {
  margin-bottom: 50px
}

.blog-list-content p:last-child {
  margin-bottom: 0
}

.shuttle-teamlogo {
  margin: 80px 0 50px
}

.shuttle-teamlogo .heading {
  font-size: 22px;
  margin-bottom: 20px
}

.shuttle-teamlogo-box div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px
}

.shuttle-teamlogo-box div img {
  width: 90px;
  margin: 0 auto;
  display: block;
  max-width: 100%;
  height: auto
}

.shuttle-tech-box {
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 15px
}

.shuttle-tech-box:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 0
}

.shuttle-tech-box div {
  position: absolute;
  text-align: center;
  padding: 0 50px;
  z-index: 1;
  top: 96%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  transition: all 0.6s ease
}

.shuttle-tech-box div h4 {
  color: #fff;
  font-size: 32px
}

.shuttle-tech-box div p {
  transition: all 0.6s ease;
  opacity: 0;
  color: #fff;
  margin: 0
}

.shuttle-tech-box:hover:after {
  opacity: .8
}

.shuttle-tech-box:hover div {
  top: 50%
}

.shuttle-tech-box:hover div p {
  opacity: 1
}

.shuttle-tech-area .default-btn {
  margin-top: 30px
}

.suttle-booking-area {
  position: relative
}

.suttbook-gap {
  margin-top: 80px
}

.app-booking-box .app-booking-mainhead {
  font-size: 28px;
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 60%);
  background-clip: text;
  color: #fff0;
  text-transform: uppercase;
  margin-bottom: 0
}

.app-booking-content .heading {
  font-size: 22px;
  text-transform: capitalize
}

.app-booking-content {
  border-bottom: 1px solid;
  padding: 25px 25px 25px 70px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  margin-top: 30px;
  transition: all 0.4s ease-in-out
}

.app-booking-content:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 0
}

.app-booking-content label {
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  font-family: Gilroy;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px 0 15px 0;
  transition: all 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0
}

.app-booking-content p {
  margin-bottom: 0;
  transition: all 0.2s ease
}

.app-booking-content .heading {
  color: #000;
  font-size: 24px;
  margin-bottom: 7px;
  transition: all 0.2s ease;
  font-weight: 500 !important
}

.app-booking-content:hover .heading,
.app-booking-content:hover p {
  color: #fff
}

.app-booking-content:hover:before {
  opacity: 1
}

.app-booking-content:hover label {
  background: #fff;
  color: #0035e5
}

.suttle-booking-row .default-btn {
  margin-top: 30px
}

.suttbook-fixed {
  position: sticky;
  top: 85px
}

.suttbook-fixed img {
  width: 68%
}

.suttbook-right {
  display: flex;
  justify-content: end
}

.booking-shuttle-head {
  margin-bottom: 50px
}

.shutbook-vector-1 {
  position: absolute;
  left: -160px;
  top: 100px;
  width: 350px;
  z-index: -1;
  transform: rotate(160deg)
}

.shutbook-vector-2 {
  position: absolute;
  right: 0;
  overflow: hidden;
  top: 0;
  width: 470px;
  z-index: -1
}

.shutbook-vector-2 img {
  width: 100%;
  margin-left: 160px
}

.shutbook-vector-3 {
  position: absolute;
  left: -40px;
  top: 40px;
  width: 350px;
  z-index: -1;
  transform: rotate(110deg)
}

.shuttpage-business {
  margin-bottom: 80px
}

.shuttle-service-row {
  position: relative;
  margin-top: 50px
}

.shuttle-service-box {
  border-radius: 12px;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  padding: 50px 200px 50px 50px;
  width: 65%;
  overflow: hidden
}

.shuttle-service-box:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 0
}

.shuttle-service-box h4,
.shuttle-service-box h3 {
  font-size: 32px;
  margin: 0 0 15px;
  transition: all 0.2s ease
}

.shuttle-service-box p {
  color: #000;
  transition: all 0.2s ease;
  font-size: 15px;
  line-height: 1.8
}

.shuttle-service-box ul {
  padding-left: 20px
}

.shuttle-service-box ul li {
  font-size: 14px;
  color: #000;
  margin-bottom: 6px;
  transition: all 0.2s ease
}

.shuttle-service-box ul li:last-child {
  margin-bottom: 0
}

.shuttle-service-box ul li::marker {
  color: #0035e5;
  font-size: 15px;
  transition: all 0.2s ease
}

.shuttle-service-box .default-btn {
  border: 1px solid #fff0
}

.shuttle-service-row:hover .shuttle-service-box .default-btn {
  background: #fff0;
  border-color: #fff
}

.shuttle-service-row:hover .shuttle-service-box:before {
  opacity: 1
}

.shuttle-service-row:hover .shuttle-service-box h4,
.shuttle-service-row:hover .shuttle-service-box h3,
.shuttle-service-row:hover .shuttle-service-box p,
.shuttle-service-row:hover .shuttle-service-box ul li,
.shuttle-service-row:hover .shuttle-service-box ul li::marker {
  color: #fff
}

.shuttle-service-image {
  position: absolute;
  top: 50%;
  right: 0;
  width: 47%;
  transform: translateY(-50%)
}

.shuttle-service-reverse {
  display: flex;
  justify-content: end
}

.shuttle-service-reverse .shuttle-service-box {
  padding: 50px 50px 50px 200px
}

.shuttle-service-reverse .shuttle-service-image {
  right: auto;
  left: 0
}

.shuttle-service-image img {
  border-radius: 10px
}

.start-book-shuttle {
  margin: 80px 0;
  background: url(../public/assets/home/start-booking-shuttle.webp) no-repeat;
  background-size: cover;
  background-position: center;
  padding: 80px 0
}

.start-booking-box h4,
.start-booking-box h2 {
  color: #fff;
  margin-bottom: 20px
}

.appreciation-slider {
  max-width: 100%;
  overflow: hidden;
  margin: auto;
  padding: 50px 0;
  min-height: 200px
}

.appreciation-slider .slick-list {
  overflow: visible
}

.appreciate-box {
  align-items: center;
  justify-content: center;
  display: flex !important;
  min-height: 150px
}

.appreciate-box div {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / .1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in;
  border: 0 solid rgb(41 141 255 / .25)
}

.appreciate-box div img {
  width: 60%
}

.appreciation-slider .slick-slide.slick-current .appreciate-box div {
  box-shadow: 0 5px 20px 0 rgb(41 141 255 / .25);
  width: 150px;
  height: 150px;
  border: 2px solid rgb(41 141 255 / .25)
}

.appreciate-box-content {
  text-align: center;
  padding-bottom: 30px
}

.appreciate-box-content div {
  text-align: center;
  width: 80%;
  margin: 0 auto
}

.shuttle-needs {
  margin: 80px 0
}

.shuttle-need-footer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.user-shuttle-need {
  display: flex;
  align-items: center;
  margin-bottom: 15px
}

.user-shuttle-need img {
  width: 70px;
  margin-right: 15px
}

.user-name-des span {
  display: block;
  font-size: 13px;
  position: relative
}

.user-name-des span::before {
  content: "";
  position: absolute;
  background: linear-gradient(to right, #0035e5 0%, #298cff 50%);
  height: 2px;
  width: 30%;
  left: 0;
  bottom: -5px
}

.start-conversation-heading {
  font-size: 22px
}

.card-conversation-box {
  border: 1px solid rgb(0 0 0 / .3);
  box-shadow: 0 2px 16px 4px rgb(40 44 63 / .05);
  padding: 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  transition: all 0.2s ease-in;
  margin-top: 20px;
  cursor: pointer;
  position: relative
}

.card-conversation-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 7px;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 0
}

.card-conversation-box:hover::before {
  opacity: 1
}

.card-conversation-image {
  width: 40px;
  padding: 10px;
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  border-radius: 8px;
  height: max-content;
  transition: all 0.2s ease-in
}

.card-conversation-image img {
  filter: brightness(0) invert(1);
  transition: all 0.2s ease-in
}

.card-conversation-box:hover .card-conversation-image {
  background: #fff
}

.card-conversation-box:hover .card-conversation-image img {
  filter: none
}

.conversation-box-content {
  width: 70%
}

.conversation-box-content h4,
.conversation-box-content p {
  transition: all 0.2s ease-in
}

.conversation-box-content h4 {
  font-size: 1.25rem
}

.card-conversation-box:hover .conversation-box-content h4,
.card-conversation-box:hover .conversation-box-content p {
  color: #fff
}

.conversation-btn-wrap {
  align-items: end;
  display: flex
}

.conversation-btn-wrap a {
  width: 40px;
  height: 40px;
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in
}

.card-conversation-box:hover .conversation-btn-wrap a {
  background: #fff
}

.conversation-btn-wrap a svg {
  color: #fff;
  transition: all 0.2s ease-in
}

.card-conversation-box:hover .conversation-btn-wrap a svg {
  color: #0035e5;
  transform: rotate(-45deg)
}

.shuttle-footer-fix p {
  margin-bottom: 0;
  width: 60%
}

.shuttle-need-formhead {
  font-size: 22px;
  display: flex;
  align-items: center;
  cursor: pointer
}

.shuttle-need-formhead svg {
  margin-right: 15px;
  font-size: 13px;
  cursor: pointer
}

.shuttle-need-form form .form-control {
  margin-top: 20px;
  font-size: 12px;
  color: #4d4d4d;
  font-weight: 600;
  padding: 10px
}

.shuttle-need-form form .form-control:focus {
  box-shadow: none;
  border: 1px solid rgb(0 0 0 / .1)
}

.form-permissions {
  margin-top: 20px
}

.form-permissions {
  display: flex;
  align-items: center
}

.form-permissions p {
  margin-left: 15px;
  margin-bottom: 0
}

.submit-btn-wrap {
  text-align: right;
  margin-top: 20px
}

.shuttlepage-head {
  background: #F0FCFF;
  padding-top: 50px;
  overflow: hidden
}

.shuttlehead-text {
  text-align: center;
  position: relative;
  z-index: 1
}

.shuttlehead-text .img-responsive {
  width: 42%;
  margin: 30px auto 0
}

.shuttlehead-text p {
  color: #000;
  margin: 7px auto 20px;
  width: 45%;
  font-size: 15px
}

.shuttlehead-text small{
  color: #fff;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
  background: linear-gradient(to right, transparent, rgb(0 0 0 / 90%) 30%, rgb(0 0 0 / 90%) 70%, transparent);
  padding: 5px 0;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 15px;
}

.shuttlehead-text label,
.shuttlehead-text h1 {
  color: #000;
  font-size: 32px;
  text-transform: capitalize;
  display: block;
  margin-bottom: 6px;
  line-height: 1.3
}

.circle-waves {
  position: absolute;
  z-index: -1;
  bottom: -400px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 750px;
  height: 750px
}

.circle-waves div {
  animation: growAndFade 4s infinite ease-out;
  border: 3px solid #05287a;
  border-radius: 50%;
  height: 100%;
  opacity: 0;
  width: 100%;
  position: absolute
}

.circle-waves div:nth-child(1) {
  animation-delay: 1s
}

.circle-waves div:nth-child(2) {
  animation-delay: 2s
}

.circle-waves div:nth-child(3) {
  animation-delay: 3s
}

@keyframes growAndFade {
  0% {
    opacity: 1;
    transform: scale(0)
  }

  100% {
    opacity: 0;
    transform: scale(1)
  }
}

.netzero-area {
  padding: 80px 0;
  position: relative
}

.netzero-box {
  padding: 30px;
  position: relative;
  border-radius: 15px;
  margin-top: 50px;
  overflow: hidden;
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  transition: all 0.4s ease-in-out
}

.netzero-box:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 0
}

.netzero-box img {
  transition: all 0.2s ease
}

.netzero-box h4,
.netzero-box h3 {
  color: #000;
  font-size: 28px;
  margin: 30px 0 10px;
  padding-top: 20px;
  transition: all 0.2s ease;
  text-transform: capitalize;
  border-top: 1px solid rgb(0 0 0 / 20%)
}

.netzero-box p {
  margin: 0;
  transition: all 0.2s ease
}

.zero-after {
  display: none
}

.netzero-box:hover:before {
  opacity: 1
}

.netzero-box:hover h4,
.netzero-box:hover h3,
.netzero-box:hover p {
  color: #fff;
  border-color: #fff
}

.netzero-box:hover .zero-before {
  display: none
}

.netzero-box:hover .zero-after {
  display: block
}

.netzero-vector {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -190px;
  width: 400px;
  z-index: -1
}

.shuttle-solution {
  margin: 70px 0
}

.shuttsolution-box {
  padding: 30px;
  position: relative;
  border-radius: 15px;
  margin-top: 50px;
  overflow: hidden;
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  transition: all 0.4s ease-in-out
}

.shuttsolution-box:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 0
}

.shuttsolution-box h4,
.shuttsolution-box h3 {
  color: #000;
  font-size: 24px;
  margin: 10px 0 15px;
  transition: all 0.2s ease
}

.shuttsolution-box ul {
  margin: 0;
  padding: 0;
  list-style: none
}

.shuttsolution-box ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: all 0.2s ease
}

.shuttsolution-box ul li:last-child {
  margin-bottom: 0
}

.shuttsolution-box ul li img {
  width: 15px;
  margin-right: 8px;
  transition: all 0.2s ease-in-out
}

.shuttsolution-box label {
  text-transform: uppercase;
  font-size: 14px;
  display: block;
  color: #000;
  transition: all 0.2s ease
}

.shuttsolution-box span {
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 25px;
  transition: all 0.4s ease-in-out
}

.shuttsolution-box span img {
  filter: brightness(0) invert(1);
  transition: all 0.4s ease-in-out;
  width: 35px
}

.shuttsolution-box:hover:before {
  opacity: 1
}

.shuttsolution-box:hover h4,
.shuttsolution-box:hover h3,
.shuttsolution-box:hover label,
.shuttsolution-box:hover ul li {
  color: #fff
}

.shuttsolution-box:hover ul li img {
  filter: brightness(0) invert(1)
}

.shuttsolution-box:hover span {
  background: #fff
}

.shuttsolution-box:hover span img {
  filter: none
}

.shutt-explore {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  filter: grayscale(1);
  opacity: .1;
  transition: all 0.4s ease-in-out
}

.shuttsolution-box:hover .shutt-explore {
  filter: none;
  opacity: 1
}

.shuttsolu-big {
  padding: 50px 15px 30px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  transition: all 0.4s ease-in-out;
  margin: 30px 0 40px !important
}

.shuttsolu-bigbox h4,
.shuttsolu-bigbox h3 {
  color: #000;
  font-size: 24px;
  margin: 10px 0 15px;
  transition: all 0.2s ease
}

.shuttsolu-bigbox label {
  text-transform: uppercase;
  font-size: 14px;
  display: block;
  color: #000;
  transition: all 0.2s ease
}

.shuttsolu-bigbox span {
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 25px;
  transition: all 0.4s ease-in-out
}

.shuttsolu-bigbox span img {
  transition: all 0.4s ease-in-out;
  width: 30px
}

.shuttsolu-image {
  display: flex;
  align-items: start
}

.shuttsolu-image img {
  width: 50%
}

.shuttsolu-image img:nth-child(2) {
  margin-top: 40px
}

.shuttexplore-vector-1 {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -190px;
  width: 380px !important;
  z-index: -1
}

.shuttexplore-vector-2 {
  position: absolute;
  right: -50px;
  top: -10px;
  width: 270px !important;
  z-index: -1
}

.shuttservice-area {
  padding: 80px 0;
  background: linear-gradient(to bottom, #dfe7ff 0%, #ffe9da 100%)
}

.shuttservice-row {
  padding: 30px 30px 0;
  position: relative;
  border-radius: 15px;
  margin: 75px 0 0px !important;
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  transition: all 0.4s ease-in-out;
  background: #fff;
  z-index: 1;
  overflow: hidden
}

.over-hide {
  overflow: visible
}

.shuttservice-row:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 15px;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 0
}

.shuttservice-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 30px
}

.shuttservice-detail h4 .shuttservice-detail h2 {
  color: #000;
  font-size: 30px;
  margin-bottom: 20px;
  transition: all 0.2s ease;
  text-transform: capitalize
}

.shuttservice-detail span {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #000
}

.shuttservice-detail span img {
  filter: brightness(0) saturate(100%);
  transition: all 0.4s ease-in-out;
  width: 30px
}

.shuttservice-detail .default-btn {
  border: 1px solid #fff0
}

.shuttservice-image {
  margin-top: -110px;
  padding-bottom: 30px
}

.shuttservice-image img {
  width: 90%;
  margin: 0 auto
}

.shuttservice-right span {
  float: right
}

.shuttservice-row:hover:before {
  opacity: 1
}

.shuttservice-row:hover h4,
.shuttservice-row:hover h2 {
  color: #fff
}

.shuttservice-row:hover .shuttservice-detail span {
  border-color: #fff;
  background: #fff
}

.shuttservice-row:hover .shuttservice-detail span img {
  filter: none
}

.shuttservice-row:hover .shuttservice-detail .default-btn {
  background: #fff0;
  border-color: #fff
}

.shuttservice-image-2 {
  padding-bottom: 30px;
  margin-top: -30px
}

.shutser-line-3b {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
  padding: 0 !important;
  width: 70% !important
}

.shutser-vector-3b {
  position: absolute;
  left: -12px;
  top: 0;
  width: 350px !important;
  z-index: -1
}

.shutser-vector-2b {
  position: absolute;
  right: -12px;
  top: 0;
  width: 330px !important;
  z-index: -1
}

.shutser-line-2b {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  padding: 0 !important;
  width: 70% !important
}

.shutser-vector-1b {
  position: absolute;
  left: -12px;
  top: 0;
  width: 300px !important;
  z-index: -1
}

.shuttservice-expand {
  background: url(../public/assets/home/shuttexpand-bg.webp) no-repeat;
  background-size: cover;
  padding: 30px 70px;
  margin: 0 -42px;
  border-radius: 0 0 15px 15px;
  display: none;
  transition: all 0.7s;
  height: 0%;
  position: relative
}

.shuttservice-expand::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  top: 4px;
  left: 0;
  right: 0;
  background: #fff
}

.shuttservice-expand ul {
  margin: 0;
  padding: 0;
  list-style: none
}

.shuttservice-expand ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: start;
  font-size: 14px;
  transition: all 0.2s ease;
  color: #fff
}

.shuttservice-expand ul li img {
  width: 15px;
  margin-right: 10px;
  margin-top: 3px;
  filter: brightness(0) invert(1)
}

.shuttservice-expand ul li:last-child {
  margin-bottom: 0
}

.moretext.show {
  display: block;
  transition: all 0.7s;
  height: 100%
}

.car-rental-subhead {
  padding: 40px 0;
  background: linear-gradient(to bottom, #f0fcff 0%, #fff 80%)
}

.rental-subhead-wrap h1 span {
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text;
  color: #fff0
}

.rental-subhead-wrap label {
  text-transform: uppercase;
  font-size: 13px;
  color: rgb(0 0 0 / .7);
  font-weight: 600
}

.rental-subhead-wrap p {
  margin-top: 15px;
  margin-bottom: 30px
}

.rental-subhead-wrap small {
  color: #fff;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
  background: linear-gradient(to right, rgb(0 0 0 / 90%) 50%, transparent);
  padding: 5px 10px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.fleet-earning-head span {
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text;
  color: #fff0
}

.fleet-earning .sub-heading {
  margin-bottom: 40px
}

.earning-box {
  padding: 25px 25px 25px 70px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  margin-bottom: 30px;
  transition: all 0.4s ease-in-out
}

.earning-box:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 0
}

.earning-box:hover:before {
  opacity: 1
}

.earning-box h4,
.earning-box h3 {
  color: #000;
  font-size: 28px;
  margin-bottom: 7px;
  transition: all 0.2s ease
}

.earning-box p {
  margin-bottom: 8px;
  transition: all 0.2s ease
}

.earning-box span {
  font-family: Gilroy;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.1px;
  color: rgb(0 0 0 / 60%);
  display: inline-flex;
  align-items: center;
  line-height: 1;
  transition: all 0.2s ease
}

.earning-box span img {
  width: 11px;
  margin-left: 4px;
  opacity: .6;
  transition: all 0.1s ease;
  filter: brightness(0) saturate(100%)
}

.earning-box label {
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  font-family: Gilroy;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px 0 15px 0;
  transition: all 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0
}

.earning-box:hover {
  border-color: #0035e5
}

.earning-box:hover h4,
.earning-box:hover h3,
.earning-box:hover p,
.earning-box:hover span {
  color: #fff
}

.earning-box:hover span img {
  filter: brightness(0) invert(1);
  opacity: 1
}

.earning-box:hover label {
  background: #fff;
  color: #0035e5
}

.earning-fixed {
  position: sticky;
  top: 70px
}

.earning-fixed img {
  width: 70%;
  margin: auto
}

.car-mobility {
  margin: 60px 0 80px
}

.car-mobility .sub-heading {
  margin-bottom: 20px
}

.car-mobility .car-mobility-head span {
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text;
  color: #fff0
}

.car-mobility-box {
  padding: 50px 30px 30px;
  border: 1px solid rgb(0 0 0 / 30%);
  transition: all 0.3s ease;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  margin-top: 60px;
  position: relative;
  transition: all 0.2s ease-in
}

.car-mobility-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.3s ease-in-out;
  border-radius: 9px;
  opacity: 0
}

.car-mobility-box:hover::before {
  opacity: 1
}

.car-mobility-box .mobility-img {
  position: absolute;
  top: -33px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  transition: all 0.2s ease-in
}

.mobility-img-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%
}

.mobility-img-box img.mobility-png {
  width: 25px;
  filter: brightness(0) invert(1);
  transition: all 0.2s ease-in
}

.mobility-img-box img.circle {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 62px
}

.car-mobility-box h4,
.car-mobility-box h3 {
  font-size: 22px;
  transition: all 0.2s ease-in
}

.car-mobility-box p {
  margin: 0;
  min-height: 70px;
  transition: all 0.2s ease-in
}

.car-mobility-box:hover p {
  color: #fff
}

.car-mobility-box:hover h4,
.car-mobility-box:hover h3 {
  color: #fff
}

.car-mobility-box:hover .mobility-img {
  background: #fff
}

.car-mobility-box:hover .mobility-img img.mobility-png {
  filter: none
}

.car-infotech {
  background: url(../public/assets/home/car-infotech-background.webp) no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 50px
}

.car-infotech .heading span {
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text;
  color: #fff0
}

.car-infotech img {
  margin-top: 80px
}

.car-control {
  margin: 20px 0 80px
}

.car-control-iconbox {
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  padding: 30px;
  position: relative;
  margin-top: 30px
}

.car-control-iconbox:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.3s ease-in-out;
  opacity: 0
}

.car-control-iconbox:hover:before {
  opacity: 1
}

.car-control-iconbox h4,
.car-control-iconbox h3 {
  text-transform: uppercase;
  font-size: 20px;
  transition: all 0.2s ease;
  margin: 12px 0 6px
}

.car-control-iconbox p {
  transition: all 0.2s ease;
  margin: 0;
  min-height: 70px
}

.car-control-iconbox span {
  width: 45px;
  height: 45px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  overflow: hidden
}

.car-control-iconbox span img {
  width: 26px;
  transition: all 0.3s ease-in-out;
  filter: brightness(0) invert(1)
}

.car-control-iconbox span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.3s ease-in-out;
  opacity: 1
}

.car-control-iconbox:hover span:before {
  opacity: 0
}

.car-control-iconbox:hover span img {
  filter: none
}

.car-control-iconbox:hover h4,
.car-control-iconbox:hover h3,
.car-control-iconbox:hover p {
  color: #fff
}

.success-key-area {
  margin: 50px 0
}

.seccess-mainhead span {
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text;
  color: #fff0
}

.successkey-booking-content .heading {
  font-size: 22px;
  text-transform: capitalize
}

.successkey-booking-content {
  padding-bottom: 20px;
  border-bottom: 1px solid;
  margin-bottom: 20px
}

.successkey-booking-content:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0
}

.successkey-booking-content p {
  margin-bottom: 0;
  transition: all 0.3s ease-in-out
}

.successkey-booking-content .heading {
  font-size: 22px;
  margin-bottom: 8px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out
}

.successkey-booking-content:hover .heading {
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 60%);
  background-clip: text;
  color: #fff0
}

.successkey-booking-content:hover p {
  color: #000
}

.success-booking-row.row {
  margin-top: 30px;
  justify-content: center;
  align-items: center
}

.success-booking-row .default-btn {
  margin-top: 30px
}

.success-key-image {
  width: 75%;
  margin: 0 auto
}

.page-not-found {
  background: url(../public/assets/home/404-background.webp) no-repeat;
  background-size: cover;
  background-position: center;
  height: 475px;
  margin-bottom: 50px;
  position: relative
}

.page-not-found h1 {
  color: #fff
}

.page-not-found span {
  font-weight: 400
}

.page-not-found p {
  color: #fff;
  font-size: 30px;
  font-family: Gilroy
}

.page-not-found a {
  margin-top: 15px
}

.page-not-found .col-lg-12 {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%)
}

.thankyou-area {
  margin-bottom: 50px;
  position: relative
}

.thankyou-area .thanks-image {
  width: 100%;
  height: 475px;
  object-fit: cover
}

.thankyou-content-box {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%
}

.thankyou-content-box p {
  font-size: 22px;
  font-family: Gilroy;
  font-weight: 600;
  color: #000
}

.thankyou-message {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px
}

.thankyou-message img {
  width: 55%
}

.user-info-img {
  position: relative;
  height: 100%
}

.modal-userinfo-image {
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100%;
  object-fit: cover
}

.user-info-popup .modal-body {
  padding: 0;
  padding-right: 15px
}

.user-info-modalbox {
  margin-top: 30px
}

.user-info-modalbox form label {
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 15px;
  color: rgb(0 0 0 / .6);
  font-weight: 500
}

.user-info-modalbox form .default-btn {
  margin: 15px auto;
  display: block;
  width: max-content
}

.user-info-modalbox .heading {
  font-size: 32px
}

.user-info-slider-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(../public/assets/home/slider-blur.png) no-repeat;
  background-position: center;
  background-size: cover;
  margin: 10px;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 8px;
  width: 95%
}

.userinfo-box {
  position: relative
}

.userinfo-box p {
  color: #fff;
  font-weight: 500
}

.userinfo-box span {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  display: block
}

.userinfo-box label {
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  display: block
}

.stars-user-info {
  position: absolute;
  right: 10px;
  bottom: 40px
}

.stars-user-info img {
  width: 80px
}

.user-info-slider-wrap .slick-next,
.user-info-slider-wrap .slick-prev {
  background-color: #fff;
  border-radius: 50%;
  color: #0035e5;
  padding: 8px;
  width: 8px;
  height: 8px;
  top: unset;
  bottom: -5px;
  z-index: 9;
  transition: all .2 ease-in
}

.user-info-slider-wrap .slick-prev {
  left: unset;
  right: 40px
}

.user-info-slider-wrap .slick-next {
  right: 10px
}

.user-info-slider-wrap .slick-next:hover,
.user-info-slider-wrap .slick-prev:hover {
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  color: #fff
}

.animated-btn {
  overflow: hidden;
  position: relative
}

.animated-btn::after {
  content: "";
  position: absolute;
  animation: button_shift;
  filter: blur(8px);
  transform: rotate(45deg);
  background: rgba(255 255 255 / 70%);
  width: 15px;
  height: 100%;
  border-radius: 5px;
  top: 0;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear
}

@keyframes button_shift {
  0% {
    left: -80px
  }

  50% {
    left: -20px
  }

  100% {
    left: 100%
  }
}

.car-pool-subhead {
  padding: 80px 0;
  background: linear-gradient(to right, #f0fcff 0%, #ffffff 100%)
}

.interface-area {
  margin-bottom: 80px
}

.interface-tabs {
  margin-top: 30px
}

.interface-tabs .nav {
  flex-direction: row !important;
  align-items: center;
  justify-content: center
}

.interface-tabs .nav-item .nav-link {
  display: inline;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #0035e5;
  margin: 0 5px
}

.interface-tabs .nav-item .nav-link.active {
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%)
}

.interface-tab-body {
  margin-top: 50px
}

.tab-content-box {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
  padding: 15px;
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 1px 14px 0 rgb(0 0 0 / 15%);
  background-color: #fff;
  z-index: 1
}

.tab-content-box h4,
.tab-content-box h3 {
  font-size: 22px;
  transition: all 0.3s ease-in
}

.tab-content-box p {
  margin: 0;
  transition: all 0.3s ease-in
}

.tab-content-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.3s ease-in-out;
  opacity: 0
}

.tab-content-box:hover p,
.tab-content-box:hover h4,
.tab-content-box:hover h3 {
  color: #fff
}

.tab-content-box:hover::before {
  opacity: 1
}

.tab-content-box:hover {
  background-color: unset
}

.interface-tabs .tab-content {
  position: relative
}

.interface-tabs .tab-content .tab-anim-img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 420px;
  z-index: -1;
  animation: animScaler 4s infinite
}

@keyframes animScaler {
  0% {
    transform: translate(-50%, -50%) scale(1)
  }

  50% {
    transform: translate(-50%, -50%) scale(1.2)
  }

  100% {
    transform: translate(-50%, -50%) scale(1)
  }
}

.taxitab-mobile {
  position: relative
}

.taxitab-mobile .img-responsive {
  margin: 0 auto
}

.taxitab-wave {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  background: #59A3FE;
  border-radius: 50%;
  transform: translate(-50%, -50%)
}

.taxitab-wave:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 240px;
  height: 240px;
  background: #B2D4FE;
  border-radius: 50%;
  animation: wave-pulse 2000ms ease-out infinite;
  z-index: -1
}

.taxitab-wave:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 300px;
  height: 300px;
  background: #E5F0FE;
  border-radius: 50%;
  animation: wave-pulse 2000ms ease-out infinite;
  z-index: -2
}

@keyframes wave-pulse {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.8);
    opacity: 0
  }
}

.taxitech-area {
  margin: 80px 0
}

.taxi-ondemand {
  background: url(../public/assets/home/ondemand-back.webp) no-repeat;
  background-size: cover;
  padding: 80px 0;
  text-align: center;
  position: relative;
  margin-top: 40px
}

.taxi-content small{
  color: #fff;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
  background: linear-gradient(to right, transparent, rgb(0 0 0 / 90%) 30%, rgb(0 0 0 / 90%) 70%, transparent);
  padding: 5px 0;
  width: 60%;
  margin: 0px auto 15px;
}

.taxi-ondemand .heading {
  margin-bottom: 30px
}

.taxiondemand-car-1 {
  position: absolute;
  bottom: -1px;
  left: 9%
}

.taxiondemand-car-1 img {
  width: 250px
}

.taxiondemand-car-2 {
  position: absolute;
  bottom: 25px;
  right: 8%
}

.taxiondemand-car-2 img {
  width: 190px
}

.taxi-operation-area {
  margin: 80px 0
}

.operation-tab-row.row {
  margin-top: 50px
}

.operation-box {
  border-bottom: 1px solid #4d4d4d;
  padding: 25px 25px 25px 70px;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  transition: all 0.4s ease-in-out;
  text-align: unset
}

.operation-box label {
  display: none
}

.operation-box p {
  display: none
}

.operation-box h4,
.operation-box h3 {
  color: #000;
  transition: all 0.3s ease-in
}

.taxi-operation-area .nav-pills .nav-link.active,
.taxi-operation-area .nav-pills .show>.nav-link {
  background-color: unset;
  color: unset;
  padding: 0
}

.taxi-operation-area .nav-pills .nav-link {
  padding: 0
}

.nav-link.active .operation-box {
  background: url(/public/assets/home/wave-pattern.webp) no-repeat;
  background-size: cover;
  padding: 25px 25px 25px 70px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  margin-bottom: 0;
  transition: all 0.3s ease-in-out
}

.nav-link.active .operation-box label {
  background: #fff;
  color: #0035e5;
  font-weight: 600;
  font-size: 22px;
  font-family: Gilroy;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px 0 15px 0;
  transition: all 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0
}

.nav-link.active .operation-box p {
  color: #fff;
  transition: all 0.3s ease;
  margin-bottom: 0;
  display: block
}

.nav-link.active .operation-box:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 1
}

.nav-link.active .operation-box h4,
.nav-link.active .operation-box h3 {
  color: #fff;
  margin-bottom: 7px;
  transition: all 0.2s ease
}

.tab-img-operation {
  border-radius: 10px;
  width: 100%;
  height: 395px;
  object-fit: cover
}

.privacy-area {
  padding: 80px 0
}

.privacy-tab-wrap {
  background: #f0fcff;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 20px
}

.privacy-tab-wrap .heading {
  font-size: 24px;
  margin-bottom: 20px
}

.privacy-tab-wrap .nav-link {
  color: rgb(0 0 0 / .7);
  background-color: #fff;
  border: 1px solid rgb(0 0 0 / .2);
  border-radius: 8px;
  margin-bottom: 15px
}

.privacy-tab-wrap .nav-link:hover {
  color: rgb(0 0 0 / .7)
}

.policy-page-mainhead.heading {
  font-size: 32px;
  font-family: "Gilroy";
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
  margin-bottom: 15px
}

.privacy-heading {
  font-size: 24px;
  font-family: "Gilroy";
  color: #000 !important;
  margin-bottom: 3px !important;
  font-weight: 700
}

.privacy-content p {
  margin-bottom: 25px;
  color: rgb(0 0 0 / .6)
}

.privacy-doc-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 25px
}

.privacy-doc-list li {
  padding-left: 25px;
  position: relative;
  font-size: 14px;
  color: #000;
  line-height: 27px
}

.privacy-doc-list li:before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  left: 0;
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  border-radius: 50%;
  top: 10px
}

.privacy-content table {
  margin-bottom: 25px
}

.privacy-content table thead th {
  vertical-align: middle;
  background-color: #f0fcff;
  font-size: 14px
}

.privacy-content table tbody td {
  vertical-align: middle;
  font-size: 14px
}

.privacy-content .table>:not(caption)>*>* {
  padding: 10px 15px
}

.menubar-area {
  width: 350px;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  transition: all 0.4s ease-in;
  padding: 20px 25px;
  overflow-y: scroll;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.menubar-area::-webkit-scrollbar {
  display: none
}

.menubar-area.close-menu {
  transform: translateX(400px);
  opacity: .9
}

.menubar-area.open-menu {
  transform: translateX(0);
  opacity: 1
}

.menubar-area .menu-header {
  position: absolute;
  top: 20px;
  right: 60px
}

.menubar-area .menu-close-btn {
  font-size: 20px;
  font-weight: 600
}

.menubar-area .menu-close-btn span {
  cursor: pointer;
  transition: all 0.2s ease-in
}

.menubar-area .menu-close-btn span:hover {
  color: #0035E5 !important
}

.menu-toggel-items {
  list-style: none;
  padding-left: 0;
  padding-top: 40px
}

.menu-toggel-items li {
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease-in;
  margin-bottom: 20px;
  text-transform: capitalize;
  cursor: pointer
}

.menu-toggel-items li a {
  color: #000
}

.menu-toggel-items li label {
  font-size: 20px;
  font-weight: 600;
  transition: all 0.3s ease-in;
  color: #0035e5;
  cursor: pointer
}

.menu-toggel-items ul {
  list-style: none
}

.submenu {
  overflow: hidden;
  max-height: 0;
  transition: all 0.4s ease-in-out;
  padding-left: 0;
  margin-top: 10px;
  margin: 0
}

.submenu.visible {
  max-height: 500px
}

.submenu li {
  margin-top: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 0
}

.submenu li a {
  color: #4b4b4b;
  text-transform: capitalize;
  transition: all 0.2s ease-in;
  font-weight: 400
}

.submenu li a:hover {
  color: #0035e5
}

.menu-footer span {
  font-weight: 600;
  color: #000;
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase
}

.overlay-menubar {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / .5);
  z-index: 10;
  top: 0;
  left: 0
}

.casestudy-area {
  padding-bottom: 80px
}

.main-head-case {
  font-size: 90px;
  padding-top: 20px;
  margin-bottom: 80px
}

.main-head-case span {
  background: linear-gradient(to right, #0035E5 0%, #298CFF 70%);
  background-clip: text;
  color: #fff0
}

.study-wrapper {
  position: relative;
  margin-bottom: 40px;
  border-radius: 25px;
  overflow: hidden
}

.study-wrapper img {
  border-radius: 25px;
  transition: all 0.3s ease-in
}

.studybox-detail {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 25px 30px
}

.studybox-detail h4 {
  color: #fff;
  font-size: 34px;
  margin-bottom: 20px
}

.studybox-detail ul {
  padding: 0;
  margin: 0;
  list-style: none
}

.studybox-detail ul li {
  background: #fff;
  display: inline-block;
  color: rgb(0 0 0 / 70%);
  border-radius: 8px;
  padding: 7px 12px;
  font-weight: 600;
  font-size: 14px;
  margin-right: 8px
}

.case-car-rent {
  margin-top: 140px
}

.case-study-wrap:hover .study-wrapper img {
  transform: scale(1.1)
}

.study-detail-area {
  padding-top: 20px;
  margin-bottom: 80px
}

.study-head {
  font-size: 28px;
  padding-bottom: 50px;
  border-bottom: 1px solid #4D4D4D;
  text-transform: none
}

.case-detail-banner {
  border-radius: 25px;
  margin-bottom: 50px
}

.details-date-wrap {
  display: flex;
  align-items: center;
  margin: 20px 0
}

.details-date-wrap span {
  display: block;
  background: linear-gradient(to right, #0035E5 0%, #298CFF 70%);
  background-clip: text;
  color: #fff0;
  margin-right: 100px;
  font-size: 14px
}

.details-date-wrap p {
  margin-bottom: 0
}

.study-detail-titles {
  font-size: 24px;
  margin-bottom: 15px
}

.detail-client-box ul {
  list-style: none;
  padding: 0
}

.detail-client-box ul li {
  font-size: 14px;
  line-height: 25px
}

.quot-details {
  padding: 15px 0 15px 20px;
  border-left: 1px solid #4D4D4D;
  margin: 20px 0
}

.quot-details span {
  font-size: 14px;
  text-transform: capitalize
}

.quot-details .study-detail-titles {
  background: linear-gradient(to right, #0035E5 0%, #298CFF 70%);
  background-clip: text;
  color: #fff0;
  font-size: 22px;
  text-transform: none
}

.case-detail-content ul {
  list-style: none;
  padding-left: 20px
}

.case-detail-content ul li {
  font-size: 14px;
  line-height: 27px;
  position: relative;
  color: rgb(0 0 0 / 70%)
}

.case-detail-content ul li::before {
  content: "";
  width: 7px;
  height: 7px;
  background: linear-gradient(to right, #0035E5 0%, #298CFF 70%);
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%
}

.case-detail-content {
  padding-bottom: 30px;
  border-bottom: 1px solid #4D4D4D
}

.detail-social-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px
}

.detail-social-wrap .detail-user-box {
  display: flex;
  align-items: center
}

.detail-user-box img {
  width: 40px;
  border-radius: 50%
}

.detail-user-box span {
  display: block;
  margin-left: 15px;
  font-size: 14px;
  color: #040404;
  font-weight: 500;
  text-transform: capitalize
}

.detail-social-box {
  display: flex;
  align-items: center
}

.detail-social-box label {
  margin-bottom: 0;
  color: #101010;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500
}

.detail-social-box ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center
}

.detail-social-box ul li {
  margin: 0 10px
}

.detail-social-box ul li:last-child {
  margin: 0 0 0 10px
}

.detail-social-box ul li a {
  color: #2e2e2e;
  font-size: 15px;
  transition: all 0.2s ease-In
}

.detail-social-box ul li a:hover {
  color: #298cff
}

.detail-card-area {
  margin-bottom: 80px
}

.head-case-detailcard {
  margin-bottom: 50px
}

.casedetail-card-wraper {
  border-radius: 10px;
  transition: all 0.3s ease;
  margin-bottom: 30px
}

.casedetail-card-wraper .case-detailcard-content {
  padding: 20px;
  background: #f0fcff;
  border: 1px solid rgb(0 0 0 / 30%);
  border-radius: 0 0 10px 10px;
  transition: all 0.3s ease
}

.casedetail-card-wraper .case-detailcard-img {
  overflow: hidden;
  padding: 0;
  background: #f0fcff;
  border: 0;
  border-radius: 10px 10px 0 0
}

.casedetail-card-wraper .case-detailcard-img img {
  border-radius: 10px 10px 0 0;
  width: 100%;
  transition: all 0.2s ease
}

.casedetail-card-wraper:hover .case-detailcard-img img {
  transform: scale(1.1)
}

.casedetail-card-wraper:hover .case-detailcard-content {
  background: #fff
}

.casedetail-card-wraper:hover {
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / .2)
}

.case-detailcard-content ul {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0
}

.case-detailcard-content ul li {
  color: #000;
  font-size: 14px;
  position: relative;
  padding-right: 20px;
  text-transform: capitalize;
  line-height: 1
}

.case-detailcard-content ul li::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 10px;
  background: #000
}

.case-detailcard-content ul li:last-child::after {
  display: none
}

.case-detailcard-content .heading {
  font-size: 22px;
  min-height: 100px;
  margin-bottom: 20px
}

.case-detailcard-content span {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 40%);
  background-clip: text;
  font-weight: 700;
  color: #fff0;
  font-size: 14px
}

.case-detailcard-content span img {
  width: 11px;
  margin-left: 5px;
  transition: all 0.3s ease-in
}

.casedetail-card-wraper:hover .case-detailcard-content span img {
  margin-left: 8px
}

.pricing-launch-area {
  padding: 80px 0 50px;
  position: relative
}

.pricing-launch-box {
  padding: 30px 30px;
  position: relative;
  border-radius: 15px;
  margin: 50px 20px 0;
  overflow: hidden;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  transition: all 0.4s ease-in-out;
  border-left: 1px solid #0035e5;
  border-top: 1px solid #0035e5;
  border-right: 1px solid #298cff;
  border-bottom: 1px solid #298cff
}

.pricing-launch-box:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 0
}

.pricing-launch-box img {
  transition: all 0.2s ease;
  margin: 0 auto;
  width: 85%
}

.pricing-launch-box .launch-vec {
  position: absolute;
  top: -20px;
  left: -30px;
  width: 200px;
  z-index: -1;
  transition: all 0.4s ease;
  opacity: 0;
  mix-blend-mode: multiply;
  transform: translate(-20px, -20px)
}

.pricing-launch-box .launch-vec-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  transition: all 0.4s ease
}

.pricing-launch-box h4,
.pricing-launch-box h3 {
  color: #fff0;
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text;
  font-size: 28px;
  margin: 30px 0 10px;
  padding-top: 20px;
  transition: all 0.2s ease;
  text-transform: capitalize;
  border-top: 1px solid rgb(0 0 0 / 20%)
}

.pricing-launch-box p {
  margin: 0;
  transition: all 0.2s ease
}

.pricing-launch-box:hover:before {
  opacity: 1
}

.pricing-launch-box:hover h4,
.pricing-launch-box:hover h3 {
  color: #fff;
  border-color: #fff
}

.pricing-launch-box:hover p {
  color: rgb(255 255 255 / .7)
}

.pricing-launch-box:hover .launch-vec {
  opacity: .6;
  transform: translate(0, 0)
}

.pricing-launch-box:hover .launch-vec-1 {
  opacity: 0
}

.price-searvices {
  margin: 60px 0 80px;
  position: relative
}

.price-searvices .heading {
  margin-bottom: 10px
}

.price-searvice-box {
  padding: 50px 30px 30px;
  background: #f0fcff;
  border: 1px solid rgb(0 0 0 / 30%);
  transition: all 0.3s ease;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  margin: 60px 20px 0;
  position: relative
}

.price-searvice-box img {
  width: 65px;
  position: absolute;
  top: -33px;
  left: 0;
  right: 0;
  margin: 0 auto
}

.price-searvice-box h4,
.price-searvice-box h3 {
  font-size: 22px;
  transition: all 0.3s ease
}

.price-searvice-box p {
  margin: 0;
  min-height: 70px
}

.price-searvice-box:hover {
  background: #fff;
  border-color: #0035e5
}

.price-searvice-box:hover h4,
price-searvice-box:hover h3 {
  color: #fff0;
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text
}

.price-searvices .default-btn {
  margin-top: 30px
}

.price-partners {
  padding: 30px 0;
  background: url(../public/assets/home/price-partner-back-1.webp) no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden
}

.price-partners .heading {
  color: #fff;
  font-size: 35px;
  margin-bottom: 5px
}

.price-partners span {
  font-size: 24px;
  color: #fff;
  display: block;
  font-family: Gilroy
}

.price-partners .default-white {
  margin-top: 25px
}

.price-contact {
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px
}

.price-contact p {
  font-size: 20px;
  color: #202020;
  font-weight: 500;
  margin-bottom: 30px
}

.price-contact .heading {
  font-size: 60px
}

.price-contact .price-contact-vec {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  z-index: -1
}

.price-contact .price-contact-vec1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1
}

.price-head-area {
  padding-top: 50px;
  position: relative
}

.price-head-content {
  position: relative;
  z-index: 1
}

.price-head-content label {
  font-size: 32px;
  font-family: Gilroy
}

.price-head-content .sub-heading {
  font-size: 18px;
  font-family: Gilroy;
  margin: 10px 0 15px;
  display: block;
  color: #000
}

.price-head-content p {
  margin: 0 0 30px;
  color: #000
}

.price-head-content small {
  color: #fff;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
  background: linear-gradient(to right, transparent, rgb(0 0 0 / 90%) 30%, rgb(0 0 0 / 90%) 70%, transparent);
  padding: 5px 0;
  width: 70%;
  margin: 0px auto 15px;
}

.price-head-content .price-head-content-vec {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  width: 100%;
  height: auto
}

.price-head-img img {
  margin: 0 auto;
  width: 100%
}

.price-head-vec1 {
  position: absolute;
  left: 0;
  bottom: -40px;
  width: 150px
}

.price-head-vec2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 150px
}

.price-business-box .heading {
  font-size: 26px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  position: relative;
  font-family: Gilroy
}

.price-business-box .heading::before {
  content: "";
  width: 25%;
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  position: absolute;
  height: 2px;
  left: 0;
  bottom: 0;
  border-radius: 10px
}

.price-business-content .accordion-item {
  border: none;
  margin-top: 15px
}

.price-business-content .accordion-header button {
  padding: 0;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 600
}

.price-business-content .accordion-header button:focus {
  border: none;
  outline: 0;
  box-shadow: none
}

.price-business-content .accordion-item:hover button {
  border: none;
  color: #fff0;
  background: linear-gradient(to right, #0035E5 0%, #298CFF 70%);
  background-clip: text;
  outline: 0;
  box-shadow: none
}

.price-business-content .accordion-header .accordion-button::after {
  display: none
}

.price-business-content .accordion-body {
  padding: 0;
  font-size: 14px;
  color: rgba(0 0 0 / 70%)
}

.price-manager {
  padding-bottom: 80px;
  position: relative;
  overflow: hidden
}

.col-lg-6.pricing-manage-one {
  padding-right: 0
}

.col-lg-6.pricing-manage-two {
  padding-left: 0
}

.price-manager-box {
  padding: 30px;
  border-left: 1px solid rgb(0 0 0 / 10%);
  border-top: 1px solid rgb(0 0 0 / 10%);
  border-top-left-radius: 10px;
  box-shadow: -10px -10px 30px -10px rgb(0 0 0 / 10%)
}

.price-manager-box img {
  width: 50px
}

.price-manager-box .heading {
  font-size: 32px;
  margin: 15px 0
}

.price-manager-box p {
  margin-bottom: 0
}

.price-manager-imgwrap {
  width: 100px;
  display: inline-block;
  margin: 0 120px
}

.price-manager-vec1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 130px;
  z-index: -1
}

.price-manager-vec2 {
  position: absolute;
  position: absolute;
  bottom: -150px;
  right: 0;
  width: 180px;
  z-index: -1
}

.price-service-vec1 {
  position: absolute;
  left: 0;
  top: 10px;
  width: 140px;
  z-index: -1
}

.pricing-faq {
  margin-top: 80px
}

.email-vector-wrap {
  display: flex;
  align-items: center
}

.email-vector-wrap img {
  margin: 0!important;
  margin-right: 7px!important;
  width: 17px!important;
}

.footer-policy-box {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.footer-policy-box a {
  font-size: 12px;
  letter-spacing: .4px;
  display: block
}

.footer-country-wrap {
  display: flex;
  align-items: center
}

.footer-country-wrap div {
  width: 25px;
  margin-right: 17px;
  position: relative
}

.footer-country-wrap div::after {
  width: 1px;
  height: 100%;
  content: "";
  background: #D9D9D9;
  top: 0;
  right: -8px;
  position: absolute
}

.footer-country-wrap div:last-child:after {
  display: none
}

.footer-gradiant-links a {
  color: #298cff !important
}

.certificate-vector {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: -1
}

.modal-certificate-content .heading {
  font-size: 24px;
  margin-bottom: 15px
}

.modal-certificate-content p:last-child {
  margin-bottom: 0
}

.certificate-info-popup .modal-body {
  padding: 40px;
  z-index: 1
}

.bike-Rental-head {
  padding: 160px 0;
  background: url(../public/assets/home/bike-rental-head.webp) no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden
}

.bike-rental-head-wrap .heading {
  color: #fff
}

.bike-rental-head-wrap .sub-heading {
  color: #fff
}

.bike-rental-head-wrap small{
  color: #fff;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
  background: linear-gradient(to right, transparent, rgb(0 0 0 / 90%) 30%, rgb(0 0 0 / 90%) 70%, transparent);
  padding: 5px 0;
  width: 60%;
  margin: 20px auto -15px;
}

.bike-rental-head-wrap .default-btn {
  margin-top: 30px
}

.bike-rental-workflow {
  margin: 80px 0
}

.br-playstore-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px
}

.br-playstore-btn a {
  margin: 0 20px
}

.br-playstore-btn a:hover img {
  transform: scale(1.05)
}

.br-playstore-btn a img {
  width: 110px;
  transition: all 0.3s ease-in
}

.br-workflow-box {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 15px;
  margin-bottom: 30px;
  padding: 25px;
  border: 1px solid rgb(0 0 0 / 10%);
  box-shadow: 0 1px 14px 0 rgb(0 0 0 / 15%);
  z-index: 1;
  background: url(../public/assets/home/workflow-br-img1.webp) no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 0.3s ease
}

.br-workflow-box:last-child {
  margin: 0 15px
}

.br-workflow-box-reverse {
  background: url(../public/assets/home/workflow-br-img2.webp) no-repeat;
  background-position: center;
  background-size: cover
}

.br-workflow-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.3s ease-in-out;
  opacity: 0
}

.br-workflow-box:hover::before {
  opacity: 1
}

.br-workflow-box .heading {
  font-size: 22px;
  transition: all 0.2s ease-in;
  margin-bottom: 8px
}

.br-workflow-box p {
  transition: all 0.2s ease-in;
  margin-bottom: 0;
  min-height: 50px
}

.br-workflow-box:hover .heading {
  color: #fff
}

.br-workflow-box:hover p {
  color: #fff
}

.br-img-animation {
  position: relative
}

.br-img-animation img {
  width: 225px;
  margin: 0 auto
}

.br-animate-box {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  border-radius: 50%;
  transform: translate(-50%, -50%)
}

.br-animate-box::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  height: 100%;
  background: #fff0;
  border: 2px solid #0035e5;
  border-radius: 50%;
  animation: wave-line-pulse 2500ms ease-out infinite;
  z-index: -1;
  animation-delay: .6s
}

.br-animate-box::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  height: 100%;
  background: #fff0;
  border: 2px solid #298cff;
  border-radius: 50%;
  animation: wave-line-pulse 2500ms ease-out infinite;
  z-index: -2
}

@keyframes wave-line-pulse {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.6);
    opacity: 0
  }
}

.bike-renatl-mobility .center-heading {
  margin-bottom: 40px
}

.br-mobility-link {
  margin-top: 25px
}

.br-mobility-link .nav-item {
  margin-top: 0;
  margin-bottom: 40px
}

.br-mobility-link .nav-link {
  background: #fff;
  border-radius: 15px;
  border: 1px solid #fff;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;
  position: relative;
  z-index: 1;
  overflow: hidden
}

.br-mobility-link .nav-link::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right, #dfe7ff 0%, #ffe9da 100%);
  transition: opacity 0.3s ease;
  z-index: -1
}

.br-mobility-link .active.nav-link::before {
  opacity: 0
}

.br-mobility-link .nav-link.active {
  border-color: #0035e5;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .2)
}

.br-mobility-link .nav-link div {
  display: flex;
  align-items: center
}

.br-mobility-link .nav-link div img {
  width: 42px;
  margin-right: 12px
}

.br-mobility-link .nav-link div h4 {
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0;
  line-height: 1.3
}

.br-mobility-link .nav-link span {
  background-image: linear-gradient(to bottom, #d3bdbd 0%, rgb(211 189 189 / 5%) 85%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: #fff0;
  color: #fff0;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 80px;
  line-height: 1
}

.br-mobility-arrow {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 20px;
  transition: all 0.2s ease;
  transform: rotate(45deg)
}

.br-mobility-link .nav-link.active .br-mobility-arrow {
  transform: none
}

.brmobility-data {
  background: url(../public/assets/home/br-mobility-back.webp) no-repeat;
  background-position: center;
  background-size: cover;
  padding: 50px 60px 0 50px;
  border-radius: 8px;
  margin-right: -55px;
  position: relative;
  overflow: hidden;
  z-index: 0
}

.brmobility-data .tab-pane {
  animation: fadeInUp 2s ease;
  animation-duration: 1s;
  transition: 2000ms cubic-bezier(.165, .84, .44, 1)
}

.br-mobility-phone {
  margin: 0 auto;
  width: 60%
}

.br-mobility-pc {
  width: 80%
}

.br-mobility-content {
  position: relative;
  padding-bottom: 50px
}

.br-mobility-content h4,
.br-mobility-content h3 {
  color: #fff;
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 20px
}

.br-mobility-content p {
  color: #fff;
  margin-bottom: 0
}

.br-mobility-content .default-white {
  position: absolute;
  left: 0;
  bottom: -20px;
  border: 1px solid #fff;
  color: #fff
}

.br-mobility-content .default-white::after {
  background: #fff0
}

.electric-bike-area {
  margin: 80px 0
}

.electric-bike-wrapper {
  margin-top: 50px;
  transition: all 0.3s ease-in;
  border-radius: 8px;
  overflow: hidden
}

.electric-bike-wrapper:hover {
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / .2)
}

.electric-bike-img {
  overflow: hidden
}

.electric-bike-img img {
  border-radius: 8px 8px 0 0;
  transition: all 0.3s ease-in
}

.electric-bike-wrapper:hover .electric-bike-img img {
  transform: scale(1.1)
}

.electric-bike-content {
  border-left: 1px solid #0035e5;
  border-bottom: 1px solid #0035e5;
  border-right: 1px solid #298cff;
  border-radius: 0 0 8px 8px;
  padding: 20px 15px;
  background-color: #F0FCFF;
  position: relative
}

.electric-bike-content h5,
.electric-bike-content h3 {
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text;
  color: #fff0;
  transition: all 0.3s ease;
  font-size: 22px
}

.electric-bike-content p {
  transition: all 0.3s ease;
  margin-bottom: 0
}

.electric-bike-content::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 0
}

.electric-bike-wrapper:hover .electric-bike-content {
  background-color: #fff0
}

.electric-bike-wrapper:hover .electric-bike-content::before {
  opacity: 1
}

.electric-bike-wrapper:hover .electric-bike-content h5,
.electric-bike-wrapper:hover .electric-bike-content h3 {
  color: #fff
}

.electric-bike-wrapper:hover .electric-bike-content p {
  color: #fff
}

.bike-leaders-slider {
  margin-top: 30px
}

.bike-leaders-slider .slick-slide {
  padding: 0 20px
}

.bike-leaders-slider .custom-prev {
  left: 0
}

.bike-leaders-slider .custom-next {
  right: 0
}

.bike-leaders-slider .custom-arrow {
  position: absolute;
  top: 50%;
  background: #fff;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateY(-50%);
  z-index: 2;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / .2);
  cursor: pointer;
  transition: all 0.3s ease-in-out
}

.bike-leaders-slider .custom-arrow:hover {
  background-color: #0035e5
}

.bike-leaders-slider .custom-arrow svg {
  font-size: 14px;
  color: #0035e5;
  transition: all 0.3s ease-in-out
}

.bike-leaders-slider .custom-arrow:hover svg {
  color: #fff
}

.bike-leaders-box {
  display: flex !important;
  margin: 20px 0;
  border: 1px solid rgb(0 0 0 / 10%);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / .2)
}

.bike-leaders-img {
  width: 45%
}

.bike-leaders-text {
  position: relative;
  padding: 40px 30px;
  width: 55%
}

.bike-leaders-text::after {
  content: "";
  background: url(../public/assets/home/br-leader-back.webp);
  background-position: center;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
  transition: all 0.4s ease-in-out
}

.bike-leaders-box:hover .bike-leaders-text::after {
  opacity: 1
}

.bike-leaders-text h5,
.bike-leaders-text h3 {
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text;
  color: #fff0;
  transition: all 0.3s ease-in-out;
  font-size: 22px
}

.bike-leaders-text p {
  transition: all 0.3s ease-in-out
}

.bike-leaders-box:hover .bike-leaders-text h5,
.bike-leaders-box:hover .bike-leaders-text h3 {
  color: #fff
}

.bike-leaders-box:hover .bike-leaders-text p {
  color: #fff
}

.all-features-btn {
  margin-top: 10px
}

.why-wait-area {
  margin: 80px 0 50px;
  background: url(../public/assets/home/why-wait-back.webp) no-repeat;
  background-position: center;
  background-size: cover;
  padding: 25px 0
}

.why-wait-content .heading {
  color: #fff;
  font-size: 35px;
  margin-bottom: 5px
}

.why-wait-content p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px
}

.web-booking-area {
  margin-bottom: 80px
}

.web-booking-box {
  border: 1px solid #809dff;
  padding: 30px;
  background-color: #F0FCFF;
  border-radius: 10px;
  margin: 30px 15px 0 15px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 2
}

.web-booking-box:hover {
  background: #fff0;
  box-shadow: 0 2px 20px 1px rgb(0 0 0 / 20%)
}

.web-booking-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.4s ease-in-out;
  opacity: 0
}

.web-booking-box:hover::before {
  opacity: 1
}

.web-booking-box span {
  border: 1px solid #000;
  padding: 7px;
  display: inline-block;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out
}

.web-booking-box:hover span {
  border: 1px solid #298cff
}

.web-booking-box:hover span img {
  filter: none
}

.web-booking-box span img {
  width: 25px;
  filter: brightness(0) saturate(100%);
  transition: all 0.3s ease-in-out
}

.web-booking-box h4,
.web-booking-box h3 {
  font-size: 20px;
  background: linear-gradient(to right, #0035e5, #298cff 70%);
  background-clip: text;
  color: #fff0;
  transition: all 0.3s ease-in-out;
  margin: 12px 0
}

.web-booking-box:hover h4,
.web-booking-box:hover h3 {
  color: #fff
}

.web-booking-box p {
  transition: all 0.3s ease-in-out;
  margin-bottom: 0
}

.web-booking-box:hover p {
  color: #fff
}

.web-book-back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  z-index: 0
}

.web-booking-box:hover .web-book-back {
  opacity: 0
}

.custom-app-banner {
  position: relative;
  margin-bottom: 65px
}

.custom-banner-img img {
  width: 100%
}

.custom-app-head {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.custom-app-head .heading {
  color: #fff
}

.custom-app-head .sub-heading {
  color: #fff;
  text-transform: capitalize
}

.custom-app-head small{
  color: #fff;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
  background: linear-gradient(to right,transparent, rgb(0 0 0 / 90%) 50%, transparent);
  padding: 5px 10px;
  margin: 20px auto -15px;
}

.custom-app-banner .container {
  position: absolute;
  bottom: -65px;
  left: 0;
  right: 0
}

.custom-head-logobox {
  background: url(../public/assets/home/custom-app-logo-back.webp) no-repeat;
  background-position: center;
  background-size: cover;
  padding: 30px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / .2)
}

.custom-head-logobox img {
  width: 70px;
  filter: brightness(.7) saturate(0%);
  transition: all 0.2s ease
}

.custom-head-logobox img:hover {
  filter: none
}

.custom-app-need {
  padding: 80px 0
}

.need-standout {
  margin-bottom: 40px
}

.need-standout p {
  color: rgb(0 0 0 / 50%);
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 8px
}

.product-need-head {
  font-size: 30px
}

.custom-app-needlist {
  list-style: none;
  padding: 0;
  margin: 0
}

.custom-app-needlist li {
  border-top: 1px solid rgb(0 0 0 / 10%);
  padding: 7px 25px;
  position: relative;
  font-size: 18px;
  font-family: Gilroy;
  font-weight: 600
}

.custom-app-needlist li::before {
  content: "";
  background: url(../public/assets/home/custom-app-list-icon.png) no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 15px;
  left: 0;
  width: 11px;
  height: 11px
}

.custom-app-needlist li:last-child {
  border-bottom: 1px solid rgb(0 0 0 / 10%)
}

.enterprises-head p {
  color: rgb(0 0 0 / 50%);
  font-weight: 500;
  margin-top: 8px
}

.enterprises-box {
  padding: 30px;
  background: #F0FCFF;
  border: 1px solid #0238E6;
  border-radius: 12px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  transition: all 0.3s ease
}

.enterprises-box::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: url(../public/assets/home/enterprises-box-back.webp) no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
  transition: all 0.4s ease
}

.enterprises-box .enterprises-logo {
  padding: 10px;
  background: #fff;
  display: inline-block;
  border: 1px solid #000;
  border-radius: 8px;
  margin-bottom: 15px;
  transition: all 0.3s ease
}

.enterprises-box .enterprises-logo img {
  width: 27px;
  filter: saturate(0) brightness(1);
  transition: all 0.3s ease
}

.enterprises-box .heading {
  font-size: 22px;
  margin-bottom: 12px;
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  background-clip: text;
  color: #fff0;
  transition: all 0.3s ease;
  min-height: 33px
}

.enterprises-box span {
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 50%);
  background-clip: text;
  color: #fff0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: Gilroy;
  display: flex;
  align-items: center;
  transition: all 0.3s ease
}

.enterprises-box span img {
  width: 11px;
  margin-left: 5px;
  transition: all 0.3s ease
}

.enterprises-box:hover {
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / .2)
}

.enterprises-box:hover::before {
  opacity: 1
}

.enterprises-box:hover .enterprises-logo img {
  filter: none
}

.enterprises-box:hover .enterprises-logo {
  border: 1px solid #0035e5
}

.enterprises-box:hover .heading {
  color: #fff
}

.enterprises-box:hover p {
  color: #fff
}

.enterprises-box:hover span {
  color: #fff
}

.enterprises-box:hover span img {
  color: #fff;
  filter: invert(60%) brightness(200%)
}

.custom-cycle {
margin: 80px 0;
}

.homecard-top {
  margin-top: 180px
}

.custom-cycle-card {
  border-radius: 15px;
  padding: 30px 20px;
  overflow: hidden;
  background:  #f0fcff;
  margin-top:30px;
  position: relative;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / .2);
  transition: 0.4s;
  z-index: 0;
}

.custom-cycle-card:hover {
  background:  transparent;
}

.custom-cycle-card::before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: " ";
  background: url(../public/assets/home/custom-cycle-card-back.webp) no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  opacity: 0;
  transition: 0.4s;
}

.custom-cycle-card:hover::before {
  opacity: 1;
}

.custom-cycle-card span{
  width: 45px;
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #0238E6 , #298cff);
  transition: 0.4s;
}

.custom-cycle-card:hover span {
  background: #fff;
}

.custom-cycle-card span img {
  width: 25px;
  filter: brightness(0) invert(1);
  transition: 0.3s;
}

.custom-cycle-card:hover span img {
  filter:  none;
}

.custom-cycle-card div {
  margin-top: 15px
}

.custom-cycle-card h4 {
  font-size: 24px;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-family: Gilroy;
  transition: 0.3s;
}

.custom-cycle-card:hover h4 {
  color: #fff;
}

.custom-cycle-box-fixer .custom-cycle-card h4{
  min-height: 60px;
}

.custom-cycle-card p {
  font-family: Gilroy;
  font-weight: 300;
  transition: 0.3s;
}

.custom-cycle-card.web-agile-card p {
  margin-bottom: 0;
}

.custom-cycle-card:hover p {
  color: #fff;
}

.custom-cycle-card ul {
  margin: 0;
  padding-left: 15px;
  font-size: 15px;
  font-family: Gilroy;
  font-weight: 500;
  text-transform: capitalize;
  transition: 0.3s;
}

.custom-cycle-card:hover ul {
  color: #fff;
}

.custom-cycle-space {
  padding-left: 30px
}

.why-custom-app {
  position: relative;
  margin: 80px 0
}

.why-custom-vec {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-20%);
  width: 100px;
  z-index: -1
}

.why-reasons-wrap {
  padding-left: 20px;
  margin-top: 40px
}

.why-reasons {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(0 0 0 / 11%);
  padding: 15px 5px
}

.why-reasons:last-child {
  border-bottom: 1px solid rgb(0 0 0 / 11%)
}

.why-reasons span {
  width: 30%;
  font-size: 18px;
  font-family: Gilroy;
  font-weight: 600;
  display: block;
  transition: all 0.3s ease
}

.why-reasons p {
  width: 60%;
  margin-bottom: 0
}

.why-reasons:hover span {
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 50%);
  background-clip: text;
  color: #fff0
}

.custom-tools {
  position: relative
}

.custom-tool-vec {
  position: absolute;
  width: 180px;
  right: 0;
  bottom: 0;
  z-index: -1
}

.custom-tools-head p {
  text-transform: capitalize;
  margin-top: 8px
}

.custom-tools-box {
  margin-top: 30px
}

.custom-tools-box h4 {
  font-family: Gilroy;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px
}

.custom-tools-box span {
  display: block;
  margin-bottom: 10px;
  color: rgb(0 0 0 / 70%);
  font-weight: 600;
  transition: all 0.2s ease;
  text-transform: capitalize
}

.custom-tools-box span i {
  font-weight: 500
}

.custom-tools-box span:hover {
  background: linear-gradient(to right, #0035E5 0%, #298cff 70%);
  background-clip: text;
  color: #fff0
}

.custom-tech-giant {
  margin: 80px 0
}

.tech-giant-details {
  border-top: 1px solid rgb(0 0 0 / 16%);
  padding-top: 30px
}

.tech-giant-details .heading {
  font-size: 30px;
  margin-bottom: 20px
}

.tech-giant-years {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.tech-giant-years span {
  display: block;
  font-size: 55px;
  font-weight: 600;
  background: linear-gradient(30deg, #0035e5 0%, #298cff 70%);
  background-clip: text;
  color: #fff0
}

.tech-giant-years label {
  font-size: 14px;
  text-transform: capitalize;
  margin: 0;
  font-weight: 600;
  font-family: Gilroy
}

.goodfirm-widget {
  width: 100%;
  height: 100%;
  margin-left: -15px;
}


.rating-blank-star {
  background-position: -63px -87px !important;
  height: 26px !important;
  margin: 0 auto;
  width: 34px !important
}

.goodfirm-widget .logo {
  padding: 0 0;
  max-width: 174px !important;
  width: 100%;
  height: 29px !important
}

.goodfirm-widget .logo img {
  width: 100% !important;
  height: 100% !important
}

.goodfirm-widget .widget-t1 {
  text-align: left !important
}

.goodfirm-widget .review_block {
  padding-bottom: 8px !important;
  margin-left: -11px !important;
  padding-top: 8px !important
}

.web-app-banner.custom-app-banner {
  margin-bottom: 0
}

.web-app-banner .custom-app-head .sub-heading {
  text-transform: none
}

.web-app-banner .custom-app-head .default-btn {
  margin-top: 30px
}

.web-app-business {
  position: relative;
  padding: 80px 0
}

.web-business-vec1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 760px;
  z-index: -1
}

.web-business-vec2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 170px;
  z-index: -1
}

.web-business-vec3 {
  position: absolute;
  left: 0;
  bottom: -200px;
  width: 80px;
  z-index: -1
}

.web-app-business .heading {
  margin-bottom: 40px
}

.webapp-service-box {
  position: relative;
  background: #E5FAFF;
  padding: 30px;
  margin-top: 40px;
  border: 1px solid #000;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  min-height: 332px
}

.webapp-service-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  z-index: -1;
  opacity: 0;
  transition: all 0.4s ease
}

.webapp-service-box div {
  padding: 10px;
  background: #fff;
  display: inline-block;
  border: 1px solid #000;
  border-radius: 5px;
  transition: all 0.3s ease
}

.webapp-service-box div img {
  width: 30px;
  filter: brightness(.5) saturate(0);
  transition: all 0.3s ease
}

.webapp-service-box .heading {
  font-size: 24px;
  margin: 15px 0;
  transition: all 0.3s ease;
  font-family: Gilroy
}

.webapp-service-box p {
  margin: 0;
  transition: all 0.3s ease
}

.webapp-service-box .service-webapp-vec {
  position: absolute;
  top: 0;
  right: 0;
  width: 140px
}

.webapp-service-box:hover {
  background: #fff0;
  border: 1px solid #0035e5;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / .2)
}

.webapp-service-box:hover::before {
  opacity: 1
}

.webapp-service-box:hover div {
  border: 1px solid #0238E6;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / .2)
}

.webapp-service-box:hover div img {
  filter: none
}

.webapp-service-box:hover .heading .gradiant-color {
  background: #fff;
  background-clip: text
}

.webapp-service-box:hover p {
  color: #fff
}

.web-app-story {
  margin: 80px 0;
  position: relative
}

.webapp-story-vec {
  position: absolute;
  right: 0;
  bottom: -100px;
  width: 100px
}

.webapp-story-details .webapp-story-subhead {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  font-family: Gilroy;
  margin: 15px 0
}

.webapp-story-details label {
  display: block;
  margin-top: 15px;
  margin-bottom: 0
}

.webapp-story-details label a {
  color: #fff0;
  background: linear-gradient(to right, #0035e5 0%, #298cff 100%);
  background-clip: text;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  font-family: Gilroy;
  border-bottom: 1px solid #298cff;
  transition: all 0.2s ease;
  display: inline-block
}

.webapp-story-details label a:hover {
  transform: scale(1.05)
}

.web-app-world {
  position: relative;
  display: flex;
  align-items: center
}

.webapp-world-map {
  position: absolute;
  right: 0;
  width: 40%
}

.webapp-world-detail p {
  margin: 20px 0;
  color: rgb(0 0 0 / 40%);
  font-weight: 600
}

.world-counts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
  padding: 5px 0
}

.world-counts:last-child {
  border: 0
}

.world-counts div {
  display: flex;
  align-items: center
}

.world-counts div span {
  background-image: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  background-clip: text;
  color: #fff0;
  font-size: 60px;
  font-weight: 600;
  font-family: Gilroy;
  margin-right: 15px
}

.world-counts div label {
  margin: 0;
  font-family: Gilroy;
  font-weight: 600;
  color: rgb(0 0 0 / 80%);
  font-size: 15px;
  text-transform: capitalize
}

.webapp-world-container {
  position: relative
}

.webapp-world-container img {
  width: 100%
}

.webapp-world-container .india-dot {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%)
}

.webapp-world-container .istanbul-dot {
  position: absolute;
  left: 0;
  top: 20%
}

.webapp-world-container .taiwan-dot {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translatey(-50%)
}

.map-dot-container img {
  width: 25px;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.3s ease
}

.map-dot-container .default-btn:has(+img:hover) {
  opacity: 1;
  transform: translateY(0)
}

.map-dot-container img:hover {
  transform: scale(1.5)
}

.map-dot-container .default-btn {
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  opacity: 0;
  transform: translatey(-30px);
  transition: all 0.4s ease
}

.map-dot-container .default-btn img {
  width: 13px;
  margin-right: 8px
}

.web-app-conversation {
  margin: 80px 0 0;
  background: url(../public/assets/home/web-app-conversation.webp) no-repeat;
  background-position: center;
  background-size: cover;
  padding: 60px 0
}

.web-app-conversation .heading {
  color: #fff;
  margin-bottom: 30px
}

.web-agile-card {
  /* background: url(../public/assets/home/web-agile-back.webp) no-repeat; */
  background-position: center;
  background-size: cover
}

.web-impression-spacer {
  padding: 40px 0;
  margin: 80px 0;
}

.webapp-impression-box {
  width: 100%;
  padding: 50px;
  border-radius: 10px;
  position: relative;
}

.impression-wrap-1 {
  background: url(../public/assets/home/webapp-impression-back-1.webp) no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 80px;
}

.impression-wrap-2 {
  background: url(../public/assets/home/webapp-impression-back-2.webp) no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 50px;
}

.impression-wrap-3 {
  background: url(../public/assets/home/webapp-impression-back-3.webp) no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 0;
  overflow: hidden;
  margin-top: 50px;
}

.webapp-impression-details .heading {
  font-family: Gilroy;
  font-size: 30px;
  color: #fff;
  font-weight: 800
}

.webapp-impression-details p {
  color: #fff
}

.webapp-impression-details p:last-child {
  margin-bottom: 0
}

.webapp-impression-details span {
  font-size: 18px;
  font-weight: 600;
  font-family: Gilroy;
  padding: 15px 0;
  border-top: 1px solid rgba(255 255 255 / 50%);
  border-bottom: 1px solid rgba(255 255 255 / 50%);
  color: #fff;
  display: flex;
  margin: 30px 0;
  align-items: center;
  justify-content: space-between
}

.webapp-impression-details span div {
  width: 60%
}

.webapp-impression-details span label {
  font-size: 45px
}

.web-app-appreciate {
  margin-top: 50px
}

.caselist-top-cards {
  position: relative;
  padding: 40px 0
}

.caselist-top-cards .top-cards-vec1 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  width: 480px
}

.caselist-top-cards .top-cards-vec2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1
}

.topcards-wrap {
  margin-top: 40px
}

.topcard-img-wrap {
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.topcard-img-wrap img {
  width: 100%;
  transition: all 0.3s ease
}

.topcard-img-wrap span {
  position: absolute;
  top: 20px;
  left: 20px;
  display: block;
  padding: 5px 15px;
  background: #fff;
  color: #000;
  text-transform: uppercase;
  font-family: Gilroy;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px
}

.topcard-content-wrap {
  padding: 30px 20px;
  border: 1px solid rgb(0 0 0 / 12%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.3s ease;
  border-top: 0;
  background-color: #fff
}

.topcard-content-wrap h2,
.topcard-content-wrap h3 {
  color: #000;
  font-size: 16px;
  margin-bottom: 15px
}

.topcard-content-wrap label {
  background: linear-gradient(to right, #0035E5 0%, #298CFF 80%);
  background-clip: text;
  color: #fff0;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  text-transform: uppercase
}

.topcard-content-wrap label img {
  width: 10px;
  margin-left: 5px;
  transition: all 0.3s ease
}

.topcard-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  margin-bottom: 20px
}

.topcard-head img {
  width: 100px
}

.topcard-head span {
  display: block;
  padding: 5px 15px;
  background: #fff;
  color: #000;
  text-transform: capitalize;
  font-family: Gilroy;
  font-weight: 500;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0 / 30%)
}

.topcards-wrap:hover .topcard-img-wrap img {
  transform: scale(1.1)
}

.topcards-wrap:hover .topcard-content-wrap {
  border-color: #0035E5;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%)
}

.topcards-wrap:hover .topcard-content-wrap label img {
  transform: rotate(-45deg)
}

.caselist-bottom-cards {
  margin: 50px 0 80px
}

.bottomcards-wrap {
  margin-bottom: 30px
}

.bottomcard-img-wrap {
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden
}

.bottomcard-img-wrap img {
  width: 100%;
  transition: all 0.3s ease
}

.bottomcard-img-wrap span {
  position: absolute;
  top: 10px;
  left: 10px;
  display: block;
  padding: 5px 15px;
  background: #fff;
  color: #000;
  text-transform: capitalize;
  font-family: Gilroy;
  font-weight: 500;
  font-size: 12px;
  border-radius: 5px
}

.bottomcard-content-wrap {
  padding: 20px;
  border: 1px solid rgb(0 0 0 / 12%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.3s ease;
  border-top: 0;
  background-color: #fff
}

.bottomcard-content-wrap h4,
.bottomcard-content-wrap h3 {
  color: #000;
  font-size: 14px;
  margin-bottom: 15px;
  min-height: 50px
}

.bottomcard-content-wrap label {
  background: linear-gradient(to right, #0035E5 0%, #298CFF 80%);
  background-clip: text;
  color: #fff0;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  text-transform: uppercase
}

.bottomcard-content-wrap label img {
  width: 10px;
  margin-left: 5px;
  transition: all 0.3s ease
}

.bottomcard-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  margin-bottom: 20px
}

.bottomcard-head img {
  width: 120px
}

.bottomcards-wrap:hover .bottomcard-img-wrap img {
  transform: scale(1.1)
}

.bottomcards-wrap:hover .bottomcard-content-wrap {
  border-color: #0035E5;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%)
}

.bottomcards-wrap:hover .bottomcard-content-wrap label img {
  transform: rotate(-45deg)
}

.case-banner-area {
  padding: 40px 0
}

.case-banner-area .heading {
  font-size: 26px;
  font-family: Gilroy;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(0 0 0 / 12%)
}

.casetext-banner-location {
  display: flex;
  align-items: center;
  padding: 15px 0 20px
}

.casetext-banner-location div {
  display: flex;
  align-items: center;
  margin-right: 50px
}

.casetext-banner-location div img {
  margin-right: 10px;
  width: 23px
}

.casetext-banner-location div span {
  text-transform: capitalize;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 13px;
  color: rgb(0 0 0 / 80%)
}

.case-text-banner {
  width: 100%
}

.case-index-link {
  list-style: none;
  padding: 0;
  margin: 0
}

.case-index-link li {
  margin-bottom: 10px
}

.case-index-link li a {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  text-transform: capitalize;
  transition: all 0.3s ease;
  font-family: Gilroy
}

.case-index-link li a:hover {
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text;
  color: #fff0
}

.case-text-details h2,
.case-text-details h3,
.case-text-details h4 {
  font-size: 28px;
  margin: 40px 0 25px
}

.case-text-details ul {
  list-style: none;
  padding-left: 20px
}

.case-text-details ul li {
  font-size: 14px;
  line-height: 27px;
  position: relative;
  color: rgb(0 0 0 / 70%)
}

.case-text-details ul li::before {
  content: "";
  width: 7px;
  height: 7px;
  background: linear-gradient(to right, #0035E5 0%, #298CFF 70%);
  position: absolute;
  left: -20px;
  top: 10px;
  border-radius: 50%
}

.casetext-detail-banner {
  width: 100%;
  margin-top: 10px
}

.case-scalability {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.case-scalability img {
  width: 42%
}

.case-scale-detail {
  width: 50%
}

.case-scale-detail .case-scale-wrap {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 30px;
  width: 100%
}

.case-scale-detail .case-scale-wrap:last-child {
  margin-bottom: 0
}

.case-scale-detail .case-scale-img {
  width: 10%;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center
}

.case-scale-img img {
  width: 20px
}

.case-scale-detail div {
  width: 100%
}

.case-scale-detail div label,
.case-scale-detail div p strong {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  font-family: Gilroy
}

.case-scale-detail div span,
.case-scale-detail div p {
  color: rgb(0 0 0 / 80%);
  font-size: 13px;
  line-height: 20px;
  display: block;
  margin-bottom: 10px
}

.case-detailcards-area {
  margin: 80px 0
}

.case-result-image {
  margin-top: 30px
}

.case-detailcards-area .bottomcards-wrap {
  margin: 40px 0 0
}

.industries-banner {
  margin-bottom: 0
}

.industries-banner .custom-app-head .heading span {
  font-size: 38px
}

.industries-banner .custom-app-head .default-btn {
  margin-top: 30px
}

.industry-logistic {
  margin: 80px 0
}

.industry-left-box {
  position: relative;
  margin-top: 30px;
  border-radius: 8px;
  overflow: hidden
}

.industry-left-box div {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 20px;
  transform: translateY(-50%)
}

.industry-left-box div span {
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-family: Gilroy;
  margin-bottom: 8px
}

.industry-left-box div p {
  color: #fff;
  margin: 0;
  font-size: 13px
}

.industry-right-box {
  position: relative;
  margin-top: 30px;
  border-radius: 8px;
  overflow: hidden
}

.industry-right-box div {
  position: absolute;
  width: 50%;
  top: 20px;
  left: 20px
}

.industry-right-box div span {
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-family: Gilroy;
  margin-bottom: 8px
}

.industry-right-box div p {
  color: #fff;
  margin: 0;
  font-size: 13px
}

.industry-searvice-box {
  border: 1px solid #0238E6;
  padding: 30px;
  border-radius: 8px;
  background: url(../public/assets/home/industry-solution-front.webp) no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer
}

.industry-searvice-box::before {
  content: "";
  background: url(../public/assets/home/industry-solution-back.webp) no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  transition: all 0.4s ease
}

.industry-searvice-box:hover {
  background: #fff0;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%)
}

.industry-searvice-box:hover::before {
  opacity: 1
}

.industry-searvice-img {
  border: 1px solid #000;
  background: #fff;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin-bottom: 30px;
  transition: all 0.3s ease
}

.industry-searvice-img img {
  width: 27px;
  filter: brightness(0) saturate(0);
  transition: all 0.3s ease
}

.industry-searvice-box:hover .industry-searvice-img {
  border-color: #0238E6
}

.industry-searvice-box:hover .industry-searvice-img img {
  filter: none
}

.industry-searvice-box h4 {
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  background-clip: text;
  color: #fff0;
  font-size: 18px;
  transition: all 0.3s ease;
  min-height: 45px
}

.industry-searvice-box:hover h4 {
  color: #fff
}

.industry-searvice-box p {
  transition: all 0.3s ease
}

.industry-searvice-box:hover p {
  color: #fff
}

.industry-searvice-arrow {
  text-align: right
}

.industry-searvice-arrow span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.3s ease;
  position: relative;
  color: #fff;
  font-size: 14px;
  z-index: 0;
  overflow: hidden
}

.industry-searvice-box:hover .industry-searvice-arrow span {
  transform: rotate(-45deg);
  background: #fff0;
  color: #0035e5
}

.industry-searvice-arrow span::before {
  content: "";
  position: absolute;
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: all 0.4s ease
}

.industry-searvice-box:hover .industry-searvice-arrow span::before {
  opacity: 1
}

.industry-desing {
  margin: 80px 0;
  position: relative
}

.industry-desing .industry-design-vec {
  position: absolute;
  top: -140px;
  left: 0;
  width: 90px;
  z-index: -1
}

.design-tab-wrap {
  background: url(../public/assets/home/industry-design-back.webp) no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 15px;
  padding: 20px 0;
  position: relative
}

.design-tab-wrap .industry-truck-img {
  position: absolute;
  width: 130px;
  right: 0;
  bottom: 0
}

.industry-design-content h4 {
  font-size: 24px
}

.industry-design-content a {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%);
  background-clip: text;
  color: #fff0;
  margin: 0
}

.industry-design-content a svg {
  font-size: 10px;
  color: #298cff;
  margin-left: 3px;
  transition: all 0.3s ease
}

.industry-design-content a:hover svg {
  margin-left: 6px
}

.industry-design-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0 !important;
  margin-top: 40px
}

.industry-design-tab li button {
  border: 0 !important;
  margin: 0 35px;
  padding: 0;
  font-family: Gilroy;
  color: rgb(0 0 0 / 70%);
  font-weight: 600;
  position: relative
}

.industry-design-tab li button:hover {
  color: rgb(0 0 0 / 70%)
}

.industry-design-tab li button::before {
  content: "";
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%);
  left: 0;
  bottom: -14px;
  border-radius: 10px;
  position: absolute;
  transition: all 0.4s ease;
  transform: scale3d(0, 1, 1);
  transform-origin: 50% 50%
}

.industry-design-tab li button:hover::before {
  transform: scale3d(1, 1, 1)
}

.industry-design-tab li button.active {
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%);
  background-clip: text;
  color: transparent !important
}

.industry-design-tab li button.active::before {
  transform: none
}

.industry-highend {
  position: relative
}

.industry-highend-vec1 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-20%);
  width: 90px;
  z-index: -1
}

.industry-highend-details {
  margin-top: 40px
}

.industry-highend-details h4 {
  text-transform: capitalize
}

.industry-highend-img {
  position: relative;
  margin-top: 40px
}

.industry-highend-img .highend-vec {
  position: absolute;
  top: -90px;
  left: -120px;
  z-index: -1
}

.track-details-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.track-details-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 47%;
  border: 1px solid rgb(0 0 0 / 20%);
  padding: 10px 15px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 12%);
  z-index: 0;
  margin-top: 30px;
  transition: all 0.3s ease
}

.track-details-box:hover {
  border-color: #0238E6
}

.track-details-box::before {
  content: "";
  background: url(../public/assets/home/track-back.webp) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: all 0.4s ease
}

.track-details-box:hover::before {
  opacity: 1
}

.track-details-box span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 12%);
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease
}

.track-details-box:hover span {
  background: #fff
}

.track-details-box span::before {
  content: "";
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 1;
  transition: all 0.4s ease
}

.track-details-box:hover span::before {
  opacity: 0
}

.track-details-box span img {
  width: 23px;
  transition: all 0.3s ease;
  filter: invert(.5) brightness(2)
}

.track-details-box:hover span img {
  filter: none
}

.track-details-box div {
  width: 78%
}

.track-details-box div label {
  font-size: 15px;
  font-family: Gilroy;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.3s ease
}

.track-details-box:hover div label {
  color: #fff
}

.track-details-box div p {
  font-size: 12px;
  font-family: Gilroy;
  margin: 0;
  transition: all 0.3s ease
}

.track-details-box:hover div p {
  color: #fff
}

.track-start-btn {
  margin-top: 30px
}

.industry-highend-two {
  position: relative;
  margin: 100px 0 130px
}

.industry-highend-vec2 {
  width: 100px;
  position: absolute;
  left: 0;
  bottom: -130px;
  z-index: -1
}

.industry-highend-two .industry-highend-img {
  position: absolute;
  margin: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 35%
}

.industry-highend-two .industry-highend-details {
  margin-top: 0
}

.industry-transform {
  margin: 80px 0;
  padding: 40px 0;
  background: url(../public/assets/home/industry-transform-back.webp) no-repeat center;
  background-size: cover
}

.industry-transform-box {
  text-align: center;
  margin-top: 40px
}

.industry-transform-box span {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  font-family: Gilroy;
  display: block;
  margin-bottom: 10px
}

.industry-transform-box img {
  width: 75%;
  margin: 0 auto
}

.consultation-content-industry p {
  margin: 30px 0 20px;
  text-transform: capitalize;
  font-weight: 600;
  font-family: Gilroy;
  color: #000;
  font-size: 15px
}

.consultation-content-industry h4 {
  font-size: 24px;
  margin-bottom: 30px
}

.industry-need-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0 !important;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 12%);
  padding: 0 !important;
  background: url(../public/assets/home/industry-need-headtab.png) no-repeat center;
  background-size: cover;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 15px !important
}

.industry-need-tab li button {
  border: 0 !important;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: #000;
  transition: all 0.3s ease;
  position: relative;
  padding: 20px 20px !important
}

.industry-need-tab li button:hover {
  color: #298CFF
}

.industry-need-tab li button::before {
  content: "";
  background: url(../public/assets/home/industry-need-headbefore.png)no-repeat center;
  background-size: contain;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -7px
}

.industry-need-tab li:last-child button::before {
  opacity: 0
}

.industry-need-tab li button.active {
  background: linear-gradient(to right, #0238E6 0%, #298cff 90%) !important;
  background-clip: text !important;
  color: transparent !important
}

.industry-needtab-content {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: end;
  padding: 40px 0;
  background: url(../public/assets/home/needtab-content-back.webp) no-repeat center;
  background-size: cover;
  border-radius: 12px
}

.industry-needtab-content img {
  width: 70%
}

.industry-needtab-content div {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 30%
}

.industry-needtab-content div h4 {
  color: #fff
}

.industry-needtab-content div p {
  color: #fff;
  margin-bottom: 30px
}

.industry-pricing {
  padding: 50px 0;
  background: url(../public/assets/home/industry-price-back.webp) no-repeat center;
  background-size: cover;
  margin-bottom: 80px
}

.industry-pricing .heading {
  color: #fff
}

.industry-pricing span {
  color: #fff;
  display: block;
  margin: 20px 0;
  font-family: Gilroy;
  font-size: 16px;
  text-transform: uppercase
}

.industry-pricing p {
  color: #fff;
  margin-bottom: 30px;
  font-family: Gilroy
}

.healthcare-banner label {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  font-family: "Gilroy";
  margin-bottom: 5px;
  padding: 3px 10px;
  background: linear-gradient(to right, #6919BA 0%, #EC9151 90%)
}

.healthcare-banner .heading span {
  font-size: 38px
}

.healthcare-banner .sub-heading{
  margin: 15px 0 30px
}

.healthcare-banner small,
.ecom-banner small{
  margin: -5px auto 15px;
}

.healthcare-expertise .container {
  position: relative
}

.health-expertise-header {
  margin-bottom: 30px
}

.health-expertise-areas .nav-pills .nav-link {
  color: rgb(0 0 0 / 70%);
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Gilroy";
  font-size: 20px;
  transition: all 0.3s ease;
  display: inline-block;
  padding: 0;
  border-radius: 0;
  padding-bottom: 18px;
  position: relative
}

.health-expertise-areas .nav-pills .nav-link::before {
  content: "";
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background: url(../public/assets/home/health-expertise-before.png) no-repeat center;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: 12px;
  opacity: 0;
  transition: all 0.3s ease
}

.health-expertise-areas .nav-pills .nav-link.active,
.health-expertise-areas .nav-pills .show>.nav-link {
  background: linear-gradient(to right, #0035E5 0%, #298CFF 70%);
  background-clip: text;
  color: #fff0;
  font-weight: 600
}

.health-expertise-areas .nav-pills .nav-link.active::before,
.health-expertise-areas .nav-pills .show>.nav-link::before {
  opacity: 1
}

.health-expertise-img {
  position: absolute;
  bottom: 0;
  right: 15px;
  width: 400px
}

.health-expertise-content .heading {
  font-size: 30px
}

.health-expertise-content p {
  width: 60%;
  margin-top: 8px
}

.health-expertise-content ul {
  list-style: none;
  padding: 0;
  margin-bottom: 30px
}

.health-expertise-content ul li {
  font-size: 16px;
  text-transform: capitalize;
  color: #000;
  margin-bottom: 8px;
  position: relative;
  padding-left: 25px;
  font-weight: 500
}

.health-expertise-content ul li:last-child {
  margin-bottom: 0
}

.health-expertise-content ul li::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: linear-gradient(to right, #0035E5 0%, #298CFF 70%);
  border-radius: 2px;
  left: 0;
  top: 9.5px
}

.health-challenges {
  margin: 80px 0
}

.health-challenge-box {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 50px 10px 0;
  background: url(../public/assets/home/health-challenge-back.webp) no-repeat center;
  background-size: cover;
  border: 1px solid #0238E6;
  transition: all 0.3s ease
}

.health-challenge-box:hover {
  background: #fff0;
  transform: translateY(-20px);
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%)
}

.health-challenge-box::before {
  content: "";
  position: absolute;
  width: 101%;
  height: 101%;
  left: -2px;
  top: -2px;
  background: url(../public/assets/home/health-challenge-before.webp) no-repeat center;
  background-size: cover;
  border: 1px solid #0238E6;
  z-index: -1;
  opacity: 0;
  transition: all 0.4s ease
}

.health-challenge-box:hover::before {
  opacity: 1
}

.health-challenge-box img {
  width: 100%
}

.health-challenge-box .health-challenge-detail {
  padding: 25px
}

.health-challenge-box .health-challenge-detail h4 {
  color: #000;
  font-weight: 700;
  font-size: 24px;
  transition: all 0.3s ease
}

.health-challenge-box:hover .health-challenge-detail h4 {
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%);
  background-clip: text;
  color: #fff0
}

.health-challenge-box .health-challenge-detail p {
  margin-bottom: 0;
  min-height: 63px;
  font-weight: 500
}

.health-challenge-btn {
  margin-top: 30px
}

.health-partner {
  background: url(../public/assets/home/health-partner-back.webp) no-repeat center;
  background-size: cover;
  padding: 30px 0
}

.health-partner-detail {
  color: #fff;
  text-align: right
}

.health-partner-detail span {
  color: #fff;
  font-size: 26px;
  display: block;
  text-transform: capitalize
}

.health-partner-detail h3 {
  color: #fff;
  font-size: 32px
}

.health-partner-detail p {
  color: #fff;
  margin-bottom: 20px
}

.patient-support {
  margin: 50px 0 80px
}

.patient-support-detail {
  background: url(../public/assets/home/patient-support-back.webp) no-repeat center;
  background-size: cover;
  padding: 110px 0;
  border-radius: 10px
}

.patient-support-detail div {
  padding: 0 50px;
  margin-top: 40px
}

.patient-support-detail div p {
  font-size: 28px;
  font-weight: 500;
  font-family: "Gilroy";
  margin-bottom: 10px;
  color: rgb(0 0 0 / 80%)
}

.patient-support-detail div span {
  font-weight: 600
}

.patient-support-detail div h3 {
  font-size: 38px;
  font-family: "Gilroy";
  font-weight: 600
}

.patient-support-box {
  background-color: #E5FAFF;
  padding: 30px;
  margin-top: 30px;
  border: 1px solid #298CFF;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease
}

.patient-support-box:hover {
  background-color: #fff0
}

.patient-support-box::before {
  position: absolute;
  width: 102%;
  height: 102%;
  left: -2px;
  top: -2px;
  z-index: -1;
  content: "";
  background: url(../public/assets/home/patient-box-before.webp) no-repeat center;
  background-size: cover;
  opacity: 0;
  transition: all 0.4s ease
}

.patient-support-box:hover::before {
  opacity: 1
}

.patient-supportbox-img {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  z-index: 3
}

.patient-supportbox-img img {
  width: 60px;
  filter: brightness(0) saturate(0);
  transition: all 0.3s ease;
  position: relative;
  z-index: 3
}

.patient-support-box:hover .patient-supportbox-img img {
  filter: none
}

.patient-supportbox-img div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #000;
  transition: all 0.3s ease;
  border-radius: 50%;
  width: 40px;
  z-index: 0;
  height: 40px
}

.patient-support-box:hover .patient-supportbox-img div {
  border-color: #fff
}

.patient-support-box h4 {
  font-size: 24px;
  font-weight: 600;
  font-family: "Gilroy";
  min-height: 57px;
  background: linear-gradient(to right, #0035e5 0%, #298CFF 70%);
  color: #fff0;
  background-clip: text
}

.patient-support-box:hover h4 {
  color: #fff
}

.patient-support-box p {
  margin-bottom: 0
}

.patient-support-box:hover p {
  color: #fff
}

.health-industry-area {
  position: relative
}

.health-industry-slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.health-industry-slides .slick-slider {
  display: flex;
  align-items: end;
  height: 100%
}

.health-industry-slides .slick-list {
  height: 100%
}

.health-industry-slides .slick-list .slick-track {
  height: 100%
}

.health-industry-slides .slick-list .slick-slide div {
  height: 100%
}

.health-industry-box {
  border-right: 1px solid #fff;
  position: relative;
  z-index: 9;
  overflow: hidden
}

.health-industry-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 40%);
  z-index: 0;
  opacity: 0;
  transition: all 0.3s ease
}

.health-industry-box:hover::before {
  opacity: 1
}

.health-industry-box div {
  height: 100%;
  padding: 20px;
  transition: all 0.3s ease
}

.health-industry-box div div {
  display: flex;
  justify-content: end;
  flex-direction: column;
  transform: translateY(85px);
  transition: all 0.3s ease
}

.health-industry-box:hover div div {
  transform: translateY(0)
}

.health-industry-box div h4 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600
}

.health-industry-box div p {
  color: #fff;
  opacity: 0;
  transition: all 0.3s ease;
  font-size: 12px;
  min-height: 57px
}

.health-industry-box:hover div p {
  opacity: 1
}

.health-industry-box div span {
  color: #fff;
  opacity: 0;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer
}

.health-industry-box div span svg {
  margin-left: 5px;
  transition: all 0.3s ease
}

.health-industry-box:hover div span {
  opacity: 1
}

.health-industry-box div span:hover svg {
  transform: rotate(-45deg)
}

.slick-current .health-industry-box div div {
  transform: translateY(0)
}

.slick-current .health-industry-box div p {
  opacity: 1
}

.slick-current .health-industry-box div span {
  opacity: 1
}

.slick-current .health-industry-box::before {
  opacity: 1
}

.health-industry-slides button svg {
  font-size: 22px;
  color: #fff
}

.health-industry-slides button svg {
  font-size: 28px;
  color: #fff
}

.health-industry-slides .slick-prev {
  left: 15px;
  z-index: 11
}

.health-industry-slides .slick-next {
  right: 15px;
  z-index: 11
}

.health-industry-slides button::before {
  display: none
}

.health-industry-head {
  color: #fff;
  position: absolute;
  top: 50px;
  left: 140px;
  z-index: 4
}

.health-tech-area {
  margin: 80px 0
}

.health-tech-big-box {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 40px;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 10%)
}

.health-tech-big-box div {
  position: absolute;
  top: 50%;
  left: 0;
  padding: 30px;
  transform: translateY(-50%)
}

.health-tech-big-box div img {
  width: 45px;
  margin-bottom: 15px
}

.health-tech-big-box div span {
  font-size: 50px;
  color: #fff;
  font-family: "Gilroy";
  font-weight: 600
}

.health-tech-big-box div h4 {
  font-size: 28px;
  color: #fff;
  font-family: "Gilroy";
  font-weight: 600;
  margin-bottom: 0;
  text-transform: capitalize
}

.health-tech-box {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 25px;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 10%)
}

.health-tech-box div {
  position: absolute;
  top: 50%;
  left: 0;
  padding: 30px;
  transform: translateY(-50%)
}

.health-tech-box div img {
  width: 45px;
  margin-bottom: 20px
}

.health-tech-box div h4 {
  font-family: "Gilroy";
  color: #fff;
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize
}

.health-workflow-content h3 {
  font-size: 32px;
  margin-bottom: 15px
}

.health-workflow-content div {
  display: flex;
  align-items: center;
  margin-bottom: 20px
}

.health-workflow-content div img {
  width: 35px;
  margin-right: 20px
}

.health-workflow-content div span {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  text-transform: capitalize
}

.health-workflow-content a {
  margin-top: 10px
}

.health-workflow-2 {
  margin: 100px 0 80px
}

.health-workflow-content-2 p {
  margin: 10px 0 20px
}

.health-workflow-content-2 .workflow-list {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.health-workflow-content-2 .workflow-list ul {
  list-style: none;
  padding: 0
}

.health-workflow-content-2 .workflow-list ul li {
  position: relative;
  margin-bottom: 10px;
  font-size: 15px;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  padding-left: 25px
}

.health-workflow-content-2 .workflow-list ul li:last-child {
  margin-bottom: 0
}

.health-workflow-content-2 .workflow-list ul li::before {
  content: "";
  background: url(../public/assets/home/workflow-right-tick.webp) no-repeat center;
  background-size: cover;
  position: absolute;
  width: 11px;
  height: 11px;
  top: 6px;
  left: 0
}

.order-app-header {
  margin-bottom: 40px
}

.orderapp-img-container {
  position: relative
}

.orderapp-img-container .notification-img {
  width: 230px;
  margin: 0 auto
}

.orderapp-img-container .orange-blush {
  width: 280px;
  position: absolute;
  top: -30px;
  left: 43%;
  z-index: -1
}

.orderapp-img-container .aqua-blush {
  width: 220px;
  position: absolute;
  bottom: 0;
  left: 11%;
  z-index: -1
}

.orderapp-tabs .nav-pills .nav-link {
  padding: 0;
  border-radius: 8px;
  color: #000;
  border: 1px solid rgb(0 0 0 / 20%);
  padding: 15px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 12%);
  position: relative;
  overflow: hidden
}

.orderapp-tabs .nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 102%;
  height: 102%;
  left: -2px;
  top: -2px;
  opacity: 0;
  z-index: -1;
  background: url(../public/assets/home/order-tabs-nav.webp) no-repeat center;
  background-size: cover;
  transition: all 0.4s ease
}

.orderapp-tabs .nav-pills .nav-link.active,
.orderapp-tabs .nav-pills .show>.nav-link {
  transform: translateX(-30px);
  background: #fff0
}

.orderapp-tabs .nav-pills .nav-link.active::before,
.orderapp-tabs .nav-pills .show>.nav-link::before {
  opacity: 1
}

.orderapp-tabs-wrap {
  overflow: hidden
}

.orderapp-tabs-wrap div {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.orderapp-tabs-wrap div span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%);
  transition: all 0.3s ease
}

.orderapp-tabs-wrap p {
  margin-top: 10px;
  display: none;
  transition: all 0.3s ease;
  margin-bottom: 0
}

.orderapp-tabs .nav-pills .nav-link.active .orderapp-tabs-wrap p,
.orderapp-tabs .nav-pills .show>.nav-link .orderapp-tabs-wrap p {
  color: #fff;
  display: block
}

.orderapp-tabs .nav-pills .nav-link.active .orderapp-tabs-wrap div span,
.orderapp-tabs .nav-pills .show>.nav-link .orderapp-tabs-wrap div span {
  background: #fff;
  transform: rotate(-180deg);
  color: #0035e5
}

.orderapp-tabs-wrap div h3 {
  color: #000;
  font-size: 22px;
  margin-bottom: 0;
  transition: all 0.3s ease
}

.orderapp-tabs .nav-pills .nav-link.active .orderapp-tabs-wrap div h3,
.orderapp-tabs .nav-pills .show>.nav-link .orderapp-tabs-wrap div h3 {
  color: #fff
}

.food-vendor {
  margin: 80px 0;
  background: url(../public/assets/home/food-vendor-back.webp) no-repeat center;
  background-size: cover;
  padding: 50px 0
}

.food-vendor-head {
  margin-bottom: 30px
}

.food-vendor-cards .nav-pills .nav-link {
  padding: 0;
  position: relative;
  background: url(../public/assets/home/food-vendor-card.png) no-repeat center;
  background-size: cover;
  color: #000;
  z-index: 0;
  margin: 18px 0;
  margin-left: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 12%)
}

.food-vendor-cards .nav-pills .nav-link::before {
  content: "";
  position: absolute;
  background: url(../public/assets/home/food-vendor-before.jpg) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  border-radius: 8px;
  transition: all 0.4s ease
}

.food-vendor-cards .nav-pills .nav-link.active,
.food-vendor-cards .nav-pills .show>.nav-link {
  background: #fff0;
  transform: translateX(20px)
}

.food-vendor-cards .nav-pills .nav-link.active::before,
.food-vendor-cards .nav-pills .show>.nav-link::before {
  opacity: 1
}

.food-vendor-card {
  padding: 25px 25px 25px 40px;
  position: relative
}

.food-vendor-card p {
  margin: 0;
  transition: all 0.3s ease
}

.food-vendor-cards .nav-pills .nav-link.active .food-vendor-card p,
.food-vendor-cards .nav-pills .show>.nav-link .food-vendor-card p {
  color: #fff
}

.food-vendor-card h4 {
  font-size: 22px
}

.food-vendor-card span {
  position: absolute;
  top: 30px;
  left: -17px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%);
  border: 1px solid #0238E6
}

.food-vendor-cards .nav-pills .nav-link.active .food-vendor-card span,
.food-vendor-cards .nav-pills .show>.nav-link .food-vendor-card span {
  color: #000;
  background: #fff
}

.fooddelivery-img-container {
  position: relative
}

.food-delivery-head h3 {
  font-size: 38px;
  margin-bottom: 10px
}

.food-delivery-head p {
  margin-bottom: 25px
}

.fooddelivery-img-container .report-img {
  width: 320px;
  margin: 0 auto
}

.fooddelivery-img-container .orange-blush {
  width: 280px;
  position: absolute;
  top: -50px;
  left: 43%;
  z-index: -1
}

.fooddelivery-img-container .purple-blush {
  width: 220px;
  position: absolute;
  bottom: 0;
  left: 11%;
  z-index: -1
}

.fooddelivery-tabs .nav-pills .nav-link {
  padding: 0;
  border-radius: 8px;
  color: #000;
  border: 1px solid rgb(0 0 0 / 20%);
  padding: 15px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 12%);
  position: relative;
  overflow: hidden
}

.fooddelivery-tabs .nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 102%;
  height: 102%;
  left: -2px;
  top: -2px;
  opacity: 0;
  z-index: -1;
  background: url(../public/assets/home/delivery-tabs-nav.webp) no-repeat center;
  background-size: cover;
  transition: all 0.4s ease
}

.fooddelivery-tabs .nav-pills .nav-link.active,
.fooddelivery-tabs .nav-pills .show>.nav-link {
  background: #fff0
}

.fooddelivery-tabs .nav-pills .nav-link.active::before,
.fooddelivery-tabs .nav-pills .show>.nav-link::before {
  opacity: 1
}

.fooddelivery-tabs-wrap {
  overflow: hidden
}

.fooddelivery-tabs-wrap div {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.fooddelivery-tabs-wrap div span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%);
  transition: all 0.3s ease
}

.fooddelivery-tabs-wrap p {
  margin-top: 10px;
  display: none;
  transition: all 0.3s ease;
  margin-bottom: 0
}

.fooddelivery-tabs .nav-pills .nav-link.active .fooddelivery-tabs-wrap p,
.fooddelivery-tabs .nav-pills .show>.nav-link .fooddelivery-tabs-wrap p {
  color: #fff;
  display: block
}

.fooddelivery-tabs .nav-pills .nav-link.active .fooddelivery-tabs-wrap div span,
.fooddelivery-tabs .nav-pills .show>.nav-link .fooddelivery-tabs-wrap div span {
  background: #fff;
  transform: rotate(-180deg);
  color: #0035e5
}

.fooddelivery-tabs-wrap div h3 {
  color: #000;
  font-size: 22px;
  margin-bottom: 0;
  transition: all 0.3s ease
}

.fooddelivery-tabs .nav-pills .nav-link.active .fooddelivery-tabs-wrap div h3,
.fooddelivery-tabs .nav-pills .show>.nav-link .fooddelivery-tabs-wrap div h3 {
  color: #fff
}

.food-admin-area {
  background: url(../public/assets/home/food-admin-back.webp) no-repeat center;
  background-size: cover;
  margin: 80px 0;
  padding: 50px 0 0
}

.food-admin-head {
  position: relative
}

.food-admin-head img {
  width: 100%
}

.food-admin-head div {
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  top: 90px;
  width: 100%
}

.food-admin-head div .heading {
  font-size: 38px;
  color: #fff
}

.food-admin-head div p {
  color: #fff
}

.food-admin-img img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 20%)
}

.food-admin-wrapper {
  background: url(../public/assets/home/food-admin-card.png) no-repeat center;
  background-size: cover;
  padding: 30px;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%);
  border-radius: 12px
}

.food-admin-box {
  padding: 0 30px;
  position: relative
}

.food-admin-box img {
  width: 45px;
  border-radius: 8px;
  margin: 0 auto 20px
}

.food-admin-box h4 {
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%);
  background-clip: text;
  color: #fff0;
  font-size: 22px
}

.food-admin-box p {
  margin-bottom: 0
}

.food-admin-box::before {
  content: "";
  position: absolute;
  background: url(../public/assets/home/food-admin-box-before.png) no-repeat center;
  background-size: cover;
  width: 2px;
  height: 100%;
  top: 0;
  bottom: 0;
  right: -23px
}

.foodadmin-last-box.food-admin-box::before {
  display: none
}

.food-addon-area {
  margin: 80px 0
}

.food-addon-wrapper {
  background: url(../public/assets/home/food-addon-back.webp) no-repeat center;
  background-size: cover;
  padding: 30px;
  position: relative;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%);
  transition: all 0.3s ease;
  margin: 30px 10px 0;
  border-radius: 10px;
  border: 1px solid #298cff;
  overflow: hidden
}

.food-addon-wrapper:hover {
  background: #fff0
}

.food-addon-wrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../public/assets/home/food-addon-before.webp) no-repeat center;
  background-size: cover;
  z-index: -1;
  opacity: 0;
  transition: all 0.4s ease
}

.food-addon-wrapper:hover::before {
  opacity: 1
}

.food-addon-wrapper div {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #84a1f973;
  border-radius: 50%;
  position: relative;
  margin-bottom: 20px
}

.food-addon-wrapper div span {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  transition: all 0.3s ease
}

.food-addon-wrapper:hover div span {
  background: #fff
}

.food-addon-wrapper div img {
  width: 24px;
  filter: brightness(900%) saturate(0);
  transition: all 0.3s ease
}

.food-addon-wrapper:hover div img {
  filter: none
}

.food-addon-wrapper h4 {
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  background-clip: text;
  color: #fff0;
  transition: all 0.3s ease;
  font-size: 22px
}

.food-addon-wrapper:hover h4 {
  color: #fff
}

.food-addon-wrapper p {
  transition: all 0.3s ease;
  margin: 0
}

.food-addon-wrapper:hover p {
  color: #fff
}

.food-smbs-head {
  margin-bottom: 30px
}

.food-smbs-box {
  padding: 15px 25px;
  border-radius: 10px;
  background: url(../public/assets/home/smbs-back.webp) no-repeat center;
  background-size: cover;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%);
  position: relative;
  margin: 15px 0
}

.food-smbs-box span {
  color: #fff;
  font-weight: 800;
  font-family: "Gilroy";
  font-size: 55px
}

.food-smbs-box p {
  color: #fff;
  margin: 0
}

.food-smbs-box img {
  position: absolute;
  width: 65px;
  top: 0;
  right: 0
}

.food-speed-area {
  margin: 80px 0
}

.lyft-disclame {
  margin: -30px 0 50px
}

.lyft-disclame p {
  font-size: 12px
}

.pharm-banner {
  background: linear-gradient(45deg, #E0F9FF 0%, #F4FDFF 100%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px
}

.pharm-banner .container {
  position: absolute
}

.pharm-banner-image {
  margin-right: -15px
}

.pharm-banner-details .purple-gradiant {
  background: linear-gradient(to right, #6919BA 0%, #EC9151 100%);
  padding: 3px 15px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Gilroy";
  display: inline-block;
  margin-bottom: 5px
}

.pharm-banner-details h1 {
  margin-bottom: 15px
}

.pharm-banner-details h1 small {
  font-size: 42px;
  font-weight: 500
}

.pharm-banner-details p {
  margin-bottom: 30px
}

.pharm-banner-details label {
  color: #fff;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
  background: linear-gradient(to right, rgb(0 0 0 / 90%) 50%, transparent);
  padding: 5px 10px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.pharmacy-order-area {
  padding: 80px 0;
  position: relative
}

.pharm-order-vec {
  position: absolute;
  top: 0;
  right: 0;
  width: 450px
}

.pharmacy-order-box {
  transition: all 0.3s ease;
  border-radius: 12px;
  margin-top: 30px
}

.pharmacy-order-box:hover {
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%)
}

.pharm-image {
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px
}

.pharm-image img {
  transition: all 0.3s ease;
  width: 100%
}

.pharmacy-order-box:hover .pharm-image img {
  transform: scale(1.1)
}

.pharm-details {
  border: 1px solid #0035e5;
  border-top: 0;
  background-color: #F0FCFF;
  padding: 25px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px
}

.pharmacy-order-box:hover .pharm-details {
  background-color: #fff0
}

.pharm-details::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #0238E6 0%, #298cff 80%);
  opacity: 0;
  z-index: -1;
  transition: all 0.4s ease
}

.pharmacy-order-box:hover .pharm-details::after {
  opacity: 1
}

.pharm-details h3 {
  font-size: 22px;
  background: linear-gradient(to right, #0238E6 0%, #298cff 80%);
  background-clip: text;
  color: #fff0;
  transition: all 0.3s ease
}

.pharmacy-order-box:hover .pharm-details h3 {
  color: #fff
}

.pharm-details p {
  transition: all 0.3s ease
}

.pharmacy-order-box:hover .pharm-details p {
  color: #fff
}

.pharm-details span {
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 800;
  font-family: "Gilroy";
  background: linear-gradient(to right, #0238E6 0%, #298cff 80%);
  background-clip: text;
  color: #fff0;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease
}

.pharmacy-order-box:hover .pharm-details span {
  color: #fff
}

.pharm-details span svg {
  color: #298cff;
  font-size: 10px;
  margin-left: 4px;
  transition: all 0.3s ease
}

.pharmacy-order-box:hover .pharm-details span svg {
  color: #fff;
  margin-left: 6px
}

.branded-pharm-area {
  background: url(../public/assets/home/brand-pharm-back.webp) no-repeat center;
  background-size: cover;
  padding-top: 50px;
  position: relative
}

.pharmacy-brand-detail .heading {
  color: #fff;
  text-shadow: 0 3px 6px rgb(0 0 0 / 15%);
  line-height: 65px
}

.pharmacy-brand-detail .heading span {
  background-color: #fff;
  padding: 0 8px;
  border-radius: 5px;
  text-shadow: none
}

.pharm-brand-image {
  position: relative;
  z-index: 0;
  margin-top: 40px
}

.pharm-brand-image .brand-vec-top {
  left: -20px;
  top: -20px;
  position: absolute;
  width: 65px;
  z-index: -1
}

.brand-vec-bottom {
  left: 0;
  bottom: -2px;
  position: absolute;
  width: 100%
}

.brand-farm-box {
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  background: #F0FCFF;
  transition: all 0.3s ease;
  overflow: hidden;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 20%);
  position: relative;
  margin-top: 40px
}

.brand-farm-box:hover {
  border-color: #0238E6;
  background: #fff0;
  transform: translateY(-15px)
}

.brand-farm-box::before {
  content: "";
  background: url(../public/assets/home/brand-farm-order-back.png) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  transition: all 0.4s ease
}

.brand-farm-box:hover::before {
  opacity: 1
}

.brand-farm-box img {
  width: 65px;
  margin: 0 auto
}

.brand-farm-box h3 {
  margin-top: 20px;
  font-size: 22px;
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  background-clip: text;
  color: #fff0;
  font-weight: 600;
  text-transform: capitalize
}

.brand-farm-box p {
  margin: 0
}

.pharmacy-step-area {
  background: url(../public/assets/home/pharmacy-step-back.webp) no-repeat center;
  background-size: cover;
  padding: 20px 0
}

.pharmacy-step-detail span {
  color: #fff;
  font-size: 32px;
  font-family: "Gilroy"
}

.pharmacy-step-detail h4 {
  color: #fff;
  font-size: 38px;
  font-weight: 600
}

.pharmacy-step-detail p {
  color: #fff;
  margin-bottom: 25px
}

.pharm-growth {
  margin: 80px 0
}

.pharm-growth-head {
  margin-bottom: 40px
}

.pharm-growth-details {
  margin: 20px 0
}

.pharm-growth-img {
  margin: 20px 0
}

.pharm-growth-details span {
  background: linear-gradient(to right, #6919BA 0%, #EC9151 100%);
  padding: 3px 15px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Gilroy";
  display: inline-block;
  margin-bottom: 10px
}

.pharm-growth-details h4 {
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%);
  background-clip: text;
  color: #fff0;
  font-size: 35px
}

.pharm-growth-details ul {
  margin: 0;
  margin-top: 10px;
  list-style: none;
  padding: 0
}

.pharm-growth-details ul li {
  padding-left: 20px;
  position: relative;
  font-size: 14px;
  margin-bottom: 10px
}

.pharm-growth-details ul li strong {
  text-transform: capitalize
}

.pharm-growth-details ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%)
}

.ecom-banner {
  margin-bottom: 0
}

.ecom-banner label {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  font-family: "Gilroy";
  margin-bottom: 5px;
  padding: 3px 10px;
  background: linear-gradient(to right, #6919BA 0%, #EC9151 90%)
}

.ecom-banner .heading span {
  font-size: 38px;
  font-weight: 500;
  display: block
}

.ecom-banner .custom-app-head {
  width: 60%
}

.ecom-banner .heading {
  font-size: 42px
}

.ecom-banner .sub-heading {
  margin: 15px 0 30px
}

.ecom-retail-area {
  padding: 80px 0;
  background: url(../public/assets/home/ecom-retail-back.webp) no-repeat center;
  background-size: cover
}

.ecom-retail-area .heading {
  font-size: 36px
}

.ecom-build-area .heading {
  margin-bottom: 40px
}

.vertical-accordion {
  display: flex;
  width: 100%;
  height: 450px;
  color: #fff
}

.vertical-accordion__item:not(.active__item) {
  cursor: pointer
}

.vertical-accordion__item {
  flex: 0 0 80px;
  position: relative;
  height: 100%;
  transition: flex .5s .35s;
  overflow: hidden
}

.vertical-accordion__background,
.vertical-accordion__background:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%
}

.vertical-accordion__header {
  position: absolute;
  padding: 28px 12px;
  width: 80px;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  writing-mode: tb-rl;
  transform: rotate(180deg);
  user-select: none;
  font-family: "Gilroy";
  text-transform: capitalize
}

.lazyloaded {
  opacity: 1;
  transition: opacity 250ms;
  transition-delay: 0ms
}

.vertical-accordion__background-item {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover
}

.vertical-accordion__background:after {
  content: "";
  display: block;
  background: rgb(0 0 0 / .4);
  mix-blend-mode: multiply
}

.vertical-accordion__background,
.vertical-accordion__background:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%
}

.vertical-accordion__content {
  padding: 25px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  opacity: 0;
  transform: translateY(20px);
  transition: transform 1.3s ease-in
}

.vertical-accordion__title {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 22px;
  white-space: pre-line;
  font-family: "Gilroy";
  text-transform: capitalize
}

.vertical-accordion__text {
  font-size: 14px;
  white-space: pre-line;
  font-family: "Gilroy"
}

.fade-in {
  animation: fadeIn 1s ease-in forwards
}

.active__item {
  flex: 1
}

.active__head {
  opacity: 0
}

.vertical-accordion__item:not(.active__item) .vertical-accordion__header {
  animation: fadeIn 1.6s ease-in forwards
}

.active__content {
  animation: fadeIn 0.3s ease-in forwards;
  animation-delay: 0.9s;
  transform: translateY(0)
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  60% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

.ecom-platforms .default-btn {
  margin-top: 30px
}

.platform-card-ecom {
  margin-top: 30px
}

.platform-card-ecom img {
  border-radius: 5px;
  width: 90%;
  margin: 0 auto
}

.ecom-collaborate-area {
  padding: 50px 0;
  background: linear-gradient(to right, #0035E5 0%, #298CFF 80%);
  position: relative;
  margin: 80px 0;
  z-index: 0
}

.ecom-collaborate-vec1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  z-index: -1
}

.ecom-collaborate-vec2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 700px;
  z-index: -1
}

.ecom-collaborate-area .heading {
  color: #fff
}

.ecom-collaborate-wrap {
  display: flex;
  justify-content: space-between
}

.ecom-collaborate-img {
  width: 22%;
  padding-top: 53px
}

.ecom-collaborate-detail {
  width: 75%;
  margin-top: 40px
}

.ecom-collaborate-detail h4 {
  color: #fff;
  font-size: 26px;
  text-transform: capitalize
}

.ecom-collaborate-detail p {
  color: #fff;
  margin-bottom: 0
}

.ecom-erp-area {
  position: relative
}

.ecom-erp-vector {
  position: absolute;
  left: 0;
  bottom: -80px;
  z-index: -1;
  width: 110px
}

.ecom-erp-wrap {
  margin-bottom: 35px;
  border-bottom: 1px solid #298cff
}

.ecom-erp-wrap h4 {
  font-size: 26px;
  transition: all 0.3s ease;
  text-transform: capitalize
}

.ecom-erp-wrap:hover h4 {
  background: linear-gradient(to right, #0238E6 0%, #298cff 80%);
  background-clip: text;
  color: #fff0;
  display: inline-block
}

.ecom-erp-wrap p {
  margin-bottom: 10px
}

.ecom-room-wrapper div {
  display: flex;
  align-items: center;
  margin-bottom: 30px
}

.ecom-room-wrapper div:last-child {
  margin-bottom: 0
}

.ecom-room-wrapper div img {
  width: 50px
}

.ecom-room-wrapper div span {
  margin-left: 20px;
  font-size: 20px;
  font-family: "Gilroy";
  text-transform: capitalize;
  font-weight: 600
}

.ecom-room-line {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%
}

.ecom-room-line img {
  width: 2.8px
}

.ecom-rooms .default-btn {
  margin-top: 30px
}

.ecom-consult-area {
  margin-bottom: 80px
}

.ecom-consult-wrap .nav-pills .nav-link.active,
.ecom-consult-wrap .nav-pills .show>.nav-link {
  background: #fff0;
  color: #000
}

.nav-pills .nav-link.active .ecom-consult-box div h4,
.nav-pills .show>.nav-link .ecom-consult-box div h4 {
  background: linear-gradient(to right, #0035e5 0%, #298cff 80%);
  color: #fff0;
  background-clip: text;
  display: inline-block
}

.ecom-consult-wrap .nav-pills a {
  color: #000;
  padding: 20px 0
}

.ecom-consult-wrap .nav-item {
  border-top: 1px solid rgb(0 0 0 / 20%)
}

.ecom-consult-wrap .nav-item:last-child {
  border-bottom: 1px solid rgb(0 0 0 / 20%)
}

.ecom-consult-box {
  display: flex
}

.ecom-consult-box span {
  color: rgb(0 0 0 / 70%);
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  margin: 4px 15px 0 0;
  font-family: "Gilroy"
}

.ecom-consult-box div h4 {
  font-size: 24px;
  text-transform: capitalize
}

.ecom-consult-box div p {
  margin-bottom: 0;
  display: none
}

.nav-pills .nav-link.active .ecom-consult-box div p,
.nav-pills .show>.nav-link .ecom-consult-box div p {
  display: block
}

.ecom-system-upper {
  padding: 50px 0;
  background: url(../public/assets/home/ecom-system-upper.webp) no-repeat center;
  background-size: cover;
  margin-top: 80px
}

.ecom-system-upper h3 {
  color: #fff;
  font-size: 36px;
  text-transform: capitalize
}

.ecom-system-lower {
  padding: 20px 0;
  background: url(../public/assets/home/ecom-system-lower.webp) no-repeat center;
  background-size: cover;
  margin-bottom: 80px
}

.ecom-system-lower p {
  margin: 0;
  color: #fff
}

.ecom-tech-upper {
  background: url(../public/assets/home/ecom-tech-upper.webp) no-repeat center;
  background-size: cover
}

.ecom-tech-lower {
  background: url(../public/assets/home/ecom-tech-lower.webp) no-repeat center;
  background-size: cover
}

.ecom-end-upper {
  background: url(../public/assets/home/ecom-end-upper.webp) no-repeat center;
  background-size: cover
}

.ecom-end-lower {
  background: url(../public/assets/home/ecom-end-lower.webp) no-repeat center;
  background-size: cover
}

.ecom-case-area {
  margin-top: 80px
}

.ecom-case-area .ecom-case-head {
  margin-bottom: 40px
}

.ecomcase-text-slides {
  height: 100%
}

.ecomcase-text-slides .slick-slider {
  height: 100%
}

.ecomcase-text-slides .ecom-textslide-details {
  transition: all 0.2s ease;
  opacity: 0
}

.ecomcase-text-slides .slick-active .ecom-textslide-details {
  opacity: 1
}

.ecomcase-text-slides .slick-next,
.ecomcase-text-slides .slick-prev {
  font-size: 14px;
  color: #298CFF;
  background: #fff;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / 12%);
  transition: all 0.3s ease
}

.ecomcase-text-slides .slick-prev:focus,
.ecomcase-text-slides .slick-next:focus {
  color: #298CFF;
}

.ecomcase-text-slides .slick-next:hover,
.ecomcase-text-slides .slick-prev:hover {
  color: #fff;
  background-color: #0238E6;
}

.ecomcase-text-slides .slick-next::before,
.ecomcase-text-slides .slick-prev::before {
  display: none;
}

.ecomcase-text-slides .slick-prev {
  bottom: 0;
  left: 60px;
  top: unset;
}

.ecomcase-text-slides .slick-next {
  bottom: 0;
  left: 0;
  top: unset;
}

.ecom-textslide-details img {
  width: 90px;
  margin-bottom: 15px
}

.ecom-textslide-details a {
  color: #000;
  display: block
}

.ecom-textslide-details h4 {
  text-transform: capitalize;
  font-size: 24px;
  color: #000
}

.ecom-textslide-details h4 svg {
  color: #298cff;
  font-size: 15px;
  margin-left: 4px;
  transition: all 0.5s ease
}

.ecom-textslide-details a:hover h4 svg {
  margin-left: 7px;
  transform: rotate3D(1, 0, 0, 180deg)
}

.ecom-img-slides .slick-slider {
  border-radius: 12px;
  overflow: hidden
}

.ecom-imgslide-image {
  border-radius: 12px;
  overflow: hidden
}

.industry-menu-scroll {
  overflow-y: auto;
  max-height: 450px;
  overflow-x: hidden;
  scrollbar-width: thin
}

.industries-menu-wrap {
  justify-content: space-between
}

.industries-big-menu .solution-menubox {
  width: 100%;
  flex-wrap: unset;
  align-items: center
}

.industries-big-menu .solution-menubox img {
  transition: all 1s ease;
  margin-right: 10px
}

.industries-big-menu .solution-menubox:hover img {
  transform: rotateY(360deg)
}

.industries-big-menu {
  width: 19%;
  flex-wrap: unset
}

.industries-big-menu .solution-menubox a {
  font-size: 16px !important;
  display: inline;
  margin: 0;
  text-transform: capitalize
}

.industries-big-menu .solution-menubox img {
  width: 22px
}

.industries-big-menu h4 {
  color: #53535391;
  font-size: 24px;
  text-transform: capitalize;
  margin-left: 10px;
  margin-bottom: 25px
}

.exclusive-btn {
  background: linear-gradient(to right, #0238E6 0%, #298CFF 80%);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 10px
}

.exclusive-btn div {
  display: flex;
  align-items: center
}

.exclusive-btn div span {
  background-color: #fff;
  border-radius: 1px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 7px 10px;
  animation: blinkLink 1.3s steps(5, start) infinite;
  background: linear-gradient(to right, #0238E6 0%, #298CFF 80%)
}

@keyframes blinkLink {
  100% {
    visibility: hidden
  }
}

.exclusive-btn div span span {
  color: #fff;
  font-weight: 900;
  padding: 0;
  animation: none;
  font-size: 12px
}

.exclusive-btn div label {
  margin: 0;
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  font-family: "Gilroy";
  cursor: pointer
}

.exclusive-btn div label p {
  margin: 0;
  display: inline-block;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  color: #fff
}

.exclusive-btn .exclusive-btn-togo {
  color: #fff;
  border-radius: 1px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 7px 10px;
  font-weight: 900;
  background: linear-gradient(to right, #0238E6 0%, #298CFF 80%)
}

.bookdemo-industry-menu {
  display: flex;
  align-items: center;
  justify-content: center
}

.industry-menu-exclusive a:hover::before {
  display: none
}

.bookdemo-industry-menu {
  border-top: 1px solid rgb(0 0 0 / 15%);
  padding: 10px 0
}

.header-menulink li .bookdemo-industry-menu a {
  font-size: 14px;
  font-weight: 800;
  font-family: "Gilroy";
  text-transform: uppercase;
  background: linear-gradient(to right, #0238E6 0%, #298cff 80%);
  background-clip: text;
  color: #fff0;
  display: block
}

.bookdemo-industry-menu a:hover:before {
  display: block
}

.coming-soon-area {
  position: relative;
  margin-bottom: 80px
}

.coming-soon-area img {
  width: 100%
}

.comingsoon-detail {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -25%);
  width: 60%
}

.comingsoon-detail h1 {
  color: #fff;
  font-size: 24px;
  font-family: "Gilroy";
  font-weight: 800;
  text-transform: capitalize
}

.comingsoon-detail .default-white {
  margin-top: 30px
}

/* loader===loading=== */

/* .loader-screen{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-screen img{
  width: 450px;
} */

/* ====education page==== */

.bespoke-education{
  padding: 80px 0;
  position: relative;
}

.bespoke-content .heading label{
  font-size: 34px;
  color: #000;
  display: block;
}

.bespoke-content .sub-heading{
  color: rgb(0 0 0 / .6);
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 30px;
}

.bespoke-education .bespoke-vec-1{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 620px;
  z-index: -1;
}

.bespoke-education .bespoke-vec-2{
  position: absolute;
  top: 0;
  right: 0;
  width: 110px;
  z-index: -1;
}

.bespoke-education .bespoke-vec-3{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 160px;
  z-index: -1;
}

/* ----------- */

.education-service-area{
  background: linear-gradient(to right, #fff 0%, #FFF9EB 50%, #fff 100%);
  padding: 40px 0;
}

.education-service-head{
  margin-bottom: 30px;
}

.educ-service-tabhead{
  position: relative;
}

.educ-service-tabhead::before{
  content: "";
  position: absolute;
  background: url(/public/assets/home/ecom-room-line.webp) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 1.5px;
  right: -15px;
  top: 0;
}

.educ-service-tabhead .nav-pills .nav-link.active, 
.educ-service-tabhead .nav-pills .show>.nav-link{
  background: transparent;
}

.educ-service-tabhead .nav-pills .nav-link.active span, 
.educ-service-tabhead .nav-pills .show>.nav-link span{
  background: linear-gradient(to right, #0035E5 0%, #298CFF 90%);
  width: 48px;
  height: 48px;
  margin-right: 27px;
}

.educ-service-tabhead .nav-pills .nav-link.active span img, 
.educ-service-tabhead .nav-pills .show>.nav-link span img{
  width: 25px;
  filter: none;
}

.educ-service-tabhead .nav-pills .nav-link.active h4, 
.educ-service-tabhead .nav-pills .show>.nav-link h4{
  background: linear-gradient(to right, #0035E5 0%, #298CFF 60%);
  color: transparent;
  background-clip: text;
}

.educ-service-tabhead span{
  display: inline-flex;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #298cff;
  transition: all 0.5s ease;
  margin-right: 30px;
}

.educ-service-tabhead a{
  display: flex;
  align-items: center;
  margin: 12px 0;
 
}

.educ-service-tabhead .nav-item{
  position: relative;
}

.educ-service-tabhead .nav-item::before{
  content: "";
  position: absolute;
  background: rgb(0 0 0 / .18);
  height: 25px;
  width: 2px;
  left: 39px;
  bottom: -12px;
}

.educ-service-tabhead .nav-item:last-child::before{
  display: none;
}

.educ-service-tabhead span img{
  filter: brightness(0) grayscale(1);
  width: 22px;
  transition: all 0.5s ease;
}

.educ-service-tabhead h4{
  color: #000;
  font-size: 24px;
  text-transform: capitalize;
  margin: 0;
}

/* ------------- */

.elearning-area{
  margin: 80px 0;
}

.elearning-head .heading span{
  display: block;
}

.elearning-content{
  margin-top: 30px;
}

.elearning-content label{
  font-size: 16px;
  font-weight: 600;
  font-family: "Gilroy";
  margin-bottom: 15px;
  text-transform: capitalize;
}

.elearning-boxes{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.elearning-boxes:last-child{
  margin-bottom: 0px;
}

.elearning-boxes img{
  width: 100px;
  margin-right: 20px;
}

.elearning-boxes div span{
  font-size: 18px;
  font-weight: 600;
  font-family: "Gilroy";
  margin-bottom: 8px;
  display: inline-block;
  transition: all 0.2s ease;
  line-height: 1.3;
  text-transform: capitalize;
}

.elearning-boxes:hover div span{
  background: linear-gradient(to right, #0238E6 0%, #298cff 70%);
  background-clip: text;
  color: transparent;
}

.elearning-boxes div p{
  font-size: 12px;
  font-family: "Gilroy";
  margin: 0;
}

/* ----------------- */

.easysteps-boxes{
  margin-top: 40px;
  border: 1px solid #298cff;
  border-radius: 10px;
  padding: 30px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / .2);
  transition: all 0.3s ease;
}

.easysteps-boxes:hover{
 transform: translateY(-10px);
}

.easysteps-boxes .easystep-numbers{
  position: absolute;
  top: 0;
  right: 0;
  width: 105px;
  transition: all 0.3s ease;
}

.easysteps-boxes:hover .easystep-numbers{
  filter: opacity(0.5);
}

.easysteps-boxes::before{
  content: "";
  position: absolute;
  background: url(/public/assets/home/easystep-box-back.webp) no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease;
}

.easysteps-boxes:hover::before{
  opacity: 1;
}

.easysteps-boxes div{
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #0035e5 0%, #298cff 100%);
  border-radius: 50%;
  transition: all 0.3s ease;
  margin-bottom: 30px;
}

.easysteps-boxes:hover div{
  background: rgb(255 255 255 / .3);
}

.easysteps-boxes div span{
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to left, #0035e5 0%, #298cff 100%);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.easysteps-boxes:hover div span{
  background: #fff;
}

.easysteps-boxes div span img{
  width: 25px;
  filter: brightness(0) invert(1);
  transition: all 0.3s ease;
}

.easysteps-boxes:hover div span img{
 filter: none;
}

.easysteps-boxes h4{
 font-weight: 600;
 font-size: 22px;
 transition: all 0.3s ease;
 text-transform: capitalize;
}

.easysteps-boxes:hover h4{
 color: #fff;
}

.easysteps-boxes p{
 margin: 0;
 transition: all 0.3s ease;
}

.easysteps-boxes:hover p{
 color: #fff;
}

/* -------- */

.great-edu-section{
  margin: 80px 0;
}

.great-edu-head{
  margin-bottom: 30px;
}

.great-edu-box{
  padding: 20px 0;
  display: flex;
  border-top: 1px solid rgb(0 0 0 / .1);
}

.great-edu-box:last-child{
  border-bottom: 1px solid rgb(0 0 0 / .1);
}

.great-edu-box span{
  display: inline-block;
  font-family: "Gilroy";
  font-weight: 600;
  color: #000;
  font-size: 24px;
  font-style: italic;
  margin-right: 20px;
  line-height: 1;
}

.great-edu-box div h4{
  font-family: "Gilroy";
  font-weight: 600;
  color: #000;
  font-size: 20px;
  text-transform: capitalize;
  transition: all 0.3s ease;
}

.great-edu-box:hover div h4{
  background: linear-gradient(to right, #0035e5 0%, #298cff 70%);
  background-clip: text;
  color: transparent;
}

.great-edu-box div p{
  margin: 0;
}

/* ---------- */

.edu-counter-content{
  position: relative;
}

.edu-counter-content img{
  width: 100%;
}

.edu-counter-content div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
}

.edu-counter-content div span span{
  display: block;
  text-align: center;
  color: #fff;
  font-family: "Gilroy";
  font-weight: 600;
  font-size: 60px;
}

.edu-counter-content div p{
  text-align: center;
  color: #fff;
  font-family: "Gilroy";
  font-weight: 600;
}

/* --------- */
.edu-industry{
  margin-top: 80px;
}


/* ========bottom right float book a demo======= */

.book-demo-floatbtn{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999999;
  width: 100px;
  cursor: pointer;
  animation: Shaker 1.5s infinite forwards;
  transition: all 0.2s ease;
}

.book-demo-floatbtn img{
  width: 100%;
  transition: all 0.3s ease;
}

.book-demo-floatbtn:hover img{
  transform: scale(1.2);
}

@keyframes Shaker {
  0%{
    transform: rotateY(-30deg);
  }
  50%{
    transform: rotateY(0deg);
  }
  100%{
    transform: rotateY(30deg);
  }
  
}

.landing-sub-head p{
  text-align: center;
}

/* only css loader */
.loader-only-css{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-only-css .loader-inner-css{
  width: 95px;
  height: 95px;
  background: linear-gradient(to right, #0238E6 0%, #298cff 30%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  animation: loadingSpinner 2s  infinite forwards;
}

.loader-only-css .loader-inner-css div{
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #fff;
}

@keyframes loadingSpinner {
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(180deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

/* telemedicine page */

.telemed-providers-area{
  position: relative;
  overflow: hidden;
  padding-top: 50px;
}

.telemed-providers-area::before{
  content: "";
  background: linear-gradient(to top, #4e9fff 0%, #fff 60%);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30%;
  height: 100%;
  z-index: -1;
  filter: blur(60px);
  opacity: 0.8;
}

.telemed-providers-area::after{
  content: "";
  background: linear-gradient(to top, #4e9fff 0%, #fff 60%);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 100%;
  z-index: -1;
  filter: blur(60px);
  opacity: 0.8;
}

.telemed-provider-head .heading label{
  display: block;
  font-size: 38px;
}

.telemed-provider-img{
  margin-top: 20px;
}

.telemed-provider-img img{
  width: 100%;
}

/* --------- */

.telemed-software-area{
  position: relative;
  overflow: hidden;
  padding: 80px 0;
}

.telemed-software-area::before{
  content: "";
  background: #ddd9ff;
  position: absolute;
  left: 0;
  top: -10px;
  width: 20%;
  height: 30%;
  z-index: -1;
  filter: blur(60px);
}

.telemed-software-content .heading{
  font-size: 40px;
}

.telemed-software-content .heading label{
  font-size: 35px;
}

.telemed-software-content p{
  margin-bottom: 0;
  margin-top: 20px;
}

/* ---------- */

.telemed-func-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0 !important;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 12%);
  padding: 0 !important;
  background: url(../public/assets/home/industry-need-headtab.png) no-repeat center;
  background-size: cover;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 15px !important;
}

.telemed-func-tab li button {
  border: 0 !important;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  color: rgb(0 0 0 / .7);
  transition: all 0.3s ease;
  position: relative;
  padding: 20px 20px !important;
}

.telemed-func-tab li button:hover {
  color: #298CFF;
}

.telemed-func-tab li button::before {
  content: "";
  background: url(../public/assets/home/industry-need-headbefore.png)no-repeat center;
  background-size: contain;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -18px;
}

.telemed-func-tab li:last-child button::before {
  opacity: 0;
}

.telemed-func-tab li button.active {
  background: linear-gradient(to right, #0238E6 0%, #298cff 90%) !important;
  background-clip: text !important;
  color: transparent !important;
}

.telemed-functab-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background: url(../public/assets/home/needtab-content-back.webp) no-repeat center;
  background-size: cover;
  border-radius: 12px;
}

.telemed-functab-content .telemed-func-mainimg {
  width: 50%;
  border-radius: 5px;
}

.telemed-functab-content div {
  width: 40%;
}

.telemed-functab-content div span {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-bottom: 30px;
  border: 5px solid #537AEE;
}

.telemed-functab-content div span img{
  width: 25px;
}

.telemed-functab-content div h4 {
  color: #fff;
  text-transform: capitalize;
}

.telemed-functab-content div p {
  color: #fff;
  margin-bottom: 30px;
  font-size: 13px;
}

/* ----------- */

.telemed-reomte-head {
  margin-bottom: 30px;
  line-height: 1.3;
}

.telemed-reomte-head label{
  font-size: 38px;
  display: block;
  font-weight: 500;
}

/* --------- */

.telemed-platfrom-box{
  margin: 40px 0 0;
  padding: 25px 20px;
  border: 1px solid #298cff;
  border-radius: 10px;
  text-align: center;
  background-color: #F0FCFF;
  transition: all 0.3s ease;
}

.telemed-platfrom-box:hover{
  transform: translateY(-10px);
  background-color: #fff;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / .15);
}

.telemed-platfrom-box img{
  width: 55px;
}

.telemed-platfrom-box p{
  margin-top: 20px;
  margin-bottom: 0;
  font-family: "Gilroy";
  min-height: 85px;
}

/* --------- */

.labtab-link.telemed-feature-link .nav-link div h3, 
.labtab-link.telemed-feature-link .nav-link div h4{
  font-weight: 600;
  transition: all 0.3s ease;
  width: 60%;
}

.labtab-link.telemed-feature-link .nav-link:hover div h3, 
.labtab-link.telemed-feature-link .nav-link:hover div h4{
  color: #298CFF;
}

.labtab-link.telemed-feature-link .nav-link:hover{
  transform: translateX(10px);
}

.labtab-link.telemed-feature-link .lab-arrow{
  transform: rotate(0deg);
}

.labtab-content.telemed-feature-content{
  justify-content: start;
  padding-left: 110px;
  background: url(/public/assets/home/telemed-feature-back.webp) no-repeat;
  background-position: center;
  background-size: cover;
}

.labtab-content.telemed-feature-content img{
  width: 55px;
  margin-bottom: 30px;
}

.labtab-content.telemed-feature-content ul{
  list-style: none;
  margin-left: 0px;
  padding-left: 20px;
  margin-top: 20px;
}

.labtab-content.telemed-feature-content ul li{
  position: relative;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 8px;
  font-size: 14px;
}

.labtab-content.telemed-feature-content ul li::before{
  content: "";
  position: absolute;
  background: url(/public/assets/home/custom-app-list-icon.png) no-repeat;
  background-size: cover;
  background-position: center;
  left: -20px;
  top: 6px;
  width: 10px;
  height: 10px;
  filter: brightness(0) invert(1);
}
/* --------- */

.fooddelivery-img-container.telemed-solution-img .report-img{
  width: 90%;
  margin: 0 auto;
  border-radius: 12px;
}

.telemed-solve-head{
  margin-bottom: 40px;
}

.telemed-solve-tabs h3{
  text-transform: capitalize;
}

/* ------------ */

.telemed-fit-area{
  margin: 80px 0;
}

.telemed-func-tab.telemed-fit-tab li button::before{
  right: -8px;
}

/* new line for trial */
.trial-in-days{
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  display: block;
  text-align: center;
  margin-top: 15px;
  color: #0035e5;
}

/*======== new youtube testimonial ========*/

.youtube-testimonial{
  position: relative;
}

.youtube-testimonial span{
  position: absolute;
  width: 110px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  top: 20px;
  left: 0;
}

.youtube-testimonial span img{
  width: 70px;
}

.yout-test-wrap{
  width: 100%;
  padding: 0 20px;
  border-radius: 15px;
  overflow: hidden;
}

.yout-test-wrap .play-butn{
  height: 50px;
  position: absolute;
  width: 50px;
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 0;
  cursor: pointer;
}

.yout-test-wrap .play-butn:before {
  animation: pulse-border 1.5s ease-out infinite;
  background: #fff;
  border-radius: 50%;
  content: "";
  display: block;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
}

@keyframes pulse-border {
  0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
  }
  100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.8);
      opacity: 0;
  }
}


.yout-test-wrap span svg{
  color:#0035E5;
  font-size: 16px;
}

.yout-test-wrap img{
  width: 100%;
  height: 330px;
  object-fit: cover;
  border-radius: 15px;
}

.testimonial-slider.youtube-slider .slick-dots{
  text-align: center;
  padding-left: 0;
}

.testimonial-slider.youtube-slider .slick-dots li button{
  background-color: #D9D9D9;
}

.inner-youtube-testimonial{
  padding: 40px 0 50px;
}

.tube-test-video-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.tube-test-popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  filter: blur(50px);
}

.tube-test-popup-content {
  position: relative;
  background: linear-gradient(to right, #0238E6 0%, #298CFF 80%);
  padding: 4px 4px 2px 4px;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
}

.tube-test-popup-content iframe {
  width: 100%;
  height: 400px;
  border-radius: 8px;
}

.tube-test-close-btn {
  position: absolute;
  top: -32px;
  right: 0px;
  background: #ff5a5a;
  border: none;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.certifications-logos{
 display: flex;
 align-items: center;
 margin-bottom: 15px;
}

.certifications-logos span{
  width: 55px;
  display: block;
  margin: 0 10px;
  cursor: pointer;
}

.certifications-logos span img{
  width: 100%;
  transition: all 0.3s;
}

.certifications-logos span:hover img{
  transform: scale(1.2);
}

.footer-companies{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footer-companies span{
  width: 90px;
  display: block;
  margin:10px 15px;
}

.footer-companies span img{
  width: 100%;
  transition: all 0.3s;
}

.footer-companies span:hover img{
  transform: scale(1.4);
}

/* ========= new landing two page ========= */

.land-2-banner{
  background: linear-gradient(to bottom, #D2E4FF, transparent);
  padding: 50px 0;
}

.land-2-banner h1{
  position: relative;
  margin-bottom: 20px;
  color: #1A1E25;
}

.land-2-banner h1::before{
  position: absolute;
  content: '';
  background: #96C0FF;
  border-radius: 10px;
  width: 50%;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -15px;
}

.land2-banner-head p{
  color: #4E545D;
  font-weight: 600;
  font-size: 15px;
}

.land2-banner-head span{
  display:block;
  align-items: center;
  background: linear-gradient(90deg, transparent, #BDD0EC 31%, #BDD0EC 70%, transparent);
  color: #000;
  font-size: 18px;
  justify-content: center;
  margin: 20px 0 30px;
  padding: 5px 0;
  text-transform: capitalize;
  font-weight: 600;
}

.land2-banner-head img{
  margin-top: 50px;
  width: 100%;
}

.land2-banner-logo{
  margin-bottom: 50px;
}

.land2-banner-logo span{
  color: #83888E;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.land2-banner-logo img{
  width: 80%; 
  margin: 20px auto 0;
}

.landing2-go{
  padding: 50px 0;
  background: #F1F7FF;
}

.land2-go-head span{
  color: #83888E;
  font-weight: 600;
  font-size: 18px;
  display: block;
  margin: 5px 0 15px;
  position: relative;
  text-transform: capitalize;
}

.land2-go-head span::before{
  position: absolute;
  content: '';
  width: 22%;
  right: 0;
  top: 50%;
  background: #D1D5DB;
  height: 2px;
  border-radius: 10px;
}

.land2-go-head p{
  color: #83888E;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 25px;
}

.land2-go-rider{
  margin-top: 30px;
}

.land2-rider-box{
  background: #fff;
  border-radius: 7px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / .1);
  align-items: center;
}

.land2-rider-box img{
  width: 45px;
  height: 45px;
}

.land2-rider-box div{
  width: 85%;
}

.land2-rider-box div h3{
  font-size: 22px;
  text-transform: capitalize;
  color: #4E545D;
}

.land2-rider-box div p{
  margin: 0;
  font-weight: 500;
}

.land2-rider-butn{
  margin-top: 40px;
  text-align: center;
}

.land2-rider-butn button{
  margin: 0 10px;
}

.land2-market{
  margin: 50px 0;
  
}

.land2-market-details{
  background: url(../public/assets/home/land2-market.webp) no-repeat center;
  background-size: cover;
  padding: 30px 70px;
  border-radius: 15px;
}

.land2-market-details label{
  background: linear-gradient(90deg, #6919ba 0, #ec9151 90%);
  color: #fff;
  font-family: Gilroy;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 3px 10px;
  text-transform: uppercase;
}

.land2-market-details .heading{
  color: #fff;
}

.land2-market-details .heading span{
  font-size: 38px;
  display: block;
}

.land2-market-details p{
  color: #fff;
  margin-bottom: 20px;
}

.land2-get-details{
  padding: 50px;
  background-color: #F1F7FF;
  border-radius: 15px;
  margin-bottom: 50px;
}

.land2-get-details span{
  color: #83888E;
  font-weight: 600;
  font-size: 18px;
  display: block;
  margin: 5px 0 15px;
  position: relative;
  text-transform: capitalize;
}
/* 
.land2-get-details span::before{
  position: absolute;
  content: '';
  width: 40%;
  right: 0;
  top: 50%;
  background: #D1D5DB;
  height: 2px;
  border-radius: 10px;
} */

.land2-get-details p{
  color: #83888E;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
}

.land2-apps-head span{
  color: #83888E;
  font-weight: 600;
  font-size: 18px;
  display: block;
  margin: 5px 0 30px;
  text-align: center;
}

.land2-app-box{
  border-radius: 7px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;
  transition: 0.3s;
  border: 1px solid #E1E8F3;
}

.land2-app-box:first-child{
  margin-top: 0px;
}

.land2-app-box:hover{
  background: #fff;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / .1);
}

.land2-app-box img{
  width: 35px;
  height: 35px;
}

.land2-app-box div{
  width: 88%;
}

.land2-app-box div h4{
  font-size: 22px;
  text-transform: capitalize;
  color: #4E545D;
}

.land2-app-box div p{
  margin: 0;
  color: #83888E;
  font-weight: 500;
}

.land2-app-image{
  width: 100%;
  height: 100%;
  background-color: #F1F7FF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.land2-app-image img{
  width: 70%;
}

.land2-apps-differ{
  margin: 50px 0;
  background-color: #F1F7FF;
  padding: 50px 0;
}

.land2-apps-differ .land2-app-image{
  background-color: #FFF;
}

.land2-choose-head label{
  color: #83888E;
  font-weight: 600;
  font-size: 18px;
  display: block;
  text-align: center;
  margin: 5px 0;
}

.land2-choose-head p{
  color: #83888E;
  font-size: 13px;
  margin-bottom: 10px;
}

.land2-choose-box{
  margin-top: 20px;
  padding: 20px;
  background-color: #F1F7FF;
  border-radius: 8px;
  transition: 0.3s;
}

.land2-choose-box:hover{
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / .2);
  transform: translateY(-5px);
}

.land2-choose-box span{
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
}

.land2-choose-box span img{
  width: 40px;
}

.land2-choose-box h4{
  font-size: 20px;
  text-transform: capitalize;
  color: #4E545D;
  font-weight: 600;
}

.land2-choose-box p{
  margin: 0;
  min-height: 43px;
  font-weight: 500;
  color: #83888E;
}

.land2-rider-butn.land2-choose-btns{
  margin-top: 30px;
  padding: 40px 0 50px;
  border-top: 1px solid #E1E8F3;
}

.land2-rider-butn.land2-choose-btns h4{
  font-size: 28px;
  color: #4E545D;
  margin-bottom: 30px;
}

.land2-works{
  padding: 30px 0;
  background-color: #F1F7FF;
}

.land2-work-head label{
  color: #83888E;
  font-weight: 600;
  font-size: 18px;
  display: block;
  margin: 5px 0 30px;
  text-align: center;
}

.land2-works-box{
  border-radius: 7px;
  padding:20px 25px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  transition: 0.3s;
  border: 1px solid #E1E8F3;
}

.land2-works-box:hover{
  background: #fff;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / .1);
}

.land2-works-box img{
  width: 35px;
  height: 35px;
}

.land2-works-box div{
  width: 85%;
}

.land2-works-box div h4{
  font-size: 22px;
  text-transform: capitalize;
  color: #4E545D;
  font-weight: 600;
}

.land2-works-box div p{
  margin: 0;
  font-weight: 500;
  color: #83888E;
}

.land2-work-video{
  margin-top: 30px;
  position: relative;
}

.land2-work-video span{
  position: absolute;
  background-color: rgb(0 0 0 / .5);
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.land2-work-video span svg{
  font-size: 30px;
  margin: 0;
  color: rgb(255 255 255 / .75);
}

.land2-work-video video{
  max-width: 100%;
  width: 100%;
  height: 420px;
  object-fit: contain;
  border-radius: 12px;
}

.land2-testimonial{
  margin: 50px 0;
}

.land2-launch{
  background: url(../public/assets/home/land2-launch-back.webp) no-repeat center;
  background-size: cover;
  padding: 30px 0;
  margin: 30px 0;
}

.land2-launch-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.land2-launch-content div{
  width: 50%;
}

.land2-launch-content div .heading{
  color: #fff;
  font-size: 34px;
}

.land2-launch-content div p{
  color: #fff;
  margin: 0;
}

.land2-launch-content span{
  display: flex;
  flex-direction: column;
}

.land2-launch-content a{
  display: block;
}

.land2-launch-content button{
  display: block;
  margin: 8px 0;
  width: 100%;
}

.land2-faq .carpool-faq{
  margin: 0;
}