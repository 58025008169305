.default-btn,
.default-white,
.sub-title {
  font-weight: 600;
  text-transform: uppercase
}

.aboutteam-box,
.carfeature-more,
.carpool-setupbox,
.center-heading,
.slick-dots,
.sub-heading {
  text-align: center
}

.slick-dots li,
.slick-dots li button {
  width: 35px !important;
  height: 7px !important
}

.carcount-box div h3,
.carcount-box div h3 small {
  text-shadow: 1px 3px 3px rgb(0 0 0 / .4);
  color: #fff
}

.labtab-link .nav-link:hover,
.software-box:hover {
  border-color: #0035e5
}

.aboutteam-box p,
.carvalue-box h3,
.carvalue-box h4,
.carwork-box span,
.labtab-link .nav-link span,
.solution-box:hover div span,
.sub-title {
  -webkit-text-fill-color: #fff0
}

.btn1,
.faq-accord .accordion-button label,
.mobsol-accord .accordion-button label {
  cursor: pointer
}

@font-face {
  font-family: Gilroy;
  src: url(../fonts/Gilroy-Regular.eot);
  src: local("Gilroy Regular"), local("Gilroy-Regular"), url(../fonts/Gilroy-Regular.woff) format("woff"), url(../fonts/Gilroy-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Gilroy;
  src: url(../fonts/Gilroy-Medium.eot);
  src: local("Gilroy Medium"), local("Gilroy-Medium"), url("../fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"), url(../fonts/Gilroy-Medium.woff2) format("woff2"), url(../fonts/Gilroy-Medium.woff) format("woff"), url(../fonts/Gilroy-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Gilroy;
  src: url(../fonts/Gilroy-Light.eot);
  src: local("Gilroy Light"), local("Gilroy-Light"), url("../fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"), url(../fonts/Gilroy-Light.woff2) format("woff2"), url(../fonts/Gilroy-Light.woff) format("woff"), url(../fonts/Gilroy-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Gilroy;
  src: url(../fonts/Gilroy-Semibold.eot);
  src: local("Gilroy Semibold"), local("Gilroy-Semibold"), url("../fonts/Gilroy-Semibold.eot?#iefix") format("embedded-opentype"), url(../fonts/Gilroy-Semibold.woff2) format("woff2"), url(../fonts/Gilroy-Semibold.woff) format("woff"), url(../fonts/Gilroy-Semibold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Gilroy-Bold;
  font-display: swap;
  src: url(../fonts/Gilroy-Bold.eot);
  src: local("Gilroy Bold"), local("Gilroy-Bold"), url("../fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"), url(../fonts/Gilroy-Bold.woff2) format("woff2"), url(../fonts/Gilroy-Bold.woff) format("woff"), url(../fonts/Gilroy-Bold.ttf) format("truetype");
  font-display: swap
}

body {
  font-family: "Open Sans", sans-serif !important;
  overflow-x: hidden;
  position: static !important;
  top: 0 !important
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Gilroy;
  font-weight: 600 !important
}

a {
  text-decoration: none !important
}

p {
  font-size: 14px;
  color: rgb(0 0 0 / 70%)
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto
}

.header-gap {
  margin-top: 83px
}

.heading {
  font-weight: 600;
  font-size: 45px;
  color: #202020;
  margin: 0;
  text-transform: capitalize;
  font-family:"Gilroy", sans-serif
}

.sub-title {
  font-size: 14px;
  letter-spacing: 2px;
  background-image: linear-gradient(to right, #0035e5 0, #298cff 80%);
  -webkit-background-clip: text;
  background-clip: text;
  color: #fff0;
  font-family: Gilroy
}

.default-btn,
.default-white {
  padding: 12px 20px 10px;
  font-family: Gilroy;
  font-size: 13px;
  letter-spacing: .6px;
  box-shadow: none;
  text-align: center;
  display: inline-block
}

.sub-heading {
  margin: 8px 0 0
}

.aboutteam-box:hover h4,
.cartechtab-point:hover h4,
.gradiant-color,
.whitemobilz-box:hover h3,
.whitemobilz-box:hover h4,
.whitetab-link .active.nav-link p {
  background-image: linear-gradient(to right, #0035e5 0, #298cff 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: #fff0;
  color: #fff0
}

.default-btn {
  transition: 0.4s;
  background-size: 200% auto;
  background-position: right center;
  color: #fff;
  border-radius: 4px;
  border: 0;
  background-image: linear-gradient(to right, #298cff 0, #0035e5 51%, #298cff 100%)
}

.default-btn:hover {
  color: #fff;
  background-position: left center
}

.default-white {
  transition: 0.3s;
  color: #0035e5;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 1px solid #0035e5
}

.header-butn,
.header-menulink li a {
  text-transform: uppercase;
  font-weight: 900;
  font-family: Gilroy;
  letter-spacing: 1px
}

.default-white:after,
.default-white:before {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  left: 0;
  top: 0;
  position: absolute;
  content: ""
}

.default-white:before {
  background: linear-gradient(to right, #0035e5 0, #298cff 80%);
  z-index: -1;
  opacity: 0
}

.default-white:after {
  background: #fff;
  z-index: -2
}

.appbox-white:hover:before,
.businesstab-link .active.nav-link:before,
.carfeature-box:hover:before,
.carsolution-model ul li:hover:before,
.carvalue-box:hover:before,
.default-white:hover:before,
.faq-accord .active.card:after,
.future-iconbox:hover:before,
.mobsol-accord .active.card:after,
.mobsol-accord .active.card:before,
.software-box:hover:before,
.taxitech-box:hover div p,
.whiteplat-box:hover:before,
.whitetab-link .active.nav-link:before {
  opacity: 1
}

.appbox-blue label,
.appbox-white:hover label,
.appbox-white:hover p,
.businesstab-link .active.nav-link p,
.carfeature-box:hover h3,
.carfeature-box:hover h4,
.carfeature-box:hover p,
.carsolution-model ul li:hover p,
.default-white:hover,
.future-iconbox:hover p,
.header-butn:hover,
.home-price .heading,
.home-price p,
.labtab-content .tab-pane p,
.software-box:hover h3,
.software-box:hover h4,
.software-box:hover p,
.software-box:hover span,
.taxi-launch .heading,
.white-insidetab p,
.whiteplat-box:hover h3,
.whiteplat-box:hover h4,
.whiteplat-box:hover p,
.whitetab-detail p {
  color: #fff
}

.footer-links li a:hover,
.footer-privacy:hover,
.footer-social li a:hover,
.header-menulink li a:hover {
  color: #298cff
}

.header-butn{
  color: #0035e5;
}

.transparent-btn {
  padding: 11px 20px 10px
}

.transparent-btn.animated-btn::after {
  z-index: 0
}

.slick-dots {
  bottom: 0;
  margin: 0;
  left: 0;
  right: 0
}

.slick-dots li {
  margin: 0 8px 0 0
}

.slick-dots li:last-child {
  margin-right: 0
}

.slick-dots li button {
  display: block;
  padding: 0 !important;
  border: 0 !important;
  border-radius: 10px;
  background: #d9d9d9;
  margin: 0 !important
}

.carsolution-model ul li span,
.default-arrowleft:hover,
.default-arrowright:hover,
.platform-box div span,
.platform-box:after,
.slick-dots li.slick-active button,
.software-box label,
.taxitech-box:after {
  background: linear-gradient(to right, #0035e5 0, #298cff 80%)
}

.header-area,
.logo:after {
  left: 0;
  right: 0;
  background: #fff
}

.faq-accord .accordion-button::after,
.header-menulink li .dropdown-item:hover::before,
.mobile-carring,
.mobsol-accord .accordion-button::after,
.slick-dots li button::before,
.whitetab-link .nav-item:last-child:after {
  display: none
}

.default-arrowleft,
.default-arrowright {
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / .1);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  cursor: pointer
}

.default-arrowleft img,
.default-arrowright img {
  transition: 0.1s;
  width: 13px
}

.logo img,
.stick .logo img {
  width: 140px
}

.businesstab-link .active.nav-link img,
.default-arrowleft:hover img,
.default-arrowright:hover img {
  filter: brightness(0) invert(1)
}

.default-arrowleft {
  transform: rotate(180deg)
}

.header-area {
  padding: 15px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
  transition: 0.4s ease-out
}

.logo {
  text-align: left;
  position: relative
}

.logo:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  z-index: 1
}

.btn1,
.btn1 .menu-line,
.carpool-head .container,
.header-butn,
.homebanner-detail {
  position: relative
}

.logo a {
  display: inline-block
}

.logo img {
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out
}

.stick {
  padding: 12px 0;
  box-shadow: 0 1px 15px rgb(0 0 0 / .2)
}

.appbox-blue,
.appbox-white,
.carvehicle-box,
.future-iconbox,
.header-menulink .dropdown-menu,
.labtab-link .nav-link:hover,
.reason-box,
.software-box,
.uspbenefit-box {
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1)
}

.header-menubar {
  display: flex;
  align-items: center;
  justify-content: end
}

.btn1 {
  display: inline-block;
  border-radius: 5px;
  width: 40px;
  padding: 10px;
  margin-left: 12px;
  background: linear-gradient(to right, #0035e5 0, #298cff 80%)
}

.header-menulink li .dropdown-item:hover,
.scroll-down-video {
  background-color: #fff
}

.btn1 .menu-line {
  transition: 0.4s cubic-bezier(0, 0, .58, 1);
  height: 1.3px;
  width: 100%;
  background: #fff;
  float: right
}

.btn1 .icon-center {
  margin-top: 6px;
  width: 80%
}

.btn1 .icon-bottom {
  margin-top: 6px
}

.aboutteam-box img,
.booking-sec img,
.btn1:hover .menu-line,
.labelinside-tablink .nav-item img,
.platform-box .img-responsive {
  width: 100%
}

.header-menulink {
  margin: 0;
  padding: 0;
  list-style: none
}

.header-menulink .dropdown-menu {
  padding: 20px 10px 0;
  top: 75px !important;
  transform: translateY(30px) !important;
  transition: 0.4s ease-out;
  opacity: 0;
  visibility: hidden;
  display: block;
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto;
  width: 1120px
}

.header-menulink .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0) !important
}

.solution-bigmenu {
  display: flex;
  justify-content: start;
  flex-wrap: wrap
}

.solution-menubox {
  display: flex;
  align-items: start;
  width: 23%;
  margin: 0 10px 22px
}

.solution-menubox img {
  margin-right: 8px;
  width: 26px;
  margin-top: 6px
}

.solution-menubox a {
  font-size: 14px !important;
  margin-bottom: 7px;
  display: inline-block
}

.solution-menubox p {
  margin: 0;
  font-size: 13px
}

.header-menulink li .nav-item {
  position: unset
}

.header-menulink li {
  margin-right: 25px;
  display: inline-block
}

.header-menulink li a {
  font-size: 13px;
  position: relative;
  color: #000
}

.header-menulink li a:before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -3px;
  transition: transform 0.5s;
  transform: scale3d(0, 1, 1);
  transform-origin: 50% 50%;
  background: #298cff
}

.footer-links li a:hover:before,
.header-menulink li a:hover::before {
  transform: scale3d(1, 1, 1)
}

.header-butn {
  display: inline-block;
  text-align: center;
  padding: 9px 15px;
  vertical-align: middle;
  border-radius: 3px;
  background: #fff;
  font-size: 12px;
  transition: 0.4s ease-out;
  z-index: 1;
  overflow: hidden;
  border: 1px solid
}

.header-butn:before {
  content: "";
  position: absolute;
  background: linear-gradient(to right, #0035e5 0, #298cff 80%);
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: 0.4s ease-out;
  z-index: -1
}

.header-butn:hover:before,
.querypop-image {
  height: 100%
}

.homebanner-detail div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 700px;
  z-index: 2
}

.homebanner-detail .img-responsive {
  width: 100%;
  height: 88vh;
  object-fit: cover
}

.homebanner-detail::after {
  content: "";
  background: rgb(0 0 0 / .4);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0
}

.homebanner-detail div .heading {
  color: #fff;
  font-size: 60px;
  text-shadow: 1px 3px 3px rgb(0 0 0 / .4)
}

.homebanner-detail div p {
  margin: 10px 0 0;
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  background: linear-gradient(to right, transparent, rgb(0 0 0 / 90%) 50%, transparent);
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center
}

.homebanner-detail div p span {
  color: #298cff;
  font-family: Gilroy;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
  margin: 0 5px
}

.homebanner-detail div .default-btn {
  margin-top: 30px
}

.scroll-down-video {
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: 3s infinite scroller_bump;
  display: flex;
  align-items: center;
  justify-content: center
}

.scroll-down-video svg {
  font-size: 20px;
  color: #0238e6
}

@keyframes scroller_bump {

  0%,
  100% {
    transform: translate(-50%, 0)
  }

  50% {
    transform: translate(-50%, -20px)
  }
}

.home-carcount {
  background: url(../public/assets/home/carcount-back.webp) bottom/cover no-repeat;
  padding: 70px 0 220px;
  position: relative
}

.home-carcount .heading {
  font-size: 30px;
  margin-bottom: 50px
}

.carcount-row,
.global-count {
  display: flex;
  justify-content: space-between
}

.carcount-box {
  width: 33%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center
}

.carcount-box img {
  width: 26px;
  margin-right: 12px;
  animation: 2s linear infinite blinkit
}

.carvector,
.carvector img {
  width: 230px
}

@keyframes blinkit {

  0%,
  100% {
    transform: scale(1)
  }

  50% {
    transform: scale(1.2)
  }
}

.carcount-box div h3 {
  line-height: .8;
  margin: 0;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center
}

.carcount-box div h3 small {
  font-size: 42px;
  font-weight: 600;
  line-height: .7
}

.carcount-box div p {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: Gilroy;
  margin: 10px 0 0
}

.carvector {
  position: absolute;
  bottom: -12px;
  left: 43%;
  transform: translateX(-50%)
}

.home-solution {
  position: relative;
  padding-top: 30px;
  margin-bottom: 80px
}

.platform-box div,
.platform-box:after,
.software-box label,
.solution-vector {
  position: absolute;
  left: 0
}

.about-app .sub-heading,
.about-journey .heading,
.about-team .heading,
.carpool-tech .center-heading,
.home-labtool .sub-heading,
.home-platform .heading,
.home-software .heading,
.home-solution .heading {
  margin-bottom: 40px
}

.feature-table,
.home-testimonial .heading,
.platform-box,
.solution-box {
  margin-bottom: 30px
}

.solution-box .img-responsive {
  border-radius: 10px 10px 0 0;
  width: 100%;
  transition: 0.2s
}

.solution-box div {
  padding: 20px;
  background: #f0fcff;
  border: 1px solid;
  border-radius: 0 0 10px 10px;
  transition: 0.3s
}

.solution-box div h3,
.solution-box div h4 {
  color: #000;
  font-size: 22px
}

.solution-box div p {
  margin-bottom: 8px;
  min-height: 63px
}

.software-box span,
.solution-box div span {
  font-family: Gilroy;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.1px;
  color: rgb(0 0 0 / 60%);
  display: inline-flex;
  align-items: center;
  line-height: 1;
  transition: 0.2s
}

.solution-box div span img {
  width: 11px;
  transition: 0.1s;
  margin-left: 4px;
  opacity: .6;
  filter: brightness(0) saturate(100%)
}

.solution-box:hover div {
  background: #fff;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / .2)
}

.solution-box:hover div span {
  background-image: linear-gradient(to right, #0035e5 0, #298cff 80%);
  -webkit-background-clip: text;
  color: #fff0
}

.solution-box:hover div span img {
  opacity: 1;
  filter: none
}

.solution-box div.solution-box-img {
  overflow: hidden;
  padding: 0;
  background: #f0fcff;
  border: 0;
  border-radius: 10px 10px 0 0
}

.solution-box:hover .solution-box-img img {
  transform: scale(1.1)
}

.solution-vector {
  top: 0;
  right: 0;
  width: 100%;
  z-index: -1
}

.platform-box,
.software-box {
  position: relative;
  border-radius: 15px;
  overflow: hidden
}

.platform-box:after {
  content: "";
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  opacity: 0
}

.platform-box div {
  bottom: 0;
  z-index: 1;
  padding: 0 15px 15px;
  right: 0;
  width: 100%
}

.platform-box div h3,
.platform-box div h4 {
  color: #fff;
  font-size: 28px;
  transition: 0.5s;
  transform: translateY(100px)
}

.platform-box div p {
  color: #fff;
  opacity: 0;
  transition: 0.5s;
  transform: translateY(100px);
  min-height: 75px
}

.platform-box div span {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right
}

.global-countbox div,
.labtab-link .nav-link div,
.software-box label,
.testi-content div {
  display: flex;
  align-items: center
}

.platform-box div span img {
  width: 13px;
  filter: brightness(0) invert(1)
}

.carfeature-box:hover div,
.platform-box:hover div span {
  transform: rotate(-45deg);
  background: #fff
}

.carfeature-box:hover div img,
.carfeature-box:hover span img,
.carsolution-model ul li:hover span img,
.carvalue-box:hover span img,
.future-iconbox:hover span img,
.platform-box:hover div span img,
.whiteplat-box:hover span img {
  filter: none
}

.platform-box:hover:after,
.taxitech-box:hover:after {
  opacity: .8
}

.platform-box:hover div h3,
.platform-box:hover div h4 {
  transform: translateY(0)
}

.platform-box:hover div p {
  opacity: 1;
  transform: translateY(0)
}

.home-price {
  background: url(../public/assets/home/price-back.webp) 0 0 / cover no-repeat;
  padding: 60px 0;
  text-align: center
}

.home-price h3,
.home-price h5 {
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1.5px;
  margin: 15px 0 12px
}

.about-future,
.about-team,
.carpool-feature,
.carpool-vehicle,
.home-global,
.home-labtool,
.home-testimonial,
.taxi-white,
.whitelabl-mobilize {
  margin: 80px 0
}

.home-global .heading {
  margin-bottom: 8px
}

.home-global .text-center {
  margin-bottom: 50px
}

.global-haed h3,
.global-haed h4 {
  font-size: 30px;
  color: #000;
  margin-bottom: 3px;
  text-transform: uppercase
}

.global-countbox {
  width: 32%
}

.global-countbox div h4 {
  font-size: 42px;
  line-height: 1;
  margin: 0
}

.global-countbox div small {
  font-size: 35px;
  font-weight: 600;
  line-height: .5
}

.global-countbox p {
  margin: 3px 0 0;
  font-size: 20px;
  font-weight: 700
}

.map-vector {
  margin: 12px auto 0;
  width: 95%
}

.software-box {
  background: url(../public/assets/home/wave-pattern.webp) 0 0 / cover no-repeat;
  padding: 25px 25px 25px 70px;
  border: 1px solid;
  margin-bottom: 30px;
  transition: 0.4s ease-in-out
}

.businesstab-content,
.businesstab-link {
  box-shadow: 0 2px 16px 4px rgb(40 44 63 / .05)
}

.carfeature-box:before,
.carvalue-box:before,
.software-box:before,
.whiteplat-box:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0, #298cff 80%);
  transition: 0.4s ease-in-out;
  opacity: 0
}

.software-box h3,
.software-box h4 {
  color: #000;
  font-size: 28px;
  margin-bottom: 7px;
  transition: 0.2s
}

.software-box p {
  margin-bottom: 8px;
  transition: 0.2s
}

.software-box span img {
  width: 11px;
  margin-left: 4px;
  opacity: .6;
  transition: 0.1s;
  filter: brightness(0) saturate(100%)
}

.software-box label {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  font-family: Gilroy;
  width: 50px;
  height: 50px;
  justify-content: center;
  border-radius: 15px 0;
  transition: 0.4s ease-in-out;
  top: 0
}

.labtab-content,
.labtab-link .nav-link {
  overflow: hidden;
  align-items: center;
  display: flex
}

.software-box:hover span img {
  filter: brightness(0) invert(1);
  opacity: 1
}

.software-box:hover label {
  background: #fff;
  color: #0035e5
}

.software-fixed {
  position: sticky;
  top: 88px
}

.carwork-box-inner div,
.software-fixed img {
  width: 80%
}

.carwork-box,
.labtab-link .nav-item {
  margin-top: 20px
}

.lab-arrow {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 20px;
  transition: 0.2s;
  transform: rotate(45deg)
}

.labtab-link .nav-link {
  background: #fff;
  border-radius: 15px;
  border: 1px solid #fff;
  padding: 20px;
  justify-content: space-between;
  transition: 0.2s;
  position: relative;
  z-index: 1
}

.labtab-link .nav-link::before,
.testi-bigphoto:after,
.testi-bigphoto:before {
  position: absolute;
  z-index: -1;
  content: ""
}

.labtab-link .nav-link::before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right, #dfe7ff 0, #ffe9da 100%);
  transition: opacity 0.3s
}

.businesstab-link .nav-link:before,
.feature-table table .table-HEAD td:nth-child(2),
.labtab-content,
.testi-bigphoto:after,
.testi-bigphoto:before {
  background: linear-gradient(to right, #0035e5 0, #298cff 90%)
}

.appbox-blue:hover:before,
.future-iconbox:hover span:before,
.labtab-link .active.nav-link::before,
.labtab-link .nav-link:hover::before {
  opacity: 0
}

.labtab-link .nav-link.active {
  border-color: #0035e5;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .2)
}

.labtab-link .nav-link.active .lab-arrow {
  transform: none
}

.labtab-link .nav-link div h3,
.labtab-link .nav-link div h4 {
  color: #000;
  font-size: 18px;
  font-weight: 500 !important;
  text-transform: uppercase;
  margin: 0;
  line-height: 1.3
}

.labtab-link .nav-link div img {
  width: 42px;
  margin-right: 8px
}

.labtab-link .nav-link span {
  background-image: linear-gradient(to bottom, #d3bdbd 0, rgb(211 189 189 / 5%) 85%);
  -webkit-background-clip: text;
  background-clip: text;
  color: #fff0;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 80px;
  line-height: 1
}

.labtab-content {
  padding: 48px 30px 50px 70px;
  border-radius: 8px;
  margin-left: -55px;
  position: sticky;
  top: 110px;
  justify-content: center;
  height: 68vh
}

.cartechtab-content .tab-pane,
.labtab-content .tab-pane {
  -webkit-animation: 1s fadeInUp;
  animation: 1s fadeInUp;
  transition: 2s cubic-bezier(.165, .84, .44, 1)
}

.labtab-content .tab-pane h4 {
  color: #fff;
  font-size: 30px
}

.labtab-content .tab-pane a,
.labtab-content .tab-pane label {
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-family: Gilroy
}

.labtab-content .tab-pane label {
  letter-spacing: 2px;
  margin: 0 0 15px
}

.labtab-content .tab-pane a {
  letter-spacing: 1.2px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  transition: 0.2s
}

.labtab-content .tab-pane a img {
  width: 6px;
  transition: 0.2s;
  margin-left: 4px
}

.labtab-content .tab-pane a:hover img {
  transform: translateX(2px)
}

.labtab-iconbox {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-top: 30px
}

.labtab-iconbox div {
  width: 24%
}

.labtab-iconbox div img {
  width: 38px
}

.labtab-iconbox div span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .2px;
  text-transform: uppercase;
  font-family: Gilroy;
  display: block;
  margin-top: 8px;
  line-height: 1.4
}

.testimonial-slider {
  padding-bottom: 10px
}

.testimonial-slider .slick-dots {
  text-align: left;
  padding-left: 25px
}

.testimonial-box {
  padding: 0 15px
}

.testi-bigphoto {
  position: relative;
  padding: 12px;
  margin-right: 20px
}

.testi-bigphoto:before {
  left: 0;
  top: 0;
  width: 120px;
  height: 108px
}

.testi-bigphoto:after {
  right: 0;
  bottom: 0;
  width: 120px;
  height: 108px
}

.appbox-blue,
.appbox-white,
.businesstab-link .nav-link,
.future-iconbox span {
  position: relative;
  z-index: 1;
  overflow: hidden
}

.testi-content img {
  width: 40px;
  margin: 12px 0
}

.testi-content p {
  font-weight: 500;
  font-family: Gilroy;
  font-size: 15px;
  line-height: 1.8
}

.testi-content div img {
  width: 55px;
  height: 55px;
  margin: 0 10px 0 0;
  border-radius: 50%
}

.testi-content div span {
  margin: 0;
  font-size: 14px
}

.testi-content div .gradiant-color {
  display: block;
  font-weight: 600
}

.carpool-faq,
.home-newsarea {
  margin-bottom: 80px
}

.about-client .heading,
.carpool-work .center-heading,
.home-newsarea .heading {
  margin-bottom: 20px
}

.newslogo-box {
  margin: 10px 0
}

.newslogo-box a {
  cursor: auto
}

.newslogo-box img {
  margin: 0 auto;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 2px 16px 4px rgb(40 44 63 / .06);
  border: 1px solid
}

.businesstab-content,
.businesstab-link,
.businesstab-link .nav-link {
  border-radius: 12px;
  background: #fff
}

.home-teamlogo {
  margin: 80px 0 50px
}

.home-teamlogo .heading {
  font-size: 24px;
  margin-bottom: 20px
}

.teamlogo-box div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px
}

.about-client,
.about-reason {
  margin: 60px 0 80px
}

.teamlogo-box div img {
  width: 110px;
  margin: 0 auto;
  display: block;
  max-width: 100%;
  height: auto
}

.home-businesstab {
  background: linear-gradient(to bottom, transparent, rgb(224 220 217 / 90%) 50%, transparent);
  padding: 80px 0
}

.home-businesstab .sub-heading {
  margin: 4px auto 10px;
  width: 55%
}

.businesstab-link {
  flex-wrap: unset !important;
  justify-content: space-between;
  width: 100%;
  margin-top: 22px
}

.businesstab-link .nav-item {
  width: 16%
}

.businesstab-link .nav-link {
  padding: 15px;
  transition: 0.2s;
  text-align: center;
  height: 100%
}

.businesstab-link .nav-link:before,
.whitetab-link .nav-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: 0.3s ease-in-out;
  opacity: 0
}

.businesstab-link .nav-link img {
  width: 46px;
  transition: 0.2s
}

.businesstab-link .nav-link p {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin: 8px 0 0;
  transition: 0.2s
}

.businesstab-link .nav-link:hover {
  background: linear-gradient(to right, #d3ddfb 0, #fddfcb 90%)
}

.businesstab-content {
  margin-top: 25px;
  padding: 40px
}

.busstab-detail img {
  width: 200px;
  margin-bottom: 10px
}

.busstab-detail h3,
.busstab-detail h4 {
  font-size: 40px;
  color: #000;
  margin-bottom: 8px;
  text-transform: capitalize;
  line-height: 1.1
}

.busstab-image img {
  box-shadow: 0 2px 16px 4px rgb(40 44 63 / .08)
}

.footer-logo img {
  width: 200px
}

.footer-logo p {
  color: #000;
  margin: 12px 0 0;
  font-weight: 500
}

.footer-app {
  margin-top: 15px;
  display: flex;
  justify-content: space-between
}

.footer-app,
.footer-logo {
  padding-right: 30px
}

.footer-app div:first-child {
  width: 30%;
  display: flex;
  justify-content: space-between;
  flex-direction: column
}

.footer-app div:first-child img {
  width: 110px;
  transition: 0.2s
}

.app-butn:hover img,
.footer-app div:first-child a:hover img {
  transform: scale(1.04)
}

.footer-app div:nth-child(2) {
  background: #e4faff;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1.4px solid;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 53%
}

.footer-app div:nth-child(2) span {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  margin-right: 10px
}

.footer-app div:nth-child(2) span .fa-angle-right {
  color: #ffa800;
  font-size: 13px
}

.footer-app div:nth-child(2) img {
  width: 55px
}

.footer-links h2,
.footer-links h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #000;
  letter-spacing: 1.1px;
  text-transform: uppercase
}

.footer-links ul {
  margin: 0 0 20px;
  padding: 0;
  list-style: none
}

.footer-links li {
  margin-bottom: 17px;
  line-height: 1
}

.cartechtab-link .nav-item:last-child,
.cartechtab-point:last-child,
.feature-table table,
.feature-table table tr td ul:last-child,
.footer-country,
.footer-links li:last-child,
.labelinside-tablink .nav-item:last-child,
.mobsol-accord .card:last-child,
.whitetab-detail ul li:last-child {
  margin-bottom: 0
}

.footer-links li a {
  color: #000;
  font-size: 15px;
  position: relative;
  font-weight: 500;
  font-family: Gilroy
}

.footer-links li a:before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -3px;
  transition: transform 0.5s;
  transform: scale3d(0, 1, 1);
  transform-origin: 0 50%;
  transform-origin: 50% 50%;
  background: #298cff
}

.appbox-blue:before,
.appbox-white:before,
.carsolution-model ul li:before,
.future-iconbox span:before,
.future-iconbox:before {
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right, #0035e5 0, #298cff 80%);
  transition: 0.3s ease-in-out;
  left: 0;
  right: 0;
  top: 0;
  content: ""
}

.footer-copyright {
  border-top: 1px solid #d9d9d9;
  align-items: center;
  padding: 12px 0;
  margin-top: 40px !important
}

.footer-country,
.footer-privacy {
  font-size: 15px;
  color: #000;
  text-transform: uppercase;
  font-family: Gilroy;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1
}

.text-right {
  text-align: right
}

.footer-social {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center
}

.footer-social li {
  margin: 0 17px
}

.footer-social li a {
  color: #2e2e2e;
  font-size: 15px
}

.about-future .heading,
.about-reason .heading,
.labelinside-tablink .nav-item,
.taxi-disbanner .heading {
  margin-bottom: 10px
}

.future-iconbox {
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid;
  padding: 30px;
  position: relative;
  margin-top: 30px
}

.appbox-white:before,
.carsolution-model ul li:before,
.future-iconbox:before {
  position: absolute;
  opacity: 0
}

.future-iconbox h4,
.future-iconbox h3 {
  text-transform: uppercase;
  font-size: 20px;
  transition: 0.2s;
  margin: 12px 0 6px
}

.future-iconbox:hover h4,
.future-iconbox:hover h3{
  color: #fff;
}

.future-iconbox p {
  transition: 0.2s;
  margin: 0
}

.future-iconbox span {
  width: 45px;
  height: 45px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px
}

.future-iconbox span img {
  width: 26px;
  transition: 0.3s ease-in-out;
  filter: brightness(0) invert(1)
}

.appbox-blue:before,
.future-iconbox span:before {
  position: absolute;
  opacity: 1
}

.about-empower {
  background: url(../public/assets/home/empowered-back.webp) 0 0 / cover no-repeat;
  padding: 50px 0 70px;
  margin: 80px 0
}

.about-empower .heading {
  color: #fff;
  margin-bottom: 40px
}

.empower-box {
  background: rgb(75 75 75 / 30%);
  border: 1px solid;
  backdrop-filter: blur(3px);
  border-radius: 12px;
  padding: 30px;
  text-align: center
}

.empower-box img {
  width: 44px;
  margin-bottom: 10px
}

.empower-box h4 {
  font-size: 42px;
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1
}

.empower-box p {
  color: #fff;
  margin: 10px 0 0
}

.reason-box {
  padding: 50px 30px 30px;
  background: #f0fcff;
  border: 1px solid;
  transition: 0.3s;
  border-radius: 10px;
  text-align: center;
  margin-top: 60px;
  position: relative
}

.reason-box img {
  width: 65px;
  position: absolute;
  top: -33px;
  left: 0;
  right: 0;
  margin: 0 auto
}

.reason-box h3,
.reason-box h4 {
  font-size: 22px
}

.reason-box p {
  margin: 0;
  min-height: 70px
}

.reason-box:hover {
  background: #fff;
  border-color: #0035e5
}

.aboutteam-box h4 {
  font-size: 20px;
  margin: 14px 0 4px;
  transition: 0.2s
}

.aboutteam-box p {
  margin: 0;
  transition: 0.2s;
  background-image: linear-gradient(to right, #0035e5 0, #298cff 80%);
  -webkit-background-clip: text;
  background-clip: text;
  color: #fff0;
  display: inline-block
}

.aboutteam-box:hover p {
  -webkit-text-fill-color: #000
}

.about-app {
  position: relative;
  padding-bottom: 40px
}

.appbox-left {
  display: flex;
  justify-content: end;
  margin-bottom: 60px
}

.appbox-blue,
.appbox-white {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #0035e5;
  background: #fff;
  transition: 0.4s ease-in-out;
  width: 65%;
  text-align: center;
  animation: 3s infinite bounce-3
}

.about-carring,
.carring-blur {
  left: 0;
  bottom: 0;
  z-index: -1;
  position: absolute;
  right: 0
}

@-webkit-keyframes bounce-3 {

  0%,
  100% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(-5px)
  }
}

@keyframes bounce-3 {

  0%,
  100% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(-5px)
  }
}

.appbox-blue label,
.appbox-white label {
  font-family: Gilroy;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 2px;
  transition: 0.2s;
  line-height: 1.3
}

.appbox-blue p,
.appbox-white p {
  margin: 0;
  color: #000;
  transition: 0.2s
}

.appbox-blue p{
  color: #fff;
}

.appbox-blue:hover label,
.appbox-blue:hover p,
.cartechtab-point:hover p,
.whitemobilz-box:hover p {
  color: #000
}

.appbox-right {
  display: flex;
  justify-content: end;
  margin-top: 60px
}

.appbox-image .img-responsive {
  width: 88%;
  margin: 0 auto
}

.about-carring,
.taxi-solut .moblimage-item img {
  width: 70%;
  margin: 0 auto
}

.carring-blur {
  background: radial-gradient(#0035e5 40%, #298cff 0);
  margin: 0 auto;
  width: 450px;
  height: 450px;
  filter: blur(100px);
  border-radius: 50%;
  opacity: .2
}

.about-journey {
  overflow: hidden;
  padding-bottom: 15px
}

.journey-slider {
  margin-right: -250px;
  padding-right: 180px
}

.journey-left {
  padding-bottom: 30px
}

.journey-right {
  display: flex;
  justify-content: end;
  padding-top: 30px;
  position: relative
}

.journey-right:before {
  position: absolute;
  content: "";
  background: #000;
  width: 100%;
  height: 1.4px;
  left: 25%;
  top: 0
}

.journey-left div,
.journey-right div {
  background: linear-gradient(to right, #dfe7ff 0, #ffe9da 100%);
  padding: 20px;
  border-radius: 12px;
  width: 50%;
  position: relative;
  min-height: 133px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column
}

.journey-left h4,
.journey-right h4 {
  font-size: 30px;
  color: #000;
  margin-bottom: 5px;
  line-height: 1
}

.journey-left p,
.journey-right p {
  color: #000;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 1.4
}

.journey-left div span {
  bottom: -42px
}

.journey-right div span {
  top: -42px
}

.journey-left div span,
.journey-right div span {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  width: 25px;
  height: 25px
}

.journey-left div span:before,
.journey-right div span:before {
  position: absolute;
  content: "";
  background: #ddebff;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  left: 0;
  top: 0
}

.journey-left div span:after,
.journey-right div span:after {
  position: absolute;
  content: "";
  background: #63a3ff;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}

.custom-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px
}

.custom-arrows .default-arrowleft,
.custom-arrows .default-arrowright {
  margin: 0 15px
}

.taxi-white .heading {
  font-size: 60px
}

.taxi-white p {
  font-family: Gilroy;
  font-size: 34px;
  margin: 15px 0 0;
  color: #000;
  text-align: center;
  line-height: 1.3
}

.taxi-white p span:first-child {
  color: #0238e6
}

.taxi-white p span:nth-child(2) {
  color: #23fd46
}

.taxi-white p span:nth-child(3) {
  color: #ffd029
}

.taxi-white p span:nth-child(4) {
  color: #ff2f2f
}

.taxi-white p span:nth-child(5) {
  color: #55b8ff
}

.uspbenefit-row {
  position: relative;
  margin-top: 50px
}

.uspbenefit-box {
  border-radius: 12px;
  padding: 80px 200px 80px 60px;
  width: 65%
}

.uspbenefit-box img {
  width: 60px
}

.uspbenefit-box h3,
.uspbenefit-box h4 {
  font-size: 30px;
  margin: 20px 0 10px
}

.uspbenefit-box p {
  margin: 0;
}

.uspbenefit-image {
  position: absolute;
  top: 20px;
  right: 0;
  width: 48%
}

.uspbenefit-reverse {
  display: flex;
  justify-content: end
}

.uspbenefit-reverse .uspbenefit-box {
  padding: 80px 60px 80px 200px
}

.uspbenefit-reverse .uspbenefit-image {
  right: auto;
  left: 0
}

.taxi-launch {
  background: url(../public/assets/home/taxilaunch-back.webp) 0 0 / cover no-repeat;
  padding: 80px 0;
  text-align: center;
  position: relative
}

.taxi-launch p {
  color: #fff;
  margin: 20px 0 22px
}

.taxilaunch-car-1 {
  position: absolute;
  bottom: -1px;
  left: 9%
}

.taxilaunch-car-1 img {
  width: 210px
}

.taxilaunch-car-2 {
  position: absolute;
  bottom: 0;
  right: 8%
}

.taxilaunch-car-2 img {
  width: 250px
}

.taxitech-box {
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 15px
}

.taxitech-box:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 0.4s ease-in-out;
  opacity: 0
}

.taxitech-box div {
  position: absolute;
  text-align: center;
  padding: 0 50px;
  z-index: 1;
  top: 96%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  transition: 0.6s
}

.taxitech-box div h3,
.taxitech-box div h4 {
  color: #fff;
  font-size: 32px
}

.taxitech-box div p {
  transition: 0.6s;
  opacity: 0;
  color: #fff;
  margin: 0
}

.taxitech-box:hover div {
  top: 50%
}

.carpool-head,
.carvehicle-box {
  background: #f0fcff
}

.carpool-head .row {
  padding: 130px 0
}

.carpool-head .row img {
  width: 70%;
  margin-top: 30px
}

.carpool-head h1,
.carpool-head label {
  color: #000;
  font-size: 32px;
  text-transform: capitalize;
  display: block;
  margin-bottom: 5px;
  line-height: 1.3
}

.carpool-head .heading {
  line-height: 1.3
}

.carpool-head p {
  color: #000;
  margin: 10px 0 15px;
  font-size: 15px;
}

.carpool-head small {
  color: #fff;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
  background: linear-gradient(to right, rgb(0 0 0 / 90%) 50%, transparent);
  padding: 5px 10px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.carpool-head .default-btn {
  margin-right: 20px
}

.carhead-image {
  position: absolute;
  bottom: 0;
  right: 15px;
  width: 41%
}

.carlaunch-box label {
  text-transform: uppercase;
  font-size: 14px;
  color: rgb(0 0 0 / 70%);
  display: block;
  margin-bottom: 3px
}

.carlaunch-box .heading {
  font-size: 38px;
  margin-bottom: 15px
}

.carlaunch-box p {
  margin-bottom: 35px
}

.carpool-custom {
  padding-top: 50px;
  background: linear-gradient(to bottom, #ffe9da 0, #dfe7ff 90%);
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 80px
}

.carpool-custom p {
  color: #000;
  margin: 20px 0
}

.carpool-custom .img-responsive {
  margin-top: 10px
}

.carcustom-vector {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 70%
}

.carpool-setupbox p {
  color: #000;
  margin: 15px auto 20px;
  width: 56%
}

.carpool-start {
  background: url(../public/assets/home/carstart-back.webp) center/cover no-repeat;
  padding: 50px 0;
  margin: 80px 0;
  text-align: center
}

.carsolution-model,
.carvehicle-box {
  border-radius: 15px;
  padding: 30px;
  text-align: center
}

.carpool-start .heading {
  color: #fff;
  margin-bottom: 20px
}

.carsolution-vehicle {
  display: flex;
  align-items: center;
  margin-top: 40px
}

.carvehicle-box h3,
.carvehicle-box h4 {
  color: #000;
  text-transform: capitalize;
  margin-bottom: 4px;
  font-size: 1.5rem
}

.carvehicle-box ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 65%
}

.carvehicle-box ul li {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 12px 0 rgb(0 0 0 / .1);
  transition: 0.2s;
  margin-top: 28px
}

.carvehicle-box ul li img {
  transition: 0.2s;
  width: 30px
}

.carvehicle-arrow {
  width: 30px;
  margin-left: 25px
}

.carsolution-model {
  background: linear-gradient(to bottom, #ffe9da 0, #dfe7ff 90%);
  box-shadow: inset 0 2px 14px 0 rgb(0 0 0 / .1);
  margin-top: 40px
}

.carfeature-box,
.carvalue-box {
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  transition: 0.4s ease-in-out;
  z-index: 0;
}

.carsolution-model ul li,
.carvalue-box {
  position: relative;
  margin-top: 30px;
  background: #fff;
  overflow: hidden
}

.carsolution-model h3,
.carsolution-model h4 {
  color: #000;
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 1.5rem
}

.carsolution-model ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap
}

.carsolution-model ul li {
  border-radius: 10px;
  border: 1px solid;
  padding: 15px 20px;
  z-index: 1;
  text-align: center;
  width: 30%
}

.carsolution-model ul li p {
  color: #000;
  transition: 0.2s;
  font-size: 14px;
  font-weight: 500;
  margin: 15px 0 0
}

.carsolution-model ul li span {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s;
  margin: 0 auto
}

.carsolution-model ul li span img {
  transition: 0.2s;
  width: 24px;
  filter: brightness(0) invert(1)
}

.carsolution-model ul li:hover span,
.mobsol-accord .active.card .accordion-button span,
.whiteplat-box:hover span {
  background: #fff
}

.carpool-value .sub-heading {
  margin: 8px 0 10px
}

.carvalue-box {
  padding: 22px;
  border-radius: 15px;
  border: 1px solid;
  min-height: 235px
}

.carfeature-box span,
.carvalue-box span {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.2s ease-in-out;
  border: 1px solid #000;
  background: #fff
}

.carfeature-box span img,
.carvalue-box span img {
  filter: brightness(0) saturate(100%);
  transition: 0.4s ease-in-out;
  width: 28px
}

.carvalue-box h3,
.carvalue-box h4 {
  font-size: 22px;
  margin: 12px 0;
  transition: 0.2s;
  background-image: linear-gradient(to right, #0035e5 0, #298cff 80%);
  -webkit-background-clip: text;
  background-clip: text;
  color: #fff0
}

.carvalue-box p,
.faq-accord .accordion-body p {
  margin: 0
}

.carvalue-image {
  position: absolute;
  top: -18px;
  right: -25px;
  width: 120px
}

.carfeature-box:hover,
.carvalue-box:hover {
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .5)
}

.carfeature-box:hover span,
.carvalue-box:hover span {
  border-color: #fff
}

.carvalue-box:hover h3,
.carvalue-box:hover h4,
.carvalue-box:hover p {
  color: #fff;
  -webkit-text-fill-color: #fff
}

.carpool-feature .sub-heading {
  margin: 8px 0 40px
}

.carfeature-box {
  padding: 17px;
  position: relative;
  border-radius: 15px;
  margin-bottom: 30px;
  overflow: hidden;
  border: 1px solid;
  background: #f0fcff
}

.carfeature-box h3,
.carfeature-box h4 {
  font-size: 22px;
  margin: 12px 0;
  transition: 0.2s;
  color: #000
}

.carfeature-box p {
  margin-bottom: 5px;
  min-height: 84px
}

.carfeature-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 110px;
  opacity: .8
}

.carfeature-box div {
  background: linear-gradient(to right, #0035e5 0, #298cff 80%);
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right
}

.carfeature-box div img {
  width: 12px;
  filter: brightness(0) invert(1)
}

.carfeature-more a {
  margin: 0 10px
}

.carpool-work {
  padding: 80px 0;
  position: relative
}

.carwork-box label {
  font-size: 22px;
  font-weight: 600;
  font-family: "Gilroy", sans-serif;
  display: block;
  line-height: 1.2
}

.carwork-box p {
  margin: 3px 0 0
}

.carwork-box span {
  background-image: linear-gradient(to bottom, #298cff 0, rgb(41 140 255 / 5%) 80%);
  -webkit-background-clip: text;
  background-clip: text;
  color: #fff0;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 60px;
  line-height: 1;
  margin-right: 15px
}

.carwork-box img {
  width: 82%;
  margin: 0 auto;
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / .1);
  border-radius: 50%;
  animation: 3s infinite bounce-3
}

.carwork-box-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px
}

.carwork-vector-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 560px;
  animation: 90s linear infinite CarRotate;
  transform-origin: 0% 0%
}

@keyframes CarRotate {
  0% {
    -webkit-transform: rotate(0) translate(-50%, -50%);
    -moz-transform: rotate(0) translate(-50%, -50%);
    -o-transform: rotate(0) translate(-50%, -50%);
    transform: rotate(0) translate(-50%, -50%)
  }

  100% {
    -webkit-transform: rotate(1turn) translate(-50%, -50%);
    -moz-transform: rotate(1turn) translate(-50%, -50%);
    -o-transform: rotate(1turn) translate(-50%, -50%);
    transform: rotate(1turn) translate(-50%, -50%)
  }
}

.carwork-vector-2 {
  position: absolute;
  transform: translateX(-50%);
  top: 0;
  left: 50%;
  z-index: -1;
  width: 310px
}

.carwork-vector-3 {
  position: absolute;
  top: 230px;
  left: 0;
  z-index: -1;
  width: 150px
}

.carwork-vector-4 {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: -1;
  width: 200px
}

.faq-accord {
  margin-top: 40px
}

.faq-accord .card {
  border: 1px solid #0035e5;
  margin-bottom: 2px;
  border-radius: 15px;
  z-index: 1
}

.faq-accord .card:before {
  position: absolute;
  content: "";
  border: 3px solid #fff;
  border-top: 0;
  width: 100.3%;
  height: 80%;
  border-radius: 0 0 15px 15px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: 1
}

.cartech-dotvector,
.faq-accord .card:after {
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

.faq-accord .card:after {
  position: absolute;
  content: "";
  border-radius: 15px;
  z-index: -1;
  background: linear-gradient(to bottom, #ebf0fd 0, #fff 90%);
  transition: 0.4s ease-in-out;
  opacity: 0
}

.cartechtab-point span,
.mobsol-accord .accordion-button span,
.mobsol-accord .card:after,
.whiteplat-box span {
  background: linear-gradient(to right, #0035e5 0, #298cff 80%)
}

.faq-accord .card-header {
  padding: 0 15px;
  background-color: #fff0;
  border: 0
}

.faq-accord .accordion-item,
.mobsol-accord .accordion-item {
  background: 0 0;
  border: 0
}

.faq-accord .accordion-body {
  padding: 0 0 15px 27px
}

.faq-accord .accordion-button {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 18px 0;
  color: #000 !important;
  font-size: 16px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  justify-content: space-between;
  z-index: 1
}

.faq-accord .accordion-button span {
  font-size: 20px;
  line-height: 1;
  margin-left: 12px
}

.cartechtab-link .nav-item {
  margin-top: 0;
  margin-bottom: 40px
}

.cartechtab-content {
  background: linear-gradient(to right, #0035e5 0, #298cff 90%);
  padding: 20px 20px 20px 60px;
  border-radius: 8px;
  margin-left: -55px;
  position: relative;
  overflow: hidden;
  z-index: 0
}

.cartech-dotvector {
  mix-blend-mode: color-burn;
  position: absolute;
  object-fit: cover;
  z-index: -1
}

.cartechtab-head {
  text-align: center;
  margin-bottom: 20px
}

.cartechtab-head h4 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500 !important;
  color: #fff;
  margin-bottom: 4px
}

.cartechtab-head p {
  color: #fff;
  margin: 0 auto;
  width: 80%
}

.cartechtab-point {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .2);
  padding: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 20px
}

.cartechtab-point span,
.whiteplat-box span {
  align-items: center
}

.cartechtab-point div {
  width: 82%
}

.cartechtab-point h4 {
  font-size: 18px;
  margin-bottom: 4px;
  transition: 0.2s;
  text-transform: capitalize
}

.cartechtab-point p {
  margin-bottom: 0;
  transition: 0.2s;
  font-size: 13.2px
}

.cartechtab-point span {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s;
  margin-right: 8px
}

.cartechtab-point span img {
  width: 22px
}

.cartechtab-image {
  margin: 0 auto;
  width: 80%
}

.cartechtab-image-2 {
  width: 97%;
  right: -31px;
  position: relative
}

.cartechtab-btn {
  text-align: center;
  margin-top: 20px !important
}

.cartechtab-btn .default-white {
  border-color: #fff;
  color: #fff
}

.whitelabl-head {
  padding-top: 20px;
  position: relative
}

.whitehead-detail label {
  color: #000;
  font-size: 32px;
  text-transform: capitalize;
  display: block;
  margin-bottom: 0;
  line-height: 1.3
}

.whitehead-detail small {
  color: #fff;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
  background: linear-gradient(to right, rgb(0 0 0 / 90%) 50%, transparent);
  padding: 5px 10px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.whitehead-detail span {
  color: #000;
  margin: 3px 0 8px;
  font-size: 17px;
  display: block
}

.whitehead-image {
  width: 90%;
  margin: 0 auto
}

.whitehead-vector-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  z-index: -1
}

.whitehead-vector-2 {
  position: absolute;
  top: 70px;
  right: 0;
  width: 420px;
  z-index: -1
}

.whitehead-vector-3 {
  position: absolute;
  bottom: -20px;
  right: 0;
  left: 0;
  width: 100%;
  z-index: -1
}

.whitelabl-demo {
  padding: 35px 0;
  margin: 80px 0;
  background: url(../public/assets/home/whitedemo-back.webp) center/100% 100% no-repeat;
  overflow: hidden
}

.whitelabl-demo p {
  color: #fff;
  margin: 0;
  font-size: 15px
}

.whitelabl-demo .heading {
  color: #fff;
  font-size: 38px;
  margin: 8px 0 20px
}

.whitelabl-platform {
  background: linear-gradient(to right, #dfe7ff 0, #ffe9da 90%);
  padding: 60px 0;
  margin-bottom: 80px
}

.mobsol-accord .card,
.whiteplat-box {
  overflow: hidden;
  background: #fff;
  position: relative;
  z-index: 0;
}

.whiteplat-box {
  padding: 18px;
  border-radius: 15px;
  margin-top: 30px;
  border: 1px solid;
  transition: 0.4s ease-in-out;
  text-align: center
}

.whiteplat-box span {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  transition: 0.4s;
  margin: 0 auto
}

.whiteplat-box span img {
  transition: 0.4s;
  width: 24px;
  filter: brightness(0) invert(1)
}

.whiteplat-box h3,
.whiteplat-box h4 {
  font-size: 24px;
  margin: 8px 0;
  transition: 0.2s;
  color: #000
}

.whiteplat-box p {
  margin: 0;
  transition: 0.2s
}

.whitemobilz-gap,
.wlabel-tab {
  margin-top: 40px !important
}

.whitemobilz-box h3,
.whitemobilz-box h4 {
  font-size: 32px;
  margin: 0 0 8px;
  transition: 0.3s;
  text-transform: capitalize
}

.whitemobilz-box p {
  margin: 0;
  transition: 0.3s;
  line-height: 1.6
}

.whitelabl-solut .sub-heading {
  margin: 8px auto 40px;
  width: 65%
}

.moblimage-box {
  overflow: hidden
}

.moblimage-item {
  display: none;
  -webkit-animation: 0.9s scaleIn;
  animation: 0.9s scaleIn
}

.moblimage-item.active {
  display: block
}

@keyframes scaleIn {
  0% {
    -webkit-transform: scale(.94);
    transform: scale(.94);
    opacity: 0
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }
}

.mobsol-accord .card {
  margin-bottom: 25px;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1);
  transition: 0.4s ease-in-out
}

.mobsol-accord .card:after,
.mobsol-accord .card:before {
  height: 100%;
  border-radius: 10px;
  top: 0;
  transition: 0.4s ease-in-out;
  width: 100%;
  position: absolute;
  opacity: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ""
}

.mobsol-accord .card:after {
  z-index: -1
}

.mobsol-accord .card:before {
  z-index: 0;
  background: url(../public/assets/home/mobsol-pattern.webp) left top/cover no-repeat;
  mix-blend-mode: hard-light
}

.mobsol-accord .card-header {
  padding: 0;
  background-color: #fff0;
  border: 0
}

.whitetab-content,
.whitetab-link {
  box-shadow: 0 2px 16px 4px rgb(40 44 63 / .05);
  border-radius: 12px;
  overflow: hidden
}

.mobsol-accord .accordion-body {
  padding: 0 20px 20px
}

.mobsol-accord .accordion-body p {
  margin-bottom: 8px;
  color: #fff
}

.mobsol-accord .accordion-body a {
  font-family: Gilroy;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.1px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  line-height: 1;
  transition: 0.2s
}

.mobsol-accord .accordion-body a img {
  width: 11px;
  margin-left: 4px;
  transition: 0.2s;
  filter: brightness(0) invert(1)
}

.mobsol-accord .accordion-body a:hover img {
  margin-left: 6px
}

.mobsol-accord .accordion-collapse {
  z-index: 1;
  position: relative
}

.mobsol-accord .accordion-button {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 20px;
  color: #000 !important;
  font-size: 22px;
  font-weight: 600;
  justify-content: space-between;
  z-index: 1;
  transition: 0.2s
}

.mobsol-accord .active.card .accordion-button {
  color: #fff !important
}

.mobsol-accord .accordion-button span {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center
}

.mobsol-accord .accordion-button span img {
  width: 10px;
  filter: brightness(0) invert(1);
  transform: rotate(180deg)
}

.mobsol-accord .active.card .accordion-button span img {
  filter: none;
  transform: rotate(0)
}

.whitelabl-tabarea {
  margin-top: 70px
}

.whitelabl-tabarea .sub-heading {
  margin: 5px auto 0;
  width: 55%;
  color: #000;
  font-family: Gilroy;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.3
}

.whitetab-link {
  background: url(../public/assets/home/whitetab-pattern.webp) 0 0 / cover no-repeat;
  flex-wrap: unset !important;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px
}

.whitetab-link .nav-item {
  width: 20%;
  position: relative
}

.whitetab-link .nav-item:after {
  content: "";
  position: absolute;
  width: 1.4px;
  height: 88%;
  top: 50%;
  right: 0;
  z-index: 1;
  background: linear-gradient(to bottom, #fff0, #0035e5, #fff0);
  transform: translateY(-50%)
}

.whitetab-link .nav-link:before,
.whitetab-link .nav-link:hover {
  background: linear-gradient(to bottom, #d3ddfb 0, #fddfcb 90%)
}

.whitetab-link .nav-link {
  padding: 20px;
  transition: 0.2s;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  height: 100%
}

.whitetab-link .nav-link img {
  width: 34px;
  transition: 0.2s
}

.whitetab-link .nav-link p {
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 600;
  margin: 7px 0 0;
  transition: 0.2s;
  color: #000;
  line-height: 1.3
}

.whitetab-link .active.nav-link img {
  filter: brightness(0) saturate(100%)
}

.whitetab-content {
  margin-top: 10px;
  background: linear-gradient(to right, #0035e5 0, #298cff 90%);
  padding: 40px 40px 0;
  position: relative;
  z-index: 1
}

.whitetab-content:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  background: url(../public/assets/home/whitecontent-pattern.webp) left top/cover no-repeat;
  mix-blend-mode: soft-light
}

.whitetab-content:after {
  position: absolute;
  content: "";
  width: 550px;
  height: 550px;
  right: -80px;
  bottom: -40%;
  z-index: -1;
  border: 1px solid #fff;
  border-radius: 50%
}

.whitetab-detail {
  padding-bottom: 36px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  height: 100%
}

.labelinside-content .tab-pane,
.whitetab-detail div {
  animation: 1s fadeInUp;
  transition: 2s cubic-bezier(.165, .84, .44, 1)
}

.white-insidetab h3,
.white-insidetab h4,
.whitetab-detail h3,
.whitetab-detail h4 {
  font-size: 38px;
  color: #fff;
  margin-bottom: 20px;
  text-transform: capitalize;
  line-height: 1.1
}

.whitetab-detail h3 small,
.whitetab-detail h4 small {
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400
}

.whitetab-detail ul {
  padding-left: 15px
}

.whitetab-detail ul li {
  color: #fff;
  font-size: 14px;
  margin-bottom: 3px
}

.app-butn {
  margin-bottom: 15px;
  margin-right: 10px;
  display: inline-block
}

.app-butn img {
  width: 120px;
  transition: 0.2s
}

.whitepagetab-image {
  display: flex;
  align-items: end;
  height: 100%
}

.whiteimage-strech {
  margin-right: -40px
}

.labelinside-tablink {
  flex-direction: column
}

.labelinside-tablink .nav-item a {
  border: 2px solid #fff0;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.2s
}

.labelinside-tablink .nav-item a.active {
  border-color: #fff;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / .1)
}

.featurepage-head {
  padding: 80px 0;
  position: relative;
  text-align: center
}

.featurehead-vector-1 {
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  width: 700px
}

.featurehead-vector-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100px
}

.featuretable-area {
  position: relative;
  margin-bottom: 80px
}

.featurtable-vector {
  position: absolute;
  top: -40px;
  right: 0;
  z-index: -1;
  width: 60px
}

.feature-table table p {
  margin-bottom: 0;
  padding: 0;
  color: #000
}

.feature-table table tr td {
  border: 1px solid #ccc;
  padding: 12px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: rgb(0 0 0 / 70%);
  vertical-align: middle
}

.feature-table table tr td span {
  padding: 0;
  color: #000;
  display: block
}

.feature-table table tr td ul {
  margin-bottom: 10px;
  padding-left: 15px
}

.feature-table table tr td ul li {
  font-size: 14px;
  font-weight: 500;
  color: rgb(0 0 0 / 70%);
  margin-bottom: 2px
}

.feature-table table tr td img {
  width: 18px;
  background: 0 0;
  padding: 0
}

.feature-table table tr td:first-child {
  width: 50px
}

.feature-table table tr td:nth-child(2) {
  text-align: left
}

.feature-table table tr td:last-child,
.feature-table table tr td:nth-last-child(2) {
  background: #f0fcff;
  width: 12%;
  line-height: 1.4;
  color: rgb(0 0 0 / 90%)
}

.feature-table table .table-HEAD td {
  color: #fff;
  font-size: 19px
}

.feature-table table .table-HEAD td:first-child {
  background: #0035e5;
  position: relative
}

.feature-table table .table-HEAD td:first-child:before {
  position: absolute;
  content: "";
  right: 14px;
  top: -1px;
  width: 51px;
  height: 149%;
  background: url(../public/assets/home/curve-shape.png) 0 0 / contain no-repeat
}

.feat-heading td:first-child {
  color: #000;
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  border: 0
}

.feat-heading td:last-child,
.feat-heading td:nth-last-child(2) {
  background: linear-gradient(to right, #0035e5 0, #298cff 90%) !important;
  color: #fff !important;
  font-size: 17px;
  text-transform: uppercase;
  text-align: center !important;
  font-family: Gilroy
}

.table-button td {
  border: 0 !important;
  background: #fff !important;
  padding-bottom: 0 !important
}

.table-button td .default-btn {
  width: 100%;
  color: #fff;
  padding-top: 10px
}

.query-popup .modal-lg {
  max-width: 900px
}

.query-popup .modal-body {
  padding: 0
}

.query-popup .modal-content {
  border-radius: 10px;
  overflow: hidden
}

.popclose {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  font-size: 15px
}

.querypop-image img {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.query-formbox {
  padding: 40px 30px;
  position: relative;
  z-index: 1
}

.pop-vector-1,
.pop-vector-2,
.pop-vector-3,
.taxi-disbanner .taxi-vector2 {
  position: absolute;
  z-index: -1
}

.query-formbox .heading {
  font-size: 30px;
  margin-bottom: 20px
}

.pop-vector-1 {
  top: 0;
  left: 0;
  width: 110px
}

.pop-vector-2 {
  bottom: 0;
  right: 0;
  width: 90px
}

.pop-vector-3 {
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%
}

.query-formbox .form-field {
  margin-bottom: 15px
}

.query-formbox .form-field label {
  font-size: 13px;
  color: rgb(0 0 0 / 70%);
  display: block;
  margin-bottom: 3px;
  font-weight: 500
}

.query-formbox .form-field .form-control {
  font-size: 13px;
  color: rgb(0 0 0 / 60%);
  height: 40px;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: none
}

.query-formbox .form-field textarea {
  height: 70px !important
}

.query-formbox .half-field {
  width: 50%;
  display: inline-block
}

.query-formbox .half-field:first-child {
  padding-right: 5px
}

.query-formbox .half-field:nth-child(2) {
  padding-left: 5px
}

.taxi-disbanner {
  padding-top: 50px;
  position: relative;
  background: url(../public/assets/home/tax-bg.webp) 0 0 / contain no-repeat
}

section.taxi-disbanner::before {
  content: "";
  width: 100%;
  height: 30%;
  position: absolute;
  background: linear-gradient(to bottom, #fff0 0, #fff 85%);
  bottom: -18px;
  left: 0;
  filter: blur(9px)
}

.taxi-disbanner .software-text {
  padding-left: 10px
}

.taxi-disbanner .animated-btn {
  line-height: 1.7;
  margin-right: 30px;
  padding: 12px 20px 10px
}

.taxi-disbanner .taxi-vector1 {
  width: 20%;
  position: absolute;
  left: -29px;
  bottom: 0;
  z-index: -1
}

.taxi-disbanner .taxi-vector2 {
  width: 60%;
  right: 0;
  bottom: auto;
  top: -50px
}

.booking-sec {
  padding: 110px 0;
  position: relative
}

.tax-gif {
  padding: 55px 0 0
}

.taxi-service .web-booking-box {
  margin: 30px 10px 0;
  padding: 30px
}

.taxi-service .web-booking-box h4 {
  margin: 15px 0 7px
}

.taxi-service .web-booking-box span img {
  width: 28px
}

.taxi-service .web-booking-box p {
  min-height: auto
}

.web-booking-area.taxi-service {
  padding: 110px 0;
  margin-bottom: 0
}

.why-wait-area.taxi-label {
  position: relative;
  background: url(/public/assets/home/label-bg1.webp) 0 0 / cover no-repeat;
  padding: 20px 0;
  margin: 0 0 80px
}

.taxi-tech .cartechtab-head {
  margin-bottom: 25px
}

.taxi-tech .cartechtab-content {
  position: relative;
  background: url(../public/assets/home/booking-bg.webp) 0 0 / cover no-repeat
}

.taxi-label .why-wait-content .heading {
  color: #fff;
  font-size: 25px;
  margin-bottom: 18px
}

.whitelabl-solut.taxi-solut {
  position: relative;
  padding-bottom: 90px
}

.taxi-solut .solut-vector {
  position: absolute;
  right: 0;
  width: 520px;
  z-index: -1;
  top: 35px
}

.taxi-solut .solut-vector1 {
  position: absolute;
  left: 0;
  width: 80px;
  top: -60px
}

.cartechtab-image.cart-bg3 {
  margin-right: -20px
}

.img-responsive.banner-img3 {
  width: 80%;
  margin: 0 auto
}

.lft-banner {
  padding: 150px 0;
  position: relative;
  padding-bottom: 0
}

.lyft-banner-btn {
  display: flex;
  align-items: center
}

.lft-banner .banner-content small {
  color: #fff;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
  background: linear-gradient(to right, rgb(0 0 0 / 90%) 50%, transparent);
  padding: 5px 10px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.lft-banner .heading {
  font-family: Gilroy;
  font-weight: 500 !important;
  line-height: 1.1;
  margin-bottom: 10px
}

.lft-banner .heading strong {
  display: inline-block;
  font-weight: 600 !important
}

.new-mobility {
  background: url(../public/assets/home/lyft-Bbtn.webp) 0 0 / cover no-repeat;
  padding: 4px 9px;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 10px
}

.lft-banner .banner-image img {
  height: 100%;
  object-fit: cover
}

.lyft-enterprises {
  padding: 80px 0;
  position: relative;
  padding-bottom: 60px
}

.lyft-enterprises .enterprises-head {
  text-align: center
}

.lyft-enterprises .left-vector {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 30%;
  margin: 0 auto
}

.lyft-enterprises .enterprises-box::before {
  background: url(../public/assets/home/enter-vector-blue.webp) no-repeat;
  background-position: center;
  background-size: cover
}

.lyft-enterprises .enterprises-box {
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: #fff0;
  transform: translateZ(0);
  box-shadow: 0 0 1px #fff0
}

.lyft-enterprises .enterprises-box:hover {
  transform: translateY(-15px)
}

.slider-section {
  position: relative;
  padding-bottom: 0;
  min-height: 500px;
  overflow-x: hidden;
  padding-top: 40px
}

.slider-section .slick-slide {
  padding: 30px;
  overflow: hidden
}

.slider-section .slick-slide img {
  border-radius: 15px;
  max-width: 100%;
  height: auto;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / .2)
}

.lyfc-faq .heading {
  text-align: center
}

.admission-scr.lyft-wheel-area {
  margin: 130px 0 50px
}

.lyft-wheel-image {
  position: relative
}

.lyft-wheel-image .orange-circle {
  position: absolute;
  bottom: -10px;
  left: -100px;
  width: 370px;
  z-index: -1
}

.lyft-wheel-image .blue-circle {
  position: absolute;
  right: -170px;
  top: -130px;
  width: 420px;
  z-index: -1
}

.lyft-wheel-content .heading {
  margin-bottom: 10px
}

.lyft-wheel-content ul {
  list-style: none;
  padding: 0;
  margin: 0
}

.lyft-wheel-content ul li {
  font-family: Gilroy;
  font-weight: 600;
  margin-bottom: 6px;
  font-size: 15px;
  position: relative;
  padding-left: 24px;
  text-transform: capitalize
}

.lyft-wheel-content ul li::before {
  content: "";
  background: linear-gradient(to right, #0238e6 0%, #63a3ff 80%);
  position: absolute;
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 2px
}

.admission-scr {
  position: relative;
  padding: 80px 0;
  padding-top: 80px;
  margin-top: 0
}

.admi-head-image {
  position: absolute;
  width: 55%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: auto;
  z-index: 3
}

.lyft-wheel-area .admi-head-image {
  left: unset;
  right: 0
}

.admi-head-image img {
  object-fit: cover;
  width: 100%;
  height: 100%
}

.admipage-vector {
  position: absolute;
  right: 0;
  width: 60%;
  z-index: 2;
  top: -106px;
  left: -116px;
  height: 344px
}

.admipage-vector2 {
  position: absolute;
  right: 0;
  width: 59%;
  z-index: 2;
  top: 307px;
  left: -288px;
  height: auto;
  filter: blur(80px)
}

.lyfc-faq {
  padding-top: 80px;
  position: relative;
  z-index: 4
}

.adminscr-about .heading {
  margin-bottom: 15px
}

.adminscr-about ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 30px
}

.adminscr-about ul li {
  font-family: Gilroy;
  font-weight: 600;
  margin-bottom: 6px;
  font-size: 15px;
  position: relative;
  padding-left: 24px;
  text-transform: capitalize
}

.adminscr-about ul li::before {
  content: "";
  background: linear-gradient(to right, #0238e6 0%, #63a3ff 80%);
  position: absolute;
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 2px
}

.media-section {
  position: relative;
  padding: 60px 0
}

.media-section .media-heading {
  text-align: center;
  margin-bottom: 20px
}

.media-section .media-content h2,
.media-section .media-content h3,
.media-section .media-content h4 {
  line-height: 1.2;
  color: #000;
  margin-bottom: 0;
  font-size: 18px;
  text-transform: capitalize
}

.media-section .solution-box .media-content {
  border-top: 0;
  min-height: 156px
}

.media-section .media-icon {
  height: auto;
  margin-bottom: 12px;
  padding: 0;
  background: #fff0;
  border: 0;
  border-bottom: 1px solid rgb(0 0 0 / 20%);
  border-radius: 0 0 0 0;
  transition: 0.3s
}

.media-section .solution-box:hover .media-icon {
  background: #fff0;
  box-shadow: unset
}

.media-icon {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.media-icon span {
  font-size: 14px;
  font-weight: 600;
  font-family: "Gilroy";
  display: inline-block;
  margin-bottom: 10px
}

.media-section .media-icon img {
  height: 100%;
  width: 30%;
  border-radius: 0;
  margin-bottom: 10px
}

.media-section .btn-wraps {
  text-align: center
}

.media-section .solution-box:hover .media-content h2,
.media-section .solution-box:hover .media-content h3,
.media-section .solution-box:hover .media-content h4 {
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #0035e5 0, #298cff 80%);
  color: #0000
}

.media-section .solution-box {
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: #fff0;
  transform: translateZ(0)
}

.media-section .solution-box:hover {
  transform: translateY(-15px)
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important
}

.google-translate-drop {
  margin-left: 10px;
  width: 150px;
  border: 1px solid #63a3ff;
  padding: 10px 10px;
  border-radius: 3px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Gilroy";
  color: #298cff
}

.google-translate-drop svg,
.google-translate-drop option {
  color: rgb(0 0 0 / 50%);
  font-weight: 600
}

.google-translate-drop:focus {
  outline: none
}

/* restaurant app page */

.restro-banner.pharm-banner{
  padding-top: 0;
}

.restro-banner .pharm-banner-image{
  margin-right: 30px;
}

/* ----------- */
.restro-magic-details,
.restro-magic-img{
  margin-top: 40px;
}

.restro-magic-details .purple-gradiant{
  background: linear-gradient(to right, #6919BA 0%, #EC9151 100%);
    padding: 3px 15px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    font-family: "Gilroy";
    display: inline-block;
    margin-bottom: 5px;
}

.restro-magic-details h4{
  font-size: 38px;
  text-transform: capitalize;
}

.restro-magic-details p{
  margin-bottom: 30px;
  font-weight: 600;
  font-family: "Gilroy";
}

/* ---------- */

.restro-industry-area{
  margin: 80px 0;
}

.restro-industry-head span{
  display: block;
}

.restro-industry-wrap{
  text-align: center;
  margin-top: 30px;
}

.restro-industry-wrap span{
  text-align: center;
  display: inline-block;
  border: 1.3px solid #FF0080;
  background: linear-gradient(to right,#FF8C00 0%, #FF0080 100%);
  background-clip: text;
  color: transparent;
  font-size: 14px;
  font-weight: 600;
  font-family: "Gilroy";
  border-radius: 20px;
  padding: 5px 10px;
}

.restro-industry-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.restro-industry-card{
  position: relative;
  width: 47%;
  min-height: 110px;
  perspective: 1000px;
}

.restro-industry-cardfront, .restro-industry-cardback {
  animation-duration: 7s;
  transform-style: preserve-3d;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.restro-industry-cardfront {
  z-index: 1;
  animation-name: cardFlipFront;
}

.restro-industry-cardback {
  transform: rotateX(-180deg);
  animation-name: cardFlipBack;
}

@keyframes cardFlipFront {
  0% {
    transform: rotateX(0deg);
  }
  22.5% {
    transform: rotateX(0deg);
  }
  27.5% {
    transform: rotateX(180deg);
  }
  72.5% {
    transform: rotateX(180deg);
  }
  77.5% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@keyframes cardFlipBack {
  0% {
    transform: rotateX(-180deg);
  }
  22.5% {
    transform: rotateX(-180deg);
  }
  27.5% {
    transform: rotateX(0deg);
  }
  72.5% {
    transform: rotateX(0deg);
  }
  77.5% {
    transform: rotateX(-180deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

/* ----------- */

.restro-margin-area{
  margin: 80px 0;
}

.restro-margin-head span{
  display: block;
}

.restro-margin-count{
  width: 100%;
  margin: 70px 0 50px;
}

.restro-margin-detail{
  text-align: center;
  margin-bottom: 30px;
  font-family: "Gilroy";
  font-weight: 600;
}

.restro-margin-count div{
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(115deg, #0035E5 0%, #567DFF 80%);
  position: relative;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin: 0 auto;
  box-shadow: inset 0px 2px 24px 0 rgb(0 0 0 / 0.2);
}

.restro-margin-count div span{
  display: block;
  align-items: center;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
}

.restro-margin-count.orange-grade div span{
  font-size: 14px;
}

.restro-margin-count.aqua-grade div span{
  font-size: 15px;
}

.restro-margin-count div::before{
  content:"" ;
  width: 230px;
  height: 230px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: transparent;
  transform: translate(-50%,-50%);
  border: 1px solid rgb(85, 184, 255, 0.5);
  animation: restroWave1 2.5s infinite forwards;
  z-index: -1;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.restro-margin-count div::after{
  content:"" ;
  width: 190px;
  height: 190px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: transparent;
  transform: translate(-50%,-50%);
  border: 2px solid rgb(0, 53, 229, .4);
  animation: restroWave2 2.5s 0.2s infinite forwards;
  z-index: -1;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.restro-margin-count.orange-grade div{
  background: linear-gradient(115deg, #FF8008 0%, #FFC837 100%);
}

.restro-margin-count.orange-grade div::before{
  border: 1px solid rgb(255, 128, 8, .3);
}

.restro-margin-count.orange-grade div::after{
  border: 2px solid rgb(255, 128, 8, .4);
}

.restro-margin-count.purp-grade div{
  background: linear-gradient(115deg, #4A00E0 0%, #8E2DE2 80%);
}

.restro-margin-count.purp-grade div::before{
  border: 1px solid rgb(142, 45, 226, 0.3);
}

.restro-margin-count.purp-grade div::after{
  border: 2px solid rgb(142, 45, 226, .4);
}

.restro-margin-count.aqua-grade div{
  background: linear-gradient(115deg, #0052D4 0%, #55C7FF 80%);
}

.restro-margin-count.aqua-grade div::before{
  border: 1px solid rgb(85, 199, 255, .3);
}

.restro-margin-count.aqua-grade div::after{
  border: 2px solid rgb(85, 199, 255, .4);
}

@keyframes restroWave1 {
  0%{
    width: 150px;
    height: 150px;
  }
  100%{
    width: 230px;
    height: 230px;
  }
}

@keyframes restroWave2 {
  0%{
    width: 130px;
    height: 130px;
  }
  100%{
    width: 190px;
    height: 190px;
  }
}

/* ----------- */

.restro-excellent-area{
  margin: 80px 0;
}

.restro-excellent-box{
  border-radius: 10px;
  border: 1px solid #55b8ff;
  padding: 30px;
  background: url(/public/assets/home/restro-excellent-back.webp) no-repeat center;
  background-size: cover;
  z-index: 0;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease;
  box-shadow: 0 2px 15px 0 rgb(0 0 0 / .2);
  margin-top: 40px;
}

.restro-excellent-box:hover{
  background: transparent;
}

.restro-excellent-box::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(/public/assets/home/restro-excellent-before.webp) no-repeat center;
  background-size: cover;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.4s ease;
}

.restro-excellent-box:hover::before{
  opacity: 1;
}

.restro-excellent-box span{
  background-color: rgb(124, 184, 255, .5);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.restro-excellent-box span span{
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background: linear-gradient(to right, #0035e5 0, #298cff 80%);
  margin-bottom: 0;
  transition: all 0.4s ease;
}

.restro-excellent-box:hover span span{
  background: #fff;
}

.restro-excellent-box span img{
  transition: all 0.4s ease;
  width: 20px;
  filter: brightness(0) invert(1);
}

.restro-excellent-box:hover span img{
  filter: none;
}

.restro-excellent-box h4{
  background: linear-gradient(to right, #0035e5 0, #298cff 80%);
  background-clip: text;
  color: transparent;
  font-size: 22px;
  transition: all 0.3s ease;
  text-transform: capitalize;
}

.restro-excellent-box:hover h4{
  color: #fff;
}

.restro-excellent-box p{
 margin-bottom: 0;
 transition: all 0.3s ease;
}

.restro-excellent-box:hover p{
 color: #fff;
}
